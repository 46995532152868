interface ContainerProps {
  children: React.ReactNode
  className?: string
  dataTestId?: string
}

const Container = ({ children, className, dataTestId }: ContainerProps) => {
  return (
    <div data-test-id={dataTestId} className={`rounded-xl border border-slate-200 bg-white shadow ${className || ''}`}>
      {children}
    </div>
  );
};

export default Container;
