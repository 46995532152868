import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { OptionRenderProps, Option } from './Types';

const defaultRenderOption = ({ active, option }: OptionRenderProps) => (
  <div
    className={`relative cursor-pointer select-none py-2 pl-4 pr-4 text-gray-900 ${
      active ? 'bg-slate-50' : 'bg-white'
    }`}>
    {option.label}
  </div>
);

interface AutocompleteProps {
  onInputChange: (value: string) => void;
  options: Option[];
  value: Option | null;
  onChange: (value: Option | null) => void;
  inputValue: string;
  renderOption?: (props: OptionRenderProps) => JSX.Element;
  placeHolder?: string;
}

const Autocomplete = ({
  onChange,
  onInputChange,
  options,
  value,
  inputValue,
  renderOption = defaultRenderOption,
  placeHolder = 'Search'
}: AutocompleteProps) => {
  const onReset = () => {
    onInputChange('');
    onChange(null);
  };

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative">
        <div className="relative w-full overflow-hidden rounded-md bg-white text-left shadow-sm focus:outline-none">
          <div className="absolute inset-y-0 left-0 ml-2 flex items-center text-slate-400">
            <MagnifyingGlassIcon width="15" height="15" />
          </div>
          <Combobox.Input
            className={`border-grey-300 w-full rounded-md border py-1.5 pl-7 pr-10 text-sm leading-5 text-secondary shadow outline-0 hover:border-muse-400 focus:border-muse-400 active:border-muse-400`}
            displayValue={(value: Option) => value?.label || ''}
            onChange={(event) => onInputChange(event.target.value)}
            placeholder={placeHolder}
            autoComplete="off"
          />
          <div className="absolute inset-y-0 right-0 mr-2 flex items-center">
            {inputValue.length > 0 && (
              <XMarkIcon
                width={16}
                height={16}
                className="cursor-pointer text-tertiary hover:text-slate-500"
                onClick={onReset}
              />
            )}
          </div>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => onInputChange('')}>
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.length === 0 && inputValue !== '' ? (
              <div className="relative select-none px-4 py-2 text-gray-700">Nothing found.</div>
            ) : (
              options.map((option) => (
                <Combobox.Option key={option.value} value={option}>
                  {({ active, disabled, selected }) => renderOption({ active, disabled, selected, option })}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default Autocomplete;
