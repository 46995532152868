import { useState } from 'react';
import Autocomplete from '../../../../components/form/Autocomplete/Autocomplete';
import { ISuperficialNode, ISuperficialSubnode, nodeNameMap, subnodeNameMap } from '../INode';
import { Option } from '../../../../components/form/Autocomplete/Types';
import { NodeIcon } from '../NodeIcon';
import { useDebounce } from 'react-use';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../../infrastructure/state/slices/activeAccountSlice';
import { getNodes } from '../../../../services/nodes/nodes';
import { SetSelectedSubNode } from '../types';
import { events, trackEvent } from '../../../../infrastructure/analytics';
import { useGetDamaRevisionQuery } from '../../../../services/accounts/accounts';

interface IOptionMetadata {
  parentNode: ISuperficialNode;
  subnode?: ISuperficialSubnode;
}

interface DiscoverSearchProps {
  setSelectedNode: (node: ISuperficialNode) => void;
  setSelectedSubNode: SetSelectedSubNode
}

export const DiscoverSearch = ({ setSelectedNode, setSelectedSubNode }: DiscoverSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const accountId = useSelector(selectActiveAccountId);
  const getDamaRevisionQuery = useGetDamaRevisionQuery({ accountId });

  useDebounce(
    async () => {
      if (searchValue.length === 0) {
        setSearchOptions([]);
        return;
      }
      if (!getDamaRevisionQuery.data) return;
      const { algoliaKey, algoliaAppId, algoliaIndex } = getDamaRevisionQuery.data;
      const { items } = await getNodes({
        query: searchValue,
        apiKey: algoliaKey,
        applicationId: algoliaAppId,
        indexName: algoliaIndex,
        pageSize: 5,
        filters: `NOT type:tableau_workbook`
      });
      const searchOptions = getSearchOptionsFromNodes(items, searchValue);
      setSearchOptions(searchOptions);
      trackEvent(events.dagSearched);
    },
    300,
    [searchValue]
  );

  const onSelect = (option: Option | null) => {
    if (option) {
      const metadata = option.metadata as IOptionMetadata;
      if (metadata.subnode) {
        setSelectedSubNode(metadata.parentNode, metadata.subnode);
      }
      else {
        setSelectedNode(metadata.parentNode);
      }
    }
  };

  return (
    <Autocomplete
      onInputChange={(value) => setSearchValue(value)}
      inputValue={searchValue}
      options={searchOptions}
      onChange={onSelect}
      value={null}
      renderOption={renderSearchOption}
    />
  );
};

const renderSearchOption = ({ active, option }: { active: boolean; option: Option }) => {
  const optionMetadata = option.metadata as IOptionMetadata;
  const backgroundColor = active ? 'bg-slate-50' : 'bg-white';
  const parentName = optionMetadata.subnode ? optionMetadata.parentNode.name : optionMetadata.parentNode.parentName;
  const typeName = optionMetadata.subnode?.type ? subnodeNameMap.get(optionMetadata.subnode.type) : nodeNameMap.get(optionMetadata.parentNode.type);
  return (
    <div
      className={`relative cursor-pointer select-none px-3 py-2 ${backgroundColor}`}>
      <div className="flex gap-2">
        <NodeIcon type={optionMetadata.parentNode.type} databaseTechnology={optionMetadata.parentNode.databaseTechnology} subnodeType={optionMetadata.subnode?.type} />
        <div className="flex flex-col">
          <span className="-mt-0.5 max-w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap">{option.label}</span>
          <span className="text-xs font-light text-slate-400 max-w-[9rem] overflow-hidden text-ellipsis whitespace-nowrap">{typeName}{parentName && ` in ${parentName}`}</span>
        </div>
      </div>
    </div>
  );
};

const getSearchOptionsFromNodes = (nodes: ISuperficialNode[], searchValue: string): Option[] => {
  const options: Option[] = [];
  const searchValueLowercase = searchValue.toLowerCase();
  for (const node of nodes) {
    if (node.name.toLowerCase().includes(searchValueLowercase)) {
      const metadata: IOptionMetadata = { parentNode: node };
      if (node.name.toLowerCase() === searchValueLowercase) {
        options.unshift({ label: node.name, value: node.id, metadata });
      }
      else {
        options.push({ label: node.name, value: node.id, metadata });
      }
    }
    for (const subnode of node.subnodes) {
      if (subnode.name.toLowerCase().includes(searchValueLowercase)) {
        const metadata: IOptionMetadata = { parentNode: node, subnode: subnode };
        if (subnode.name.toLowerCase() === searchValueLowercase) {
          options.unshift({ label: subnode.name, value: `${node.id}-${subnode.name}`, metadata });
        }
        else {
          options.push({ label: subnode.name, value: `${node.id}-${subnode.name}`, metadata });
        }
      }
    }
  }
  return options;
};