import { Error500Icon } from '../../assets/images/icons/DelphiIcons';
import Footer from './Footer';

const ErrorPage = () => {
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;
  return (
    <div className="h-screen bg-surface-light flex flex-col">
      <div className="mx-auto w-80 pt-20 text-center">
        <Error500Icon width="100" height="100" className="ml-32" />
        <div className="mt-5 text-lg font-medium text-secondary">Something went wrong</div>
        <div className="mt-2 text-tertiary">
          Please try refreshing, or
          <a className="mx-1 font-medium" href={`mailto:${supportEmail}`}>
            contact us
          </a>
          if the problem persists.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
