import { ISuperficialNode } from "../../models/discover/INode";

export const filterOutUpstreamNodes = (nodes: ISuperficialNode[], modelUtl: string): ISuperficialNode[] => {
    const upstreamNodeIds: string[] = [];
    const queue = [modelUtl];
    while (queue.length > 0) {
        const nodeId = queue.pop();
        const node = nodes.find((n) => n.id === nodeId);
        if (node) {
            upstreamNodeIds.push(...node.parents);
            queue.push(...node.parents);
        }
    }
    return nodes.filter((node) => !upstreamNodeIds.includes(node.id));
};