import { SparklesIcon } from '@heroicons/react/24/solid';
import { ChangeStatus, ChangeType, IChangePage } from '../IChange';
import Table from '../../../components/Table/Table';
import { getEnumKeyByEnumValue } from '../../../utils/enumUtils';
import statusToColorScheme from './statusToColorScheme';
import { TextWithEllipsisAndTooltip } from '../../../components/TextWithEllipsisAndTooltip';
import { DbtCloudIcon, LookerIcon } from '../../../assets/images/icons/DelphiIcons';
import { dateFormats, utcToLocal } from '../../../infrastructure/dateUtilities';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useGetChangesQuery } from '../../../services/changes/changes';
import { SortOptions, ModelChangesTab, DataModelFilter } from './Types';
import { DataModelFilters } from './filters/DataModelFilters';
import { DateFilterValue } from './filters/dateFilter';
import { FilterValue } from '../../../components/Table/TableFilters/types';

export const ModelChangesList = ({ tab }: { tab: ModelChangesTab }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchInput, setSearchInput] = useState('');
  const [projectId, setProjectId] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState<SortOptions>('newest');
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>({});
  const getChangesQuery = useGetChangesQuery({
    search: searchInput,
    owner: filterValues[DataModelFilter.owner] || undefined,
    createdAt: (filterValues[DataModelFilter.createdAt] as DateFilterValue) || undefined,
    sortBy: sortBy,
    statuses: tabToStatuses[tab],
    types: filterValues[DataModelFilter.type]?.split(',').map(t => t as ChangeType) || undefined,
    page,
    pageSize,
    projectId: projectId || 0
  }, { skip: !projectId });
  const total = getChangesQuery.data?.total || 0;

  const onRowClicked = (row: unknown) => {
    const change = (row as IChangePage);
    navigate(`/project/${projectId}/evolution/change/${change.id}`);
  };

  const tableColumns = [
    {
      name: '#',
      selector: (row: unknown) => `${(row as IChangePage).id}`,
      width: '80px'
    },
    {
      name: 'TYPE',
      selector: (row: unknown) => (
        <div className="flex gap-1 uppercase" data-tag="allowRowEvents">
          <div><SparklesIcon width="16" height="16" /></div><div>{(row as IChangePage).changeType}</div>
        </div>
      ),
      width: '200px'
    },
    {
      name: 'TITLE',
      selector: (row: unknown) => <span className="text-text-primary" data-tag="allowRowEvents"><TextWithEllipsisAndTooltip text={(row as IChangePage).title} maxChars={65} /></span>,
      width: 'auto'
    },
    {
      name: 'SOURCE',
      selector: SourceColumn,
      width: '250px'
    },
    {
      name: 'OWNER',
      selector: (row: unknown) => (row as IChangePage).createdBy,
      width: '200px'
    },
    {
      name: 'DATE',
      selector: (row: unknown) => utcToLocal((row as IChangePage).createdAt, dateFormats.dateHoursAndMinutes),
      width: '200px'
    },
    {
      name: 'STATUS',
      selector: (row: unknown) => {
        const status = (row as IChangePage).status;
        const statusLabel = getEnumKeyByEnumValue(ChangeStatus, status);
        return <div className={`rounded-full px-3 py-1 text-sm ${statusToColorScheme(status)}`} data-tag="allowRowEvents">{statusLabel}</div>;
      },
      width: '150px'
    }
  ];

  return (
    <div>
      <DataModelFilters
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedProjectId={projectId}
        setSelectedProjectId={setProjectId}
        showSort={true}
        tableName='model-changes-table'
        onFilterValuesChange={setFilterValues}
      />
      <div className="mt-4 max-w-[78vw]">
        <Table isLoading={getChangesQuery.isLoading} data={getChangesQuery.data?.items || []} columns={tableColumns} onRowClicked={onRowClicked} maxBodyHeight='72vh' pagination={{ page, pageSize, setPage, setPageSize, total }} />
      </div>
    </div>
  );
};

const SourceColumn = (row: unknown) => {
  const change = row as IChangePage;
  let icon;
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
      icon = <DbtCloudIcon width="16" height="16" fill="#FF694A" />;
      break;
    default:
      icon = <LookerIcon width="16" height="16" />;
      break;
  }
  return (
    <div className="flex gap-1 text-black" data-tag="allowRowEvents">
      <div>{icon}</div>
      <TextWithEllipsisAndTooltip text={(row as IChangePage).createdByResourceName} maxChars={20} />
    </div>
  );
};

const tabToStatuses = {
  [ModelChangesTab.Proposals]: [ChangeStatus.Proposal],
  [ModelChangesTab.InProgress]: [ChangeStatus.Deployed, ChangeStatus.Merged, ChangeStatus.Open, ChangeStatus['Pending Merge']],
  [ModelChangesTab.Archived]: [ChangeStatus.Published, ChangeStatus['PR Rejected'], ChangeStatus['Draft Rejected']]
};