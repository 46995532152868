import { useEffect, useRef } from 'react';
import { useClickAway, useKeyPress } from 'react-use';
import { useIntercom } from 'react-use-intercom';

interface SidepaneProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  background?: 'bg-white' | 'bg-surface-light';
  maxWidth?: 'max-w-xs' | 'max-w-sm' | 'max-w-md' | 'max-w-lg';
}

const Sidepane = ({ isOpen, children, onClose, background = 'bg-white', maxWidth = 'max-w-sm' }: SidepaneProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, onClose);
  const pressed = useKeyPress('Escape');
  const animation = isOpen ? 'right-0 duration-200' : '-right-1/2 duration-200';
  const { shutdown } = useIntercom();

  useEffect(() => {
    if (pressed[0] && isOpen) {
      onClose();
    }
  }, [pressed, onClose, isOpen]);

  useEffect(() => {
    if (isOpen) {
      shutdown();
    }
  }, [isOpen, shutdown]);

  return (
    <>
      <div
        ref={ref}
        className={`${animation} ${background} ${maxWidth} fixed top-0 z-30 h-full overflow-y-auto shadow-lg transition-right sidepane`}>
        {children}
      </div>
      {isOpen && <div className="fixed inset-0 z-[11] bg-black bg-opacity-25"></div>}
    </>
  );
};

export default Sidepane;

