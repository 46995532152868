import { useEffect, useState } from "react";
import { NodeButtons } from "../NodeSidepaneButtons";
import { SubNodesList } from "./NodeSidepaneSubnodesList";
import { NodeInfo } from "./NodeSidepaneInfo";
import { NodeSidepaneFooter } from "./NodeSidepaneFooter";
import { IExpandedNode, ISubnode, NODES_TO_SHOW_CONTAINERS, NodeType, SubnodeType } from "../../INode";
import { NodeIcon } from "../../NodeIcon";
import { BriefcaseIcon, FolderIcon } from "@heroicons/react/24/outline";
import { NodeSidepaneLoader } from "./NodeSidepaneLoader";
import { LookerColorlessIcon, TableauIcon } from "../../../../../assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "../../../../../components/TextWithEllipsisAndTooltip";
import { ExpandableText } from "../../../../../components/ExpandableText";
import { ShiftToDbtBanner } from "../ShiftToDbtBanner";
import { useGetPatQuery } from "../../../../../services/changes/changes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { NodeUsage } from "../NodeUsage";
import { events, trackEvent } from "../../../../../infrastructure/analytics";
import { SubNodeSidepaneLoader } from "../SubnodeSidepane/SubnodeSidepaneLoader";
import { SubNodesListProps } from "./types";
import { NodeButtonProps } from "../types";
import { ContainedNodesList } from "./NodeSidepaneContainedNodesList";
import CreateChangeSidepane from "../../../../evolution/createChangeSidepane/CreateChangeSidepane";
import { NodeSidepaneMenu } from "src/features/models/discover/sidepane/NodeSidepane/NodeSidepaneMenu";

type NodeSidepaneProps = {
    SubNodesListComponent?: React.FC<SubNodesListProps>;
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
    ButtonsComponent?: React.FC<NodeButtonProps>;
}

export const NodeSidepane = ({ node, setCanCloseModal, SubNodesListComponent = SubNodesList, ButtonsComponent = NodeButtons }: NodeSidepaneProps) => {
    const [showCodeModal, setShowCodeModal] = useState<boolean>(false);
    const [showSubnodeSidepane, setShowSubnodeSidepane] = useState<boolean>(false);
    const [selectedSubnode, setSelectedSubnode] = useState<ISubnode | null>(null);
    const isSelectedSubnodeANode = !!(selectedSubnode && [SubnodeType.LookerTile, SubnodeType.LookerLook, SubnodeType.LookerConnectedView, SubnodeType.Metric].includes(selectedSubnode.type));
    const { tags = [], meta = {}, subnodes, name, description, typeSpecificInfo } = node;
    const [showProposalSidepane, setShowProposalSidepane] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const getPatQuery = useGetPatQuery({ accountId, uri: node.id }, { skip: !node.hasProposals });
    const [selectedRelatedNode, setSelectedRelatedNode] = useState<string | null>(null);
    const [showRelatedNodeSidepane, setShowRelatedNodeSidepane] = useState<boolean>(false);

    const workbookId = NODES_TO_SHOW_CONTAINERS.includes(node.type) ? node.chainedNodes?.find(n => n.type === NodeType.TableauWorkbook)?.id : null;
    const onClickSubtitle = workbookId ? () => { onRelatedNodeClick(workbookId); } : undefined;

    useEffect(() => {
        trackEvent(events.nodeSidepaneOpened, { node_type: node.type });
    }, [node]);

    useEffect(() => {
        setCanCloseModal(!showCodeModal && !showSubnodeSidepane && !showProposalSidepane);
    }, [showCodeModal, setCanCloseModal, showSubnodeSidepane, showProposalSidepane]);

    const onSubnodeClick = (subnode: ISubnode) => {
        setSelectedSubnode(subnode);
        setShowSubnodeSidepane(true);
    };

    const onRelatedNodeClick = (nodeId: string) => {
        setSelectedRelatedNode(nodeId);
        setShowRelatedNodeSidepane(true);
    };

    const onShiftToDbtClick = () => {
        setShowProposalSidepane(true);
    };

    return (
        <>
            <div className="p-2">
                <div className="flex items-center gap-2">
                    <div className="bg-surface-light w-fit border border-slate-200 rounded">
                        <NodeIcon withPadding={true} withBackground={true} iconSize={20} type={node.type} generatedByDelphi={node.generatedByDelphi} databaseTechnology={node.databaseTechnology} />
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-between items-center">
                            <div className="text-lg text-text-primary">
                                <TextWithEllipsisAndTooltip text={name} maxChars={25} />
                            </div>
                            <NodeSidepaneMenu node={node} />
                        </div>
                        <ClickableSubtitle onClick={onClickSubtitle}><NodeSubtitle node={node} /></ClickableSubtitle>
                    </div>
                </div>
                <div className="mt-3 text-text-primary text-sm"><ExpandableText text={description} maxChars={290} withMarkdown /></div>
                <ButtonsComponent parentNode={node} showCodeModal={showCodeModal} setShowCodeModal={setShowCodeModal} onRelatedNodeClick={onRelatedNodeClick} />
                <NodeUsage node={node} />
                <NodeInfo tags={tags} meta={meta} info={typeSpecificInfo} />
                {
                    getPatQuery.data && node.userAllowedToPromote && <ShiftToDbtBanner onClick={onShiftToDbtClick} />
                }
                <SubNodesListComponent parentNode={node} subnodes={subnodes} onClick={onSubnodeClick} />
                <ContainedNodesList containedNodes={node.containedNodes} onClick={onRelatedNodeClick} />
            </div>
            <NodeSidepaneFooter node={node} />
            <SubNodeSidepaneLoader isOpen={showSubnodeSidepane && !isSelectedSubnodeANode} onClose={() => !isSelectedSubnodeANode && setShowSubnodeSidepane(false)} subnode={selectedSubnode} nodeId={node.id} />
            <NodeSidepaneLoader isOpen={showSubnodeSidepane && isSelectedSubnodeANode} onClose={() => isSelectedSubnodeANode && setShowSubnodeSidepane(false)} nodeId={isSelectedSubnodeANode && selectedSubnode?.id || ''} />
            <NodeSidepaneLoader isOpen={showRelatedNodeSidepane} onClose={() => setShowRelatedNodeSidepane(false)} nodeId={selectedRelatedNode || ''} />
            {
                getPatQuery.data && (
                    <CreateChangeSidepane
                        isOpen={showProposalSidepane}
                        onClose={() => setShowProposalSidepane(false)}
                        pat={getPatQuery.data}
                    />
                )
            }
        </>
    );
};

const ClickableSubtitle = ({ onClick, children }: { onClick?: () => void, children: React.ReactNode }) => {
    if (!onClick) {
        return <div className="text-tertiary text-sm flex items-center gap-1">{children}</div>;
    }
    return <div onClick={onClick} className="text-tertiary text-sm flex items-center gap-1 hover:text-slate-500 cursor-pointer">{children}</div>;
};

const NodeSubtitle = ({ node }: { node: IExpandedNode }) => {
    switch (node.type) {
        case NodeType.DataModel:
            return <>Model in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.parentName} (dbt project)`} /> </>;
        case NodeType.Metric:
            return <>Metric in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.dbtProjectName}`} /> </>;
        case NodeType.DataSource:
            return <>Source in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.parentName} (dbt project)`} /> </>;
        case NodeType.LookerDashboard:
            return <>Dashboard in <FolderIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.lookerFolder} (Looker folder)`} /> </>;
        case NodeType.LookerExplore:
            return <>Explore in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.lookerProject} (Looker project)`} /> </>;
        case NodeType.LookerLook:
            return <>Look in <FolderIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.lookerFolder} (Looker folder)`} /> </>;
        case NodeType.LookerTile:
            return <>Tile in <LookerColorlessIcon width={16} height={16} fill="#64748B" /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.parentName} (Looker dashboard)`} /> </>;
        case NodeType.LookerView:
            return <>LookML view in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.lookerProject} (Looker project)`} /> </>;
        case NodeType.LookerDerivedView:
            return <>LookML view in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={`${node.lookerProject} (Looker project)`} /> </>;
        case NodeType.TableauWorkbook:
            return <>Workbook in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={node.parentName} /> </>;
        case NodeType.TableauView:
            return <>View in <TableauIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={node.parentName} /> </>;
        case NodeType.TableauDashboard:
            return <>Dashboard in <TableauIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={node.parentName} /> </>;
        case NodeType.TableauCustomQuery:
            return <>Custom query in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={30} text={node.parentName} /> </>;
        case NodeType.TableauEmbeddedDataSource:
            return <>Embedded data source in <TableauIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={20} text={node.parentName} /> </>;
        case NodeType.TableauPublishedDataSource:
            return <>Published data source in <BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={20} text={node.parentName} /> </>;
        case NodeType.TableauStory:
            return <>Story in <TableauIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={35} text={node.parentName} /> </>;
        case NodeType.Table:
            if (node.databaseTechnology) {
                return <>Table of type {node.databaseTechnology}</>;
            }
            return <>Table</>;
        default:
            throw new Error(`Node type ${node.type} not supported`);
    }
};
