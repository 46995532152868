import { NavLink } from 'react-router-dom';
import { DataModelIcon, SparklesLeftArrowIcon } from '../../assets/images/icons/DelphiIcons';
import { Cog6ToothIcon, HomeIcon } from '@heroicons/react/24/outline';

const baseNavItems = [
  {
    to: '/',
    Icon: <HomeIcon width="20" height="20" />,
    label: 'Home'
  },
  {
    to: `/data-model`,
    Icon: <DataModelIcon width="20" height="20" fill="#0A225C" />,
    label: 'Data model'
  },
  {
    to: `/model-changes`,
    Icon: <SparklesLeftArrowIcon width="20" height="20" fill="#0A225C" />,
    label: 'Model changes'
  },
  {
    to: `/account`,
    Icon: <Cog6ToothIcon width="20" height="20" />,
    label: 'Account settings'
  }
];

export const MainNavigation = () => {

  return (
    <div className="px-2 w-60">
      <nav>
        {baseNavItems.map((item) => {
          return (
            <NavLink
              key={item.label}
              to={item.to}
              className={({ isActive }) =>
                `mb-1 flex h-12 cursor-pointer text-text-primary items-center rounded-lg ${isActive ? 'bg-slate-100' : ''
                } px-2 hover:bg-slate-100`
              }>
              {item.Icon}
              <span className="ml-3">{item.label}</span>
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};
