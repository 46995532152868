import { store } from "src/infrastructure/state/store";
import { nodesApi } from "src/services/nodes/nodes";
import { SubnodeType } from "src/features/models/discover/INode";
import { ChangeMeta } from "src/features/evolution/IChange";

export const getMetricMeta = async (modelId: string, metricName: string, accountId: number) => {
    const meta: ChangeMeta[] = [{ key: 'euno.ai/analytical_semantic_role', value: 'measure' }];
    const { data: model } = await store.dispatch(nodesApi.endpoints.getExpandedNode.initiate({ accountId, nodeId: modelId }));
    const metric = model?.subnodes.find(s => s.type === SubnodeType.Metric && s.name === metricName);
    if (metric?.subType === 'simple') {
        const measureName = metric.inputMeasures[0];
        const measure = model?.subnodes.find(s => s.type === SubnodeType.Measure && s.name === measureName);
        if (measure?.aggType) {
            meta.push({ key: 'euno.ai/default_aggregation_override', value: measure.aggType });
        }
    }
    return meta;
};
