import { PlusIcon, MinusIcon } from "@heroicons/react/24/solid";
import { useRef, MutableRefObject, useState, useEffect, useMemo } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Xwrapper, useXarrow } from "react-xarrows";
import DiscoverViewArrows from "./DiscoverViewArrows";
import { DiscoverViewSwimlanes } from "./DiscoverViewSwimlanes";
import { ISuperficialNode } from "../../../INode";
import { ExpandedNode, IDAGNode, OnCollapse, SetShowNodeSidepane } from "../types";
import { NodePositionsMap } from "../../../../../../infrastructure/DAG/Types";
import { DAGNode } from "../DAGNode";

type DiscoverDAGCanvasProps = {
    criticalPath: string[];
    nodes: IDAGNode[];
    onCollapse: OnCollapse;
    setShowNodeSidepane: SetShowNodeSidepane;
    selectedNode: ISuperficialNode | null;
    setSelectedNode: (node: ISuperficialNode | null) => void;
    nodesPositions: NodePositionsMap;
    withFilters: boolean;
    expandedNodes: ExpandedNode[];
}

export const DiscoverDAGCanvas = ({ expandedNodes, nodesPositions, criticalPath, nodes, onCollapse, setSelectedNode, setShowNodeSidepane, selectedNode, withFilters }: DiscoverDAGCanvasProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
    const [scale, setScale] = useState(1);
    const updateArrows = useXarrow();
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        setIsHidden(true);
        setTimeout(() => {
            setIsHidden(false);
        }, 100);
    }, [nodes]);

    const nodesWithPositions = useMemo(() => {
        return nodes.filter((node) => nodesPositions.has(node.id));
    }, [nodes, nodesPositions]);

    // Rule is disabled because XArrow library updates the hook on every call which causes an endless update loop
    useEffect(() => {
        updateArrows();
    }, [selectedNode, nodes]); // eslint-disable-line react-hooks/exhaustive-deps

    const zoomIn = () => {
        if (scale < 1.5) {
            setScale(scale + 0.1);
        }
    };

    const zoomOut = () => {
        if (scale > 0.75) {
            setScale(scale - 0.1);
        }
    };

    return (
        <>
            <div {...events} ref={ref} className="absolute flex h-full w-full overflow-auto" style={{ zoom: scale, opacity: isHidden ? 0 : 1 }} id="lineage-wrapper">
                <Xwrapper>
                    {nodesWithPositions.map((node) => (
                        <div
                            className="absolute pb-10 pr-2"
                            style={{ top: nodesPositions.get(node.id)!.y, left: nodesPositions.get(node.id)!.x}}
                            key={node.id}>
                            <DAGNode
                                setSelectedNode={setSelectedNode}
                                node={node}
                                selectedNode={selectedNode}
                                criticalPath={criticalPath}
                                setShowNodeSidepane={setShowNodeSidepane}
                                onCollapse={onCollapse}
                                withFilters={withFilters}
                                expandedNodes={expandedNodes}
                            />
                        </div>
                    ))}
                    <DiscoverViewArrows nodes={nodesWithPositions} selectedNode={selectedNode} />
                </Xwrapper>
                <DiscoverViewSwimlanes nodes={nodesWithPositions} nodesPositions={nodesPositions} />
            </div>
            <div className="fixed fixed bottom-2 right-2 z-20 cursor-pointer bg-white text-tertiary shadow-md">
                <div className="rounded-start border border-slate-300 p-1 hover:bg-slate-50" onClick={zoomIn}>
                    <PlusIcon width="16" height="16" />
                </div>
                <div className="rounded-end border border-slate-300 p-1 hover:bg-slate-50" onClick={zoomOut}>
                    <MinusIcon width="16" height="16" />
                </div>
            </div>
        </>
    );
};
