import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';

interface AlertProps {
  title?: string;
  text: string;
  type: 'warning' | 'error' | 'success' | 'info';
  className?: string;
}

const alertTypeColors = {
  warning: {
    className: 'text-yellow-800 bg-yellow-50 border-yellow-400',
    icon: <ExclamationTriangleIcon width="20" height="20" className="mr-2 text-yellow-400" />
  },
  error: {
    className: 'text-red-800 bg-red-50 border-red-400',
    icon: <ExclamationCircleIcon width="20" height="20" className="mr-2 text-red-400" />
  },
  success: {
    className: 'text-green-800 bg-green-50 border-green-400',
    icon: <CheckCircleIcon width="20" height="20" className="mr-2 text-green-400" />
  },
  info: {
    className: 'text-text-primary bg-muse-50 border-blue-400',
    icon: <ExclamationCircleIcon width="20" height="20" className="mr-2 text-surface-primary" />
  }
};

export const Alert = ({ title, text, type, className }: AlertProps) => {
  return (
    <div className={`flex items-center border-l-4 p-4 ${alertTypeColors[type].className} ${className}`} role="alert">
      <div>{alertTypeColors[type].icon}</div>
      {title && <p className="mr-2">{title}</p>}
      <p className="font-thin">{text}</p>
    </div>
  );
};
