import api from '../api';
import { FolderResponse } from '../folders';
import { Operation } from '../../features/operations/Operation';
import { IDbtCloudJob } from '../../features/projects/IProject';
import { IRevision } from '../../features/evolution/IRevision';
import { transformDamaRevisionResponseToLocalRevision } from './transformers';
import { LuzmoEmbedCredentials } from 'src/features/dashboards/types';

export interface AccountResponse {
  id: number;
  name: string;
  ancestry: FolderResponse[];
}

export const accountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<AccountResponse[], void>({
      query: () => 'accounts',
      providesTags: ['Accounts']
    }),
    getAccountOperations: build.query<
      { total: number; items: Operation[] },
      { accountId: number; page: number; pageSize: number }
    >({
      query: ({ accountId, page, pageSize }) => `accounts/${accountId}/operations?page=${page}&page_size=${pageSize}`,
      keepUnusedDataFor: 0
    }),
    getOperationLogs: build.query<string, { accountId: number; operationId: number }>({
      query: ({ accountId, operationId }) => `accounts/${accountId}/operations/${operationId}/logs`,
      keepUnusedDataFor: 0
    }),
    getAccountOperation: build.query<Operation, { accountId: number; operationId: number; timestamp?: number }>({
      query: ({ accountId, operationId }) => `accounts/${accountId}/operations/${operationId}`,
      keepUnusedDataFor: 0
    }),
    getDbtResourceCode: build.query<{ raw_code: string; compiled_code: string }, { accountId: number; utl: string }>({
      query: ({ accountId, utl }) => `accounts/${accountId}/dbt_resources/lookup/code?utl=${encodeURIComponent(utl)}`,
      keepUnusedDataFor: 0
    }),
    getDbtCloudJobs: build.query<IDbtCloudJob[], { accountId: number; projectId: number }>({
      query: ({ accountId, projectId }) => `accounts/${accountId}/integrations/dbt_cloud/jobs?project_id=${projectId}`,
      keepUnusedDataFor: 0
    }),
    getDamaRevision: build.query<IRevision | null, { accountId: number }>({
      query: ({ accountId }) => `/accounts/${accountId}/data_model/revisions`,
      transformResponse: transformDamaRevisionResponseToLocalRevision
    }),
    getLiquidConsts: build.query<object, { accountId: number, lookerIntegrationId: number }>({
      query: ({ accountId, lookerIntegrationId }) => `accounts/${accountId}/integrations/looker/${lookerIntegrationId}/liquid_consts`,
    }),
    updateLiquidConsts: build.mutation<void, { accountId: number, lookerIntegrationId: number, liquidConsts: object }>({
      query: ({ accountId, lookerIntegrationId, liquidConsts }) => ({
        url: `accounts/${accountId}/integrations/looker/${lookerIntegrationId}/liquid_consts`,
        method: 'POST',
        body: liquidConsts
      })
    }),
    getSavedTableViews: build.query<{ id: number, name: string, config: string }[], { accountId: number, tableKey: string }>({
      query: ({ accountId, tableKey }) => `accounts/${accountId}/table_views/${tableKey}`,
      providesTags: ['SavedTableViews']
    }),
    createSavedTableView: build.mutation<void, { accountId: number, tableKey: string, name: string, config: string }>({
      query: ({ accountId, tableKey, name, config }) => ({
        url: `accounts/${accountId}/table_views/${tableKey}`,
        method: 'POST',
        body: { name, config }
      }),
      invalidatesTags: ['SavedTableViews']
    }),
    updateSavedTableView: build.mutation<void, { accountId: number, tableKey: string, name: string, config: string, viewId: number }>({
      query: ({ accountId, tableKey, name, config, viewId }) => ({
        url: `accounts/${accountId}/table_views/${tableKey}/${viewId}`,
        method: 'PATCH',
        body: { name, config }
      }),
      invalidatesTags: ['SavedTableViews']
    }),
    getLuzmoEmbedCredentials: build.query<LuzmoEmbedCredentials, { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/luzmo/embed_credentials`
    }),
    getLuzmoColumn: build.query<{ name: string }, { accountId: number, columnId: string }>({
      query: ({ accountId, columnId }) => `accounts/${accountId}/luzmo/column/${columnId}`
    }),
    getLuzmoDashboard: build.query<unknown, { accountId: number, dashboardId: string }>({
      query: ({ accountId, dashboardId }) => `accounts/${accountId}/luzmo/dashboard/${dashboardId}`
    }),
  })
});

export const {
  useGetAccountsQuery,
  useGetAccountOperationsQuery,
  useGetOperationLogsQuery,
  useGetAccountOperationQuery,
  useGetDbtResourceCodeQuery,
  useGetDbtCloudJobsQuery,
  useGetLiquidConstsQuery,
  useUpdateLiquidConstsMutation,
  useGetSavedTableViewsQuery,
  useCreateSavedTableViewMutation,
  useUpdateSavedTableViewMutation,
  useGetDamaRevisionQuery,
  useGetLuzmoEmbedCredentialsQuery,
  useLazyGetLuzmoColumnQuery,
  useGetLuzmoDashboardQuery
} = accountsApi;
