import { getEnumKeyByEnumValue } from '../../utils/enumUtils';
import { OperationStatus } from './Operation';

const OperationStatusLabel = ({ status }: { status: OperationStatus }) => {
  let color = '';
  switch (status) {
    case OperationStatus.Failed:
      color = 'text-red-600';
      break;
    case OperationStatus.Cancelled:
      color = 'text-orange-600';
      break;
    case OperationStatus.Completed:
      color = 'text-text-primary';
      break;
    default:
      color = 'text-slate-400';
  }
  return <span className={color}>{getEnumKeyByEnumValue(OperationStatus, status)}</span>;
};

export default OperationStatusLabel;
