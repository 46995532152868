import { Navigate } from 'react-router-dom';
import { Login } from '../features/auth/Login';

const publicRoutes = () => {
  return [
    { path: '/login', element: <Login /> },
    { path: '*', element: <Navigate to="/login" replace /> }
  ];
};

export default publicRoutes;
