import { SparklesLeftArrowIcon } from "../../../../assets/images/icons/DelphiIcons";

type ShiftToDbtBannerProps = {
    onClick: () => void;
}

export const ShiftToDbtBanner = ({ onClick }: ShiftToDbtBannerProps) => {
    return (
        <div className="bg-muse-50 rounded mt-3 p-2 flex justify-between items-center cursor-pointer" onClick={onClick}>
            <div className="items-center flex gap-1 text-text-primary">
                <SparklesLeftArrowIcon width="16" height="16" fill="#0047FF" />
                Shift to dbt
            </div>
            <div className="text-surface-primary">
                Review change draft
            </div>
        </div>
    );
};