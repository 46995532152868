import { AnalyticsBrowser } from '@segment/analytics-next';
import config from '../config';

const client = AnalyticsBrowser.load({ writeKey: config.segmentWriteKey });
let cachedIdentity = '';
let cachedPage = '';

export enum events {
  pageView = 'page_view',
  newFolder = 'new_folder',
  stakeholderRoleUpdated = 'stakeholder_role_updated',
  magicLinkLogin = 'magic_link_login',
  dataModelFilterAdded = 'data_model_filter_added',
  dataModelEnteredNode = 'data_model_entered_node',
  nodeSidepaneOpened = 'node_sidepane_opened',
  dagSearched = 'dag_searched',
  dagExpanded = 'dag_expanded',
  nodeUsageExpanded14Days = 'node_usage_expanded_14d',
  nodeUsageExpanded30Days = 'node_usage_expanded_30d',
  nodeUsageExpanded60Days = 'node_usage_expanded_60d',
  dataModelPRCreated = 'data_model_pr_created',
  dataModelChangeStateUpdated = 'data_model_change_state_updated',
  dataModelChangeEdited = 'data_model_change_edited',
}

export const identifyUser = (parameter: object) => {
  const hashedIdentity = JSON.stringify(parameter);
  if (cachedIdentity === hashedIdentity) return;
  cachedIdentity = hashedIdentity;
  client.identify(parameter);
};

export const trackEvent = (eventName: events, properties?: object) => {
  client.track(eventName, properties);
};

export const resetIdentity = () => {
  client.reset();
};

export const trackPageChange = (path: string) => {
  if (cachedPage === path) return;
  cachedPage = path;
  client.page(path);
};