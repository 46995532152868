import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import privateRoutes from './routes/private';
import publicRoutes from './routes/public';
import RouterRoot from './routes/RouterRoot';
import ErrorPage from './components/layout/ErrorPage';

const router = createBrowserRouter([
  { element: <RouterRoot />, children: [...privateRoutes(), ...publicRoutes()], errorElement: <ErrorPage /> }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
