import { OperationStatus } from "../operations/Operation";
import IBuild, { DataSyncStatus } from "./IBuild";

const getDataSyncStatus = (build: IBuild): DataSyncStatus => {
    if (build.dataSyncRuns.length === 0) {
        return DataSyncStatus.NONE;
    }
    else if (build.dataSyncRuns.every(r => r.status === OperationStatus.Failed)) {
        return DataSyncStatus.FAILED;
    }
    else if (build.dataSyncRuns.every(r => r.status === OperationStatus.Completed)) {
        return DataSyncStatus.SUCCESS;
    }
    else if (build.dataSyncRuns.some(r => r.status === OperationStatus.Failed)) {
        return DataSyncStatus.PARTIAL;
    }
    else {
        return DataSyncStatus.PENDING;
    }
};

export default getDataSyncStatus;