import Layout from '../../../components/layout/Layout';
import { ModelChangesTab } from './Types';
import { ModelChangesList } from './ModelChangesList';
import TopBar from '../../../components/layout/TopBar';
import { PageTabs } from '../../../components/PageTabs';
import { PatsList } from './PatsList';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

export const ModelChanges = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = useMemo(() => searchParams.get('tab') as ModelChangesTab, [searchParams]);

  const setTab = useCallback((tab: ModelChangesTab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!tab) {
      setTab(ModelChangesTab.Proposals);
    }
  }, [tab, setTab]);

  return (
    <Layout>
      <TopBar>
        <PageTabs
          tabs={[
            { name: 'Proposals', isActive: tab === ModelChangesTab.Proposals, onClick: () => setTab(ModelChangesTab.Proposals) },
            { name: 'In progress', isActive: tab === ModelChangesTab.InProgress, onClick: () => setTab(ModelChangesTab.InProgress) },
            { name: 'Archived', isActive: tab === ModelChangesTab.Archived, onClick: () => setTab(ModelChangesTab.Archived) }
          ]}
        />
      </TopBar>
      <div className="py-8 px-14">
        {
          tab === ModelChangesTab.Proposals && (
            <PatsList />
          )
        }
        {
          tab !== ModelChangesTab.Proposals && (
            <ModelChangesList tab={tab} />
          )
        }
      </div>
    </Layout>
  );

};