import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export default createSlice({
  name: 'isMenuCollapsed',
  initialState: false,
  reducers: {
    setIsMenuCollapsed: (_state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const selectIsMenuCollpased = (state: RootState) => state.isMenuCollapsed;
