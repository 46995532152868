import { useDescope } from '@descope/react-sdk';
import EulaLink from '../../features/eula/EulaLink';

const Footer = () => {
  return (
    <div className="mx-auto mb-2 mt-auto flex w-fit items-center gap-1 text-sm text-tertiary">
      DelphiIO
      <Bullet />
      <EulaLink />
      <Bullet />
      <LogoutLink />
    </div>
  );
};

const Bullet = () => {
  return (
    <div className="h-[3px] w-[3px] rounded bg-tertiary"></div>
  );
};

const LogoutLink = () => {
  const descope = useDescope();
  const logout = async () => {
    await descope.logout();
    window.location.href = '/';
  };

  return (
    <span onClick={logout} className="cursor-pointer">Logout</span>
  );
};

export default Footer;
