interface ConnectedBulletPointsProps {
  bulletPoints: {
    bulletColor: string;
    lineColor: string;
    textColor: string;
    content: JSX.Element | string;
  }[];
}

const ConnectedBulletPoints = ({ bulletPoints }: ConnectedBulletPointsProps) => {
  return (
    <div className="flex flex-col">
      {bulletPoints.map((bullet, index) => (
        <>
          <div className={`flex items-center gap-2 text-sm font-light ${bullet.textColor}`}>
            <div className={`h-1.5 w-1.5 rounded-full ${bullet.bulletColor}`}></div>
            {bullet.content}
          </div>
          {index !== bulletPoints.length - 1 && (
            <div className={`-mb-1 -mt-1 ml-0.5 h-4 w-0.5 ${bullet.lineColor}`}></div>
          )}
        </>
      ))}
    </div>
  );
};

export default ConnectedBulletPoints;
