import Xarrow from 'react-xarrows';
import { memo, useEffect, useMemo, useState } from 'react';
import { ISuperficialNode } from '../../../INode';
import getCriticalPath from '../../../getCiriticalPath';
import { getNodeIdForDom } from '../../../getNodeIdForDom';

interface Arrow {
  start: string;
  end: string;
  isHighlighted: boolean;
}

interface DiscoverViewArrowsProps {
  nodes: ISuperficialNode[];
  selectedNode: ISuperficialNode | null;
}

const generateArrows = (nodes: ISuperficialNode[], selectedNodeId: string | null, criticalPath: string[]) => {
  const arrows: Arrow[] = [];
  nodes.forEach((node) => {
    node.parents.forEach((parentId) => {
      const parentNode = nodes.find((m) => m.id === parentId);
      if (parentNode) {
        const isHighlighted =
          selectedNodeId === node.id || (criticalPath.includes(node.id) && criticalPath.includes(parentId));
        arrows.push({
          start: `header-${getNodeIdForDom(parentId)}`,
          end: `header-${getNodeIdForDom(node.id)}`,
          isHighlighted
        });
      }
    });
  });
  return arrows;
};

const getFullPath = (selectedNode: ISuperficialNode | null, nodes: ISuperficialNode[]) => {
  if (selectedNode) {
    return [
      ...getCriticalPath(nodes, selectedNode.id, 'right'),
      ...getCriticalPath(nodes, selectedNode.id, 'left'),
      selectedNode.id
    ];
  }
  return [];
};

const DiscoverViewArrows = ({ nodes, selectedNode }: DiscoverViewArrowsProps) => {
  const [delayedModels, setDelayedModels] = useState<ISuperficialNode[]>([]);
  const [delayedSelectedNode, setDelayedSelectedNode] = useState<ISuperficialNode | null>(null);
  const [firstRenderDelay, setFirstRenderDelay] = useState(true); // This is a hack to avoid color flickering issue in the package
  const criticalPath = useMemo(() => getFullPath(delayedSelectedNode, nodes), [nodes, delayedSelectedNode]);

  useEffect(() => {
    if (firstRenderDelay) {
      setTimeout(() => setFirstRenderDelay(false), 100);
    }
  }, [firstRenderDelay]);

  useEffect(() => {
    setTimeout(() => setDelayedModels(nodes), 10);
  }, [nodes, setDelayedModels]);

  useEffect(() => {
    setTimeout(() => setDelayedSelectedNode(selectedNode), 40);
  }, [selectedNode, setDelayedSelectedNode]);

  const arrows = useMemo(
    () => generateArrows(delayedModels, delayedSelectedNode?.id || null, criticalPath),
    [delayedModels, delayedSelectedNode, criticalPath]
  );
  const filteredNodesHash = delayedModels.map((m) => m.id).join('_');

  if (delayedModels.length !== nodes.length) {
    return null;
  }

  return (
    <div className={`transition-opacity duration-300 ${firstRenderDelay ? 'opacity-0': 'opacity-100'}`}>
      <MemoizedArrows arrows={arrows} filteredNodesHash={filteredNodesHash} selectedNode={delayedSelectedNode} />
    </div>
  );
};

const Arrows = ({
  arrows,
  filteredNodesHash,
  selectedNode
}: {
  arrows: Arrow[];
  filteredNodesHash: string;
  selectedNode: ISuperficialNode | null;
}) => {
  return (
    <div className="discoverArrowsContainer">
      {arrows.map((arrow) => (
        <Xarrow
          key={`${JSON.stringify(arrow)}_${filteredNodesHash}`}
          start={arrow.start}
          end={arrow.end}
          zIndex={1}
          color={arrow.isHighlighted ? '#56A5FF' : '#CBD5E1'}
          strokeWidth={2}
          showHead={false}
          startAnchor={{
            position: 'right',
            offset: { x: 8 }
          }}
          endAnchor={{
            position: 'left',
            offset: { x: -8 }
          }}
          arrowBodyProps={{
            opacity: !arrow.isHighlighted && selectedNode !== null ? 0.25 : 1
          }}
        />
      ))}
    </div>
  );
};

const MemoizedArrows = memo(Arrows);

export default DiscoverViewArrows;
