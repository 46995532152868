export enum OperationStatus {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Canceling = 'canceling'
}

export interface Operation {
  id: number;
  name: string;
  status: OperationStatus;
  start_time: string;
  end_time: string;
  project_id: number;
  result: unknown;
}
