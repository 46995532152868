import { Operation, OperationStatus } from '../features/operations/Operation';
import { accountsApi } from '../services/accounts/accounts';
import { store } from './state/store';

const waitForOperationToComplete = (
  operationId: number,
  accountId: number,
  interval: number = 1000,
  maxTries: number = 30
): Promise<Operation> => {
  return new Promise((resolve, reject) => {
    let tries = 0;
    const intervalId = setInterval(async () => {
      tries++;
      const { data, isSuccess, error } = await store.dispatch(
        accountsApi.endpoints.getAccountOperation.initiate({ accountId, operationId, timestamp: Date.now() })
      );
      if (error) {
        clearInterval(intervalId);
        reject(error);
      } else if (isSuccess && [OperationStatus.Completed, OperationStatus.Failed].includes(data.status)) {
        clearInterval(intervalId);
        resolve(data);
      } else if (tries > maxTries) {
        clearInterval(intervalId);
        reject('Operation timeout');
      }
    }, interval);
  });
};

export default waitForOperationToComplete;
