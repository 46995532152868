import keys from './localStorageKeys';

export const get = (key: keys) => {
    const value = localStorage.getItem(key);
    if (value === null) {
        return undefined;
    }
    return JSON.parse(value);
};

export const set = (key: keys, value: number | string | object) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const reset = () => {
    for (const key in keys) {
        localStorage.removeItem(key);
    }
};
