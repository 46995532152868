import { NodeIcon } from '../../NodeIcon';
import { ISuperficialNode, nodeNameMap } from '../../INode';
import { TableColumnProps } from '../../../../../components/Table/types';
import { DescriptionColumn } from './DescriptionColumn';
import { SparklesLeftArrowIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { TextWithEllipsisAndTooltip } from '../../../../../components/TextWithEllipsisAndTooltip';
export const discoverTableColumns: TableColumnProps[] = [
  {
    name: 'Type',
    property: 'type',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center"><NodeIcon type={(row as ISuperficialNode).type} databaseTechnology={(row as ISuperficialNode).databaseTechnology} /> {nodeNameMap.get((row as ISuperficialNode).type)}</div>,
    width: '200px',
    isNotRemovable: true,
    sortBy: 'type',
  },
  {
    name: 'Name',
    property: 'name',
    selector: row => <TextWithEllipsisAndTooltip text={(row as ISuperficialNode).name} maxChars={40} />,
    grow: 10,
    isNotRemovable: true,
    sortBy: 'name',
  },
  {
    name: 'Description',
    property: 'description',
    selector: row => <DescriptionColumn row={row as ISuperficialNode} />,
    width: '300px',
    sortBy: 'description',
  },
  {
    name: 'Columns',
    property: 'number_of_columns',
    selector: row => printNumber((row as ISuperficialNode).numberOfColumns),
    width: '110px',
    sortBy: 'number_of_columns',
  },
  {
    name: 'Dimensions',
    selector: row => printNumber((row as ISuperficialNode).numberOfDimensions),
    width: '140px',
    property: 'number_of_dimensions',
    sortBy: 'number_of_dimensions',
  },
  {
    name: 'Measures',
    selector: row => printNumber((row as ISuperficialNode).numberOfMeasures),
    width: '110px',
    property: 'number_of_measures',
    sortBy: 'number_of_measures',
  },
  {
    name: 'Entities',
    selector: row => printNumber((row as ISuperficialNode).numberOfEntities),
    width: '110px',
    property: 'number_of_entities',
    sortBy: 'number_of_entities',
  },
  {
    name: 'Related Metrics',
    selector: row => printNumber((row as ISuperficialNode).numberOfMetrics),
    width: '150px',
    property: 'number_of_metrics',
    sortBy: 'number_of_metrics',
  },
  {
    name: 'Custom Fields',
    selector: row => printNumber((row as ISuperficialNode).numberOfCustomFields),
    width: '140px',
    property: 'number_of_custom_fields',
    sortBy: 'number_of_custom_fields',
  },
  {
    name: 'Proposals',
    property: 'has_shift_left_potential',
    selector: row => (row as ISuperficialNode).hasProposals ? <SparklesLeftArrowIcon width="16" height="16" fill='#0047FF' data-tag="allowRowEvents" /> : '',
    sortBy: 'has_shift_left_potential',
    width: '120px'
  },
  {
    name: 'Trivial SQL',
    property: 'is_trivial_sql',
    selector: row => {
      const value = (row as ISuperficialNode).isTrivialSql;
      if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        }
        else {
            return '';
        }
      },
    sortBy: 'is_trivial_sql',
    width: '120px'
  },
  {
    name: 'First seen',
    property: 'first_seen',
    selector: row => (row as ISuperficialNode).firstSeen && (new Date((row as ISuperficialNode).firstSeen)).toISOString().split('T')[0],
    width: '150px',
    sortBy: 'first_seen'
  },
  {
    name: 'Materialization',
    property: 'dbt_materialization_strategy',
    selector: row => (row as ISuperficialNode).dbtMaterializationStrategy,
    width: '150px',
    sortBy: 'materialized',
  },
  {
    name: 'Tableau connection',
    property: 'tableau_connection',
    selector: row => (row as ISuperficialNode).tableauConnection,
    width: '180px',
    sortBy: 'tableau_connection',
  },
  {
    name: 'Tableau workbook',
    property: 'tableau_workbook',
    selector: row => (row as ISuperficialNode).tableauWorkbook,
    width: '200px',
    sortBy: 'tableau_workbook',
  },
  {
    name: 'Tableau project',
    property: 'tableau_project',
    selector: row => (row as ISuperficialNode).tableauProject,
    width: '200px',
    sortBy: 'tableau_project',
  },
  {
    name: 'Tags',
    property: 'tags',
    selector: row => (row as ISuperficialNode).tags?.join(', '),
    sortBy: 'tags',
  },
  {
    name: 'dbt project',
    property: 'dbt_project',
    selector: row => (row as ISuperficialNode).dbtProject,
    width: '150px',
    sortBy: 'dbt_project',
  },
  {
    name: 'Database schema',
    property: 'database_schema',
    selector: row => (row as ISuperficialNode).databaseSchema,
    width: '200px',
    sortBy: 'database_schema',
  },
  {
    name: 'Database',
    property: 'database',
    selector: row => (row as ISuperficialNode).database,
    sortBy: 'database',
    width: '150px',
  },
  {
    name: 'Looker folder',
    property: 'looker_folder',
    selector: row => (row as ISuperficialNode).lookerFolder,
    width: '150px',
    sortBy: 'looker_folder',
  },
  {
    name: 'Looker model',
    property: 'looker_model',
    selector: row => (row as ISuperficialNode).lookerModel,
    width: '150px',
    sortBy: 'looker_model',
  },
  {
    name: 'Looker instance',
    property: 'looker_host',
    selector: row => (row as ISuperficialNode).lookerHost,
    width: '150px',
    sortBy: 'looker_host',
  },
  {
    name: 'Source directory',
    property: 'source_directory',
    selector: row => (row as ISuperficialNode).sourceDirectory,
    width: '150px',
    sortBy: 'source_directory',
  },
  {
    name: 'UTL',
    property: 'utl',
    selector: row => (row as ISuperficialNode).id,
    width: '200px',
    sortBy: 'utl',
  },
  {
    name: 'Owner',
    property: 'owner',
    selector: row => (row as ISuperficialNode).owner,
    width: '200px',
    sortBy: 'owner',
  },
  {
    name: 'Total views 30 days',
    selector: row => printNumber((row as ISuperficialNode).totalViews30Days),
    width: '210px',
    property: 'last_30d_views',
    sortBy: 'last_30d_views',
  },
  {
    name: 'Total views 7 days',
    selector: row => printNumber((row as ISuperficialNode).totalViews7Days),
    width: '210px',
    property: 'last_7d_views',
    sortBy: 'last_7d_views',
  },
  {
    name: 'Total usage 14 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries14Days),
    width: '210px',
    property: 'total_queries_14d',
    sortBy: 'total_queries_14d',
  },
  {
    name: 'Total usage 30 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries30Days),
    width: '210px',
    property: 'total_queries_30d',
    sortBy: 'total_queries_30d',
  },
  {
    name: 'Total usage 60 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries60Days),
    width: '210px',
    property: 'total_queries_60d',
    sortBy: 'total_queries_60d'
  },
  {
    name: 'Distinct users 14 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers14Days),
    width: '210px',
    property: 'distinct_users_14d',
    sortBy: 'distinct_users_14d'
  },
  {
    name: 'Distinct users 30 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers30Days),
    width: '210px',
    property: 'distinct_users_30d',
    sortBy: 'distinct_users_30d'
  },
  {
    name: 'Distinct users 60 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers60Days),
    width: '210px',
    property: 'distinct_users_60d',
    sortBy: 'distinct_users_60d'
  }
];

const printNumber = (value?: number | null) => typeof value === 'number' ? `${Math.round(value)}` : '';
