import { useEffect } from "react";
import { AggregateTableTimeGranularity, NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../types";
import { generateAggregateTableColumn } from "../utilities/generateAggregateTableColumn";
import Select, { Option } from "../../../../../components/form/Select";

type AggregateTableMetricTimeFormProps = {
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
};

export const AggregateTableMetricTimeForm = ({ changeData, setChangeData }: AggregateTableMetricTimeFormProps) => {

    useEffect(() => {
        const columnsWithoutMetricTime = changeData.columns.filter(c => c.type !== NewAggregateTableChangeColumnType.metric_time);
        if (changeData.metricTime && !changeData.columns.some(c => c.type === NewAggregateTableChangeColumnType.metric_time)) {
            const metricTimeColumn = generateAggregateTableColumn({ type: NewAggregateTableChangeColumnType.metric_time, name: 'metric_time' });
            setChangeData({ ...changeData, columns: [...columnsWithoutMetricTime, metricTimeColumn] });
        }
        else if (!changeData.metricTime && changeData.columns.some(c => c.type === NewAggregateTableChangeColumnType.metric_time)) {
            setChangeData({ ...changeData, columns: columnsWithoutMetricTime });
        }
    }, [changeData, setChangeData]);

    const onChange = (option: Option | Option[]) => {
        setChangeData({ ...changeData, metricTime: (option as Option).value.toString() as AggregateTableTimeGranularity || null });
    };

    return (
        <div className="flex gap-2 w-full">
            <Select
                placeholder="Select metric time"
                className="w-full rounded border shadow"
                options={options}
                value={changeData.metricTime || ''}
                onChange={onChange}
            />
        </div>
    );
};

const options: Option[] = [
    { label: 'Off', value: '' },
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Year', value: 'year' }
];