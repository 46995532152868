import IBuild from "../../features/builds/IBuild";
import { IProject } from "../../features/projects/IProject";
import { GetProjectBuildReponse, ProjectResponse } from "./types";

export const transformProjectResponseToLocalProject = (response: ProjectResponse): IProject => {
    return {
        id: response.id,
        name: response.name,
        parent: response.parent,
        repository: {
            provider: response.repository_provider,
            id: response.repository_parameters.id,
            name: response.repository_parameters.name,
            ownerId: response.repository_parameters.owner_id,
            ownerLogin: response.repository_parameters.owner_login,
            cloneUrl: response.repository_parameters.clone_url,
            branch: response.repository_parameters.branch,
            defaultTargetBranch: response.repository_parameters.default_target_branch_name
        },
        dbtCloudDeploymentJob: response.dbt_cloud_job
    };
};

export const transformBuildResponseToLocalBuild = (build: GetProjectBuildReponse): IBuild => {
    return {
        id: build.id,
        lifecycleState: build.lifecycle_state,
        buildType: build.build_type,
        buildTime: build.build_time,
        revisionSha: build.revision_sha,
        errorMessage: build.error_message,
        uploadStartTime: build.upload_start_time,
        buildTarget: build.build_target,
        branchNames: build.branch_names,
        modelsCount: build.models_cnt,
        sourcesCount: build.sources_cnt,
        operationId: build.process_operation_id,
        dataSyncRuns: build.data_sync_runs.map((run) => ({
            status: run.status,
            operationId: run.operation_id,
            name: run.data_sync_name
        }))
    };
};
