import { useGetProjectBranchesQuery, useGetProjectConfigurationQuery, useSetDefaultTargetBranchMutation } from '../../../../services/projects/projects';
import { useMemo, useState } from 'react';
import PageLoader from '../../../../components/loaders/PageLoader';
import { IProjectConfiguration, IProject } from '../../IProject';
import Button from '../../../../components/button/Button';
import { PlusIcon } from '@heroicons/react/24/solid';
import { ButtonTypes } from '../../../../components/button/types';
import IBranch from '../../branches/IBranch';
import StakeholdersInviteModal from '../../../stakeholders/InviteStakeholdersModal';
import StakeholderEntityTypes from '../../../stakeholders/stakeholdersEntityTypes';
import ConnectBranchModal from '../../branches/ConnectBranchModal';
import DisconnectBranchModal from '../../branches/DisconnectBranchModal';
import BranchesList from './BranchList';
import BranchSyncConfigurationModal from '../../branches/syncConfigurationModal/BranchSyncConfigurationModal';
import { notify } from '../../../../components/Toaster';
import { extractErrorMessage } from '../../../../services/api';

const ManageProjectBranches = ({ project }: { project: IProject }) => {
  const [showConnectBranchModal, setShowConnectBranchModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [actionBranch, setActionBranch] = useState<IBranch | null>(null);
  const { data: branches = [], isLoading: isLoadingBranches } = useGetProjectBranchesQuery({ id: project.id });
  const [showSyncConfigurationModal, setShowSyncConfigurationModal] = useState(false);
  const getProjectConfigurationQuery = useGetProjectConfigurationQuery({ projectId: project.id });
  const projectConfiguration: IProjectConfiguration | null = useMemo(
    () => getProjectConfigurationQuery.data || null,
    [getProjectConfigurationQuery]
  );
  const [setDefaultTargetBranch, {  isLoading: isLoadingDefaultTargetBranch }] = useSetDefaultTargetBranchMutation();

  const onSetDefaultTargetBranch = async (branch: IBranch) => {
    try {
      await setDefaultTargetBranch({ projectId: project.id,  branchId: branch.id }).unwrap();
      notify(`Successfully set default target branch for project`, 'success');
    } catch (e) {
      notify(`Failed to set default target branch: ${extractErrorMessage(e).message}`, 'error');
    }
  };


  if (isLoadingBranches || !projectConfiguration || isLoadingDefaultTargetBranch) {
    return <PageLoader />;
  }

  return (
    <div className="mb-8 mt-3">
      <div className="mb-2 flex h-8 justify-between">
        <div className="flex items-center gap-2 text-lg text-text-primary">
          <span>Branches from</span>
          <span className="font-medium">{project.repository.name}</span>
          <span>repository</span>
        </div>
        <Button
          text="Connect branch"
          icon={<PlusIcon width="16" height="16" />}
          type={ButtonTypes.secondary}
          onClick={() => setShowConnectBranchModal(true)}
        />
      </div>
      <BranchesList
        branches={branches}
        projectConfiguration={projectConfiguration}
        project={project}
        setActionBranch={setActionBranch}
        setShowInviteModal={setShowInviteModal}
        setShowDisconnectModal={setShowDisconnectModal}
        setShowSyncConfigurationModal={setShowSyncConfigurationModal}
        onSetDefaultTargetBranch={onSetDefaultTargetBranch}
      />
      <ConnectBranchModal
        isOpen={showConnectBranchModal}
        onClose={() => setShowConnectBranchModal(false)}
        project={project}
        projectBranches={branches}
      />
      {actionBranch && showInviteModal && (
        <StakeholdersInviteModal
          isOpen={showInviteModal}
          onClose={() => setShowInviteModal(false)}
          entityType={StakeholderEntityTypes.branch}
          entityId={actionBranch.id}
          entityName={actionBranch.name}
        />
      )}
      {actionBranch && showDisconnectModal && (
        <DisconnectBranchModal
          isOpen={showDisconnectModal}
          onClose={() => setShowDisconnectModal(false)}
          branch={actionBranch}
        />
      )}
      {actionBranch && showSyncConfigurationModal && (
        <BranchSyncConfigurationModal
          onClose={() => setShowSyncConfigurationModal(false)}
          isOpen={showSyncConfigurationModal}
          projectConfiguration={projectConfiguration}
          branch={actionBranch}
        />
      )}
    </div>
  );
};

export default ManageProjectBranches;
