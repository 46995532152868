export const FormField = ({
    children,
    label,
    labelWidth = 'w-28',
    helper = '',
    error = ''
}: {
    children?: React.ReactNode;
    label: string;
    labelWidth?: string;
    helper?: string;
    error?: string;
}) => {
    return (
        <div>
            <div className="flex items-center text-text-primary">
                <div className={labelWidth}>{label}</div>
                <div className="flex-1">{children}</div>
            </div>
            {helper && <div className="text-xs text-tertiary mt-2">{helper}</div>}
            {error && <div className="text-xs text-danger mt-2">{error}</div>}
        </div>
    );
};