import { ChartBarIcon, MagnifyingGlassIcon, UserIcon } from '@heroicons/react/24/solid';
import { capitalize } from '../../../../utils/stringsUtils';
import Input from '../../../../components/form/Input';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Select, { Option } from '../../../../components/form/Select';
import { DataModelFilter, SortOptions } from '../Types';
import { useGetAccountProjectsQuery } from '../../../../services/projects/projects';
import { selectActiveAccountId } from '../../../../infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { selectActiveProject } from '../../../../infrastructure/state/slices/activeProjectSlice';
import { FiltersBar } from '../../../../components/Table/TableFilters/FiltersBar';
import { SelectFilter } from '../../../../components/Table/TableFilters/SelectFilter';
import { FreeTextFilter } from '../../../../components/Table/TableFilters/FreeTextFilter';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { SparklesLeftArrowIcon } from '../../../../assets/images/icons/DelphiIcons';
import { ChangeType } from '../../IChange';
import { Filter, FilterValue } from '../../../../components/Table/TableFilters/types';

interface DataModelFiltersProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  sortBy?: string;
  setSortBy?: (sortBy: SortOptions) => void;
  selectedProjectId: number | null;
  setSelectedProjectId: (projectId: number) => void;
  showSort: boolean;
  tableName: string;
  onFilterValuesChange: (filterValues: Record<string, FilterValue>) => void;
}

export const DataModelFilters = ({
  searchInput,
  setSearchInput,
  sortBy,
  setSortBy,
  onFilterValuesChange,
  selectedProjectId,
  setSelectedProjectId,
  showSort,
  tableName
}: DataModelFiltersProps) => {
  const [delayedSearchInput, setDelayedSearchInput] = useState(searchInput);
  const accountId = useSelector(selectActiveAccountId);
  const getProjectsQuery = useGetAccountProjectsQuery({ accountId });
  const projects = useMemo(() => getProjectsQuery.data || [], [getProjectsQuery.data]);
  const activeProjectId = useSelector(selectActiveProject);

  useEffect(() => {
    if (activeProjectId && !selectedProjectId) {
      setSelectedProjectId(activeProjectId);
    }
  }, [activeProjectId, selectedProjectId, setSelectedProjectId]);

  useEffect(() => {
    const firstProject = projects[0]?.id;
    if (firstProject && !selectedProjectId) {
      setSelectedProjectId(firstProject);
    }
  }, [projects, selectedProjectId, setSelectedProjectId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchInput(delayedSearchInput);
    }, 300);
    return () => clearTimeout(timeout);
  }, [delayedSearchInput, setSearchInput]);

  const filters: Filter[] = [
    {
      name: DataModelFilter.type,
      menuIcon: <SparklesLeftArrowIcon width="20" height="20" fill="#94a3b8" />,
      filterIcon: <SparklesLeftArrowIcon width="16" height="16" fill="#94a3b8" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => typeFilterOptions.find(o => o.value === value)?.label || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <SelectFilter
          label="Type"
          options={typeFilterOptions}
          isMulti={true}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
        />
    },
    {
      name: DataModelFilter.owner,
      menuIcon: <UserIcon width="20" height="20" />,
      filterIcon: <UserIcon width="16" height="16" className="text-muse-400" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => value || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <FreeTextFilter
          value={value}
          onChange={onChange}
          onClose={onClose}
          onSave={onSave}
          placeholder='Owner'
          label='Owner'
        />
    },
    {
      name: DataModelFilter.createdAt,
      menuIcon: <CalendarIcon width="20" height="20" />,
      filterIcon: <CalendarIcon width="16" height="16" className="text-muse-400" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => dateFilterOptions.find(o => o.value === value)?.label || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <SelectFilter
          label="Created at"
          options={dateFilterOptions}
          isMulti={true}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
        />
    },
    {
      name: DataModelFilter.usage14d,
      menuIcon: <ChartBarIcon width="20" height="20" />,
      filterIcon: <ChartBarIcon width="16" height="16" className="text-muse-400" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => usageOptions.find(o => o.value === value)?.label || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <SelectFilter
          label="Total usage 14D"
          options={usageOptions}
          isMulti={false}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
        />,
    },
    {
      name: DataModelFilter.usage30d,
      menuIcon: <ChartBarIcon width="20" height="20" />,
      filterIcon: <ChartBarIcon width="16" height="16" className="text-muse-400" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => usageOptions.find(o => o.value === value)?.label || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <SelectFilter
          label="Total usage 30D"
          options={usageOptions}
          isMulti={false}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
        />,
    },
    {
      name: DataModelFilter.usage60d,
      menuIcon: <ChartBarIcon width="20" height="20" />,
      filterIcon: <ChartBarIcon width="16" height="16" className="text-muse-400" />,
      isActive: (value: FilterValue) => !!value,
      value: null,
      presentValue: (value: FilterValue) => usageOptions.find(o => o.value === value)?.label || '',
      isDisabled: false,
      component: ({ value, onChange, onSave, onClose }) =>
        <SelectFilter
          label="Total usage 60D"
          options={usageOptions}
          isMulti={false}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
        />,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between">
        <FiltersBar tableName={tableName} onFilterValuesChange={onFilterValuesChange} filters={filters} />
        <div className="flex items-center gap-2">
          <Input
            placeholder="Search"
            onInputChange={(e: ChangeEvent<HTMLInputElement>) => setDelayedSearchInput(e.target.value)}
            value={delayedSearchInput}
            icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
            height="h-8"
            background="bg-slate-100"
            border="border-slate-100"
            shadow=""
            rounded="rounded-2xl"
            highlightBorder=""
          />
          {
            showSort && sortBy && setSortBy && (
              <div>
                <Select
                  options={['newest', 'oldest'].map((option) => ({ value: option, label: capitalize(option) }))}
                  value={sortBy}
                  onChange={(option) => setSortBy((option as Option).value as SortOptions)}
                  className="w-28 bg-surface-light"
                  background="#F8FAFC"
                  isSearchable={false}
                  menuPortalDistance="-15px"
                />
              </div>
            )
          }
          <div className="flex items-center text-text-primary">
            Project:
            <Select
              options={projects.map((project) => ({ value: project.id, label: project.name }))}
              value={selectedProjectId}
              onChange={(option) => setSelectedProjectId((option as Option).value as number)}
              className="w-48 bg-surface-light"
              background="#F8FAFC"
              isSearchable={false}
              menuPortalDistance="-15px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


const usageOptions = [
  { label: '0', value: '0' },
  { label: '1-100', value: '1-100' },
  { label: '101-500', value: '101-500' },
  { label: '501-1,000', value: '501-1000' },
  { label: '1,001-5,000', value: '1001-5000' },
  { label: '5,001-10,000', value: '5001-10000' },
  { label: '10,001+', value: '10001+' },
];

const typeFilterOptions = [
  { label: ChangeType.NewColumn, value: ChangeType.NewColumn, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#94a3b8" /> },
  { label: ChangeType.NewMeasure, value: ChangeType.NewMeasure, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#94a3b8" /> },
  { label: ChangeType.NewModel, value: ChangeType.NewModel, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#94a3b8" /> },
  { label: ChangeType.NewAggregateTable, value: ChangeType.NewAggregateTable, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#94a3b8" /> },
];

const dateFilterOptions = [
  {
    label: 'Last 7 Days',
    value: 'last7Days',
    icon: <CalendarIcon width="20" height="20" />
  },
  {
    label: 'Last 30 Days',
    value: 'last30Days',
    icon: <CalendarIcon width="20" height="20" />
  },
  {
    label: 'Last 3 Months',
    value: 'last3Months',
    icon: <CalendarIcon width="20" height="20" />
  }
];