import Modal from '../../../../components/Modal/Modal';
import { ButtonTypes } from '../../../../components/button/types';
import { useEffect, useMemo, useState } from 'react';
import { IExpandedNode, ISubnode, NodeType } from '../INode';
import { NodeIcon } from '../NodeIcon';
import { CodeViewer } from '../../../../components/CodeViewer/CodeViewer';
import { CodeViewerProps } from '../../../../components/CodeViewer/types';

interface CodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  parentNode: IExpandedNode;
  subnode?: ISubnode;
}

const CodeModal = ({ isOpen, onClose, parentNode, subnode }: CodeModalProps) => {
  const node = subnode || parentNode;
  const nodeCodes = useMemo(() => getCodesForNode(node), [node]);
  const nodeCodeTypes = useMemo(() => Object.keys(nodeCodes), [nodeCodes]);
  const [showCodeType, setShowCodeType] = useState(nodeCodeTypes[0]);
  const activeCodes = nodeCodes[showCodeType];

  //Reset active code type on modal change
  useEffect(() => {
    if (isOpen) {
      setShowCodeType(nodeCodeTypes[0]);
    }
  }, [setShowCodeType, isOpen, nodeCodeTypes]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      isOpen={isOpen}
      onClose={onClose}
      title="Viewing code"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-24' }]}>
      <div className="h-[50vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <NodeIcon type={parentNode.type} subnodeType={subnode?.type} iconSize={18} />
            <span className="text-text-primary">{node.name}</span>
          </div>
          <div className="flex rounded-lg bg-slate-200 p-0.5 text-secondary">
            {
              nodeCodeTypes.map((codeType) => (
                <div
                  key={codeType}
                  className={`cursor-pointer rounded-lg px-2 py-1 text-xs ${showCodeType === codeType ? 'bg-white' : 'bg-slate-200'
                    }`}
                  onClick={() => setShowCodeType(codeType)}>
                  {codeType}
                </div>
              ))
            }
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {
            activeCodes.map((activeCode, index) => (
              <CodeViewer {...activeCode} key={index} className={'max-h-[600px]'} />
            ))
          }
        </div>
      </div>
    </Modal>
  );
};

const getCodesForNode = (node: IExpandedNode | ISubnode): { [CodeType: string]: CodeViewerProps[] } => {
  const defaultCodesForNode = {
    Source: [{ code: node.rawCode }],
    Compiled: [{ code: node.compiledCode }],
    Semantic: [{ code: node.semanticCode }]
  };

  const typeSpecificCodesForNode: { [nodeType: string]: { [CodeType: string]: CodeViewerProps[] } } = {
    [NodeType.LookerView]: {
      Source: [{ code: node.rawCode || '' }],
      'Delphi Compiled': [{ code: node.compiledCode || '', fileName: 'Transpiled SQL', collapsable: true }, { code: node.semanticCode || '', fileName: 'MetricFlow', collapsable: true }],
    }
  };

  const codesForNode = typeSpecificCodesForNode[node.type] || defaultCodesForNode;
  const filteredNonEmptyCodes = Object.entries(codesForNode).reduce((acc, [codeType, codeProps]) => {
    const filteredCodes = codeProps.filter((code) => code.code);
    if (filteredCodes.length > 0) {
      acc[codeType] = filteredCodes;
    }
    return acc;
  }, {} as { [CodeType: string]: CodeViewerProps[] });

  return filteredNonEmptyCodes;
};

export default CodeModal;
