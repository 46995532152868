import BranchStatusType from "./branches/BranchStatusType";

const HealthStatusLabel = ({ status }: { status: BranchStatusType }) => {
    const statusLabelMap = {
      active: {
        label: 'Active',
        background: 'bg-green-300',
        textColor: 'text-green-700'
      },
      archived: {
        label: 'Archived',
        background: 'bg-yellow-500',
        textColor: 'text-white'
      }
    };
  
    return (
      <span
        className={`self-center mt-1 rounded-full px-2 text-sm ${statusLabelMap[status].background} ${statusLabelMap[status].textColor}`}>
        {statusLabelMap[status].label}
      </span>
    );
  };

  export default HealthStatusLabel;
