import { useState, useMemo, useEffect } from "react";
import { GithubIcon, BranchIcon } from "../../../assets/images/icons/DelphiIcons";
import { notify } from "../../../components/Toaster";
import { extractErrorMessage } from "../../../services/api";
import IRepository from "../IRepository";
import { FormField } from "../../../components/form/FormField";
import Select, { Option } from "../../../components/form/Select";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../infrastructure/state/slices/activeAccountSlice";
import { useGetGithubBranchesQuery, useGetGithubIntegrationsQuery, useGetGithubRepositoriesQuery } from "../../../services/integrations/integrations";

type GithubProjectConfigurationProps = {
    setRepository: (repository: IRepository | null) => void;
    repository: IRepository | null;
    setBranch: (branch: string) => void;
    branch: string;
};

export const GithubProjectConfiguration = ({ setRepository, repository, setBranch, branch }: GithubProjectConfigurationProps) => {
    const [repositoryId, setRepositoryId] = useState<number | null>(null);
    const accountId = useSelector(selectActiveAccountId);
    const githubIntegrationsQuery = useGetGithubIntegrationsQuery({ accountId });
    const githubAccountId = useMemo(() => githubIntegrationsQuery.data?.[0]?.githubAccountId || 0, [githubIntegrationsQuery.data]);
    const repositoriesQuery = useGetGithubRepositoriesQuery({ accountId, githubAccountId }, { skip: !githubAccountId });
    const repositories = useMemo(() => repositoriesQuery.data || [], [repositoriesQuery.data]);
    const branchesQuery = useGetGithubBranchesQuery({ repo: repository?.name || '', githubAccountId, accountId }, { skip: !repository });
    
    useEffect(() => {
        setRepository(repositories.find((r) => r.id === repositoryId) || null);
    }, [repositoryId, repositories, setRepository]);
    useEffect(() => {
        if (repositoriesQuery.error) {
            console.error(repositoriesQuery.error);
        }
    }, [repositoriesQuery.error]);
    useEffect(() => {
        if (branchesQuery.error) {
            notify(`Error loading branches: ${extractErrorMessage(branchesQuery.error).message}`, 'error');
            console.error(branchesQuery.error);
        }
    }, [branchesQuery.error]);
    return (
        <div className="flex flex-col gap-4">
            <FormField label="Repository">
                <Select
                    options={repositories.map((r) => ({ label: r.name, value: r.id }))}
                    value={repositoryId}
                    onChange={(option) => setRepositoryId(+(option as Option).value)}
                    placeholder="Select a repository"
                    className="border-grey-300 rounded-md border shadow"
                    icon={<GithubIcon width="16" height="16" fill="#94A3B8" />}
                    dataTestId="repository-select"
                />
            </FormField>
            <FormField label="Branch">
                <Select
                    options={branchesQuery.data?.map((b) => ({ label: b.name, value: b.name })) || []}
                    value={branch}
                    onChange={(option) => setBranch((option as Option).value.toString())}
                    className="border-grey-300 rounded-md border shadow"
                    isLoading={branchesQuery.isLoading}
                    isDisabled={!repositoryId}
                    placeholder={repositoryId ? 'Select a branch' : 'Select a repository first'}
                    icon={<BranchIcon fill="#94A3B8" className="ml-2 mr-1" width="16" height="16" />}
                    dataTestId="branch-select"
                />
            </FormField>
        </div>
    );
};