import { useState, useEffect, useMemo } from 'react';
import { NodeType, SubnodeType, subnodeTypePlurals } from '../../INode';
import { NodeIcon } from '../../NodeIcon';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { SubNodesListProps } from './types';

export const SubNodesList = ({ subnodes, onClick, parentNode }: SubNodesListProps) => {
    const [activeType, setActiveType] = useState<string | null>(null);
    const types = useMemo(() => [...new Set(subnodes.map((subnode) => subnode.type))].sort(), [subnodes]);
    useEffect(() => {
        setActiveType(types[0]);
    }, [types, setActiveType]);

    const subnodeTypePluralsWithCustomNames = {
        ...subnodeTypePlurals,
        ...(customSubnodeTypePlurals[parentNode.type] || {})
    };

    if (subnodes.length === 0) {
        return null;
    }
    return (
        <div className="mt-4">
            {types.length === 1 ? (
                <div className="flex items-end gap-1">
                    <div className="text-tertiary">{subnodeTypePluralsWithCustomNames[types[0]]}</div>
                    <div className="text-sm text-slate-400">{subnodes.length}</div>
                </div>
            ) : (
                <div className="flex gap-0.5 overflow-auto rounded bg-slate-200 p-0.5 text-sm font-light text-secondary">
                    {types.map((type) => {
                        const isActive = type === activeType;
                        const background = isActive ? 'bg-white' : 'bg-slate-200';
                        return (
                            <div
                                key={type}
                                className={`w-full cursor-pointer whitespace-nowrap rounded px-1 py-1 text-center ${background} hover:bg-white`}
                                onClick={() => setActiveType(type)}>
                                {subnodeTypePluralsWithCustomNames[type]}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="mt-2">
                {subnodes
                    .filter((subnode) => subnode.type === activeType)
                    .map((subnode, index) => {
                        return (
                            <div
                                className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
                                key={index}
                                onClick={() => onClick(subnode)}>
                                <div className="flex items-center gap-1 overflow-hidden">
                                    <NodeIcon type={parentNode.type} subnodeType={subnode.type} />
                                    <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                                        {subnode.name}
                                    </span>
                                    {subnode.hasProposals && <SparklesIcon width="14" height="14" className="text-surface-primary" />}
                                </div>
                                <span className="text-xs text-tertiary">{subnode.subType}</span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const customSubnodeTypePlurals: { [key: string]: { [key: string]: string } } = {
    [NodeType.LookerLook]: {
        [SubnodeType.Dimension]: 'Custom Dimensions',
        [SubnodeType.Measure]: 'Custom Measures'
    },
    [NodeType.LookerTile]: {
        [SubnodeType.Dimension]: 'Custom Dimensions',
        [SubnodeType.Measure]: 'Custom Measures'
    }
};
