import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { ButtonTypes } from '../../../components/button/types';
import { extractErrorMessage } from '../../../services/api';
import { useRejectChangeMutation } from '../../../services/changes/changes';
import { IChange } from '../IChange';

interface RejectProposalModalProps {
  change: IChange;
  onClose: () => void;
  isOpen: boolean;
}

const RejectProposalModal = ({ change, isOpen, onClose }: RejectProposalModalProps) => {
  const [rejectProposalMutation, { isLoading }] = useRejectChangeMutation();

  const reject = async () => {
    try {
      if (!change.id) throw new Error('Change ID is missing');
      await rejectProposalMutation({ projectId: change.projectId, changeId: change.id }).unwrap();
      onClose();
      notify('Proposal rejected successfully', 'success');
    } catch (e) {
      notify(`Error rejecting proposal: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Reject proposal"
      buttons={[
        {
          type: ButtonTypes.secondary,
          text: 'Cancel',
          onClick: onClose
        },
        {
          type: ButtonTypes.danger,
          text: 'Reject',
          onClick: reject,
          isLoading
        }
      ]}>
        Are you sure you want to reject this proposal?
      </Modal>
  );
};

export default RejectProposalModal;
