import { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { BranchIcon } from "../../../assets/images/icons/DelphiIcons";
import Button from "../../../components/button/Button";
import { ButtonTypes } from "../../../components/button/types";
import { FormField } from "../../../components/form/FormField";
import { selectActiveAccountId } from "../../../infrastructure/state/slices/activeAccountSlice";
import { extractErrorMessage } from "../../../services/api";
import { useCreateGitlabProjectIntegrationMutation, useLazyGetGitlabBranchesQuery, useLazyGetGitlabProjectQuery } from "../../../services/integrations/integrations";
import IRepository from "../IRepository";
import Select, { Option } from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import { notify } from "src/components/Toaster";

type GitlabProjectConfigurationProps = {
    setRepository: (repository: IRepository | null) => void;
    branch: string;
    setBranch: (branch: string) => void;
};

export const GitlabProjectConfiguration = ({ setRepository, branch, setBranch }: GitlabProjectConfigurationProps) => {
    const [projectId, setProjectId] = useState<number | null>(null);
    const [projectToken, setProjectToken] = useState<string>('');
    const [branches, setBranches] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [projectError, setProjectError] = useState<string>('');
    const accountId = useSelector(selectActiveAccountId);
    const [getGitlabProject] = useLazyGetGitlabProjectQuery();
    const [getGitlabBranches] = useLazyGetGitlabBranchesQuery();
    const [createGitlabProjectIntegration] = useCreateGitlabProjectIntegrationMutation();

    const validateProject = async () => {
        setIsLoading(true);
        setProjectError('');
        const { data: repository = null, error: repositoryError } = await getGitlabProject({ accountId, gitlabProjectId: projectId || 0, token: projectToken });
        const { data: branches = [], error: branchesError } = await getGitlabBranches({ accountId, gitlabProjectId: projectId || 0, token: projectToken });
        const error = repositoryError || branchesError;
        if (error) {
            setProjectError(extractErrorMessage(error).message);
        }
        else {
            try {
                await createGitlabProjectIntegration({ accountId, gitlabProjectId: projectId || 0, token: projectToken }).unwrap();
                setRepository(repository);
                setBranches(branches.map((b) => b.name));
                setBranch(branches[0]?.name || '');
                notify('Project verified successfully', 'success');
            } catch (e) {
                setProjectError(extractErrorMessage(e).message);
            }
        }
        setIsLoading(false);
    };
    return (
        <div className="border rounded-md p-4 bg-slate-50 flex-col flex gap-2">
            <FormField label="Project ID">
                <Input
                    placeholder="Enter Gitlab project ID"
                    value={projectId ? projectId.toString() : ''}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) => setProjectId(parseInt(e.target.value))}
                    type="number"
                />
            </FormField>
            <FormField label="Project token" helper="Optional, use this to override your GitLab group permissions for this project.">
                <Input
                    value={projectToken}
                    type="password"
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) => setProjectToken(e.target.value)}
                    placeholder='Enter Gitlab project token'
                />
            </FormField>
            <div className="flex justify-between items-center">
                <div className="text-danger text-sm">{projectError}</div>
                <Button type={ButtonTypes.secondary} text='Verify' onClick={validateProject} isLoading={isLoading} className='w-fit' />
            </div>
            <hr className="bg-slate-200 -mx-4 my-2" />
            <FormField label="Branch">
                <Select
                    options={branches.map((b) => ({ label: b, value: b }))}
                    value={branch}
                    onChange={(option) => setBranch((option as Option).value.toString())}
                    className="border-grey-300 rounded-md border shadow"
                    isDisabled={branches.length === 0}
                    placeholder={'Select a branch'}
                    icon={<BranchIcon fill="#94A3B8" className="ml-2 mr-1" width="16" height="16" />}
                    dataTestId="branch-select"
                />
            </FormField>
        </div>
    );
};