import { ChangeEvent, useEffect, useState } from "react";
import { NodeType, SubnodeType } from "../../../../models/discover/INode";
import Input from "../../../../../components/form/Input";
import { NodeIcon } from "../../../../models/discover/NodeIcon";
import { NameForm } from "../../../formSections/NameForm";
import { DescriptionForm } from "../../../formSections/DescriptionForm";
import { MetaForm } from "../../../formSections/MetaForm";
import { TagsForm } from "../../../formSections/TagsForm";
import { TextWithEllipsisAndTooltip } from "../../../../../components/TextWithEllipsisAndTooltip";
import { ColumnTestsForm } from "../../../formSections/ColumnTestsForm";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { NewAggregateTableChangeColumn, NewAggregateTableChangeData } from "../types";
import Checkbox from "../../../../../components/form/Checkbox";

type NewAggregateTableSubnodeTabProps = {
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
    disabled?: boolean;
}

export const NewAggregateTableSubnodeTab = ({ changeData, setChangeData, disabled = false }: NewAggregateTableSubnodeTabProps) => {
    const [selectedSubnode, setSelectedSubnode] = useState('');

    useEffect(() => {
        if (!selectedSubnode && changeData.columns.length > 0) setSelectedSubnode(changeData.columns[0].name);
    }, [changeData.columns, selectedSubnode, setSelectedSubnode]);

    return (
        <div className="flex border-t border-slate-200 flex-1">
            <SubnodeSearch selectedSubnode={selectedSubnode} subnodes={changeData.columns.map(c => ({ name: c.name, originalName: c.name }))} setSelectedSubnode={setSelectedSubnode} />
            {
                selectedSubnode && (
                    <ColumnForm changeData={changeData} selectedSubnode={selectedSubnode} setChangeData={setChangeData} disabled={disabled} />
                )
            }
        </div>
    );
};

type SubnodeSearchProps = {
    selectedSubnode: string;
    subnodes: { name: string, originalName: string; }[];
    setSelectedSubnode: (subnode: string) => void;
}

const SubnodeSearch = ({ selectedSubnode, subnodes, setSelectedSubnode }: SubnodeSearchProps) => {
    const [search, setSearch] = useState('');
    const subnodesToShow = subnodes.filter((subnode) => subnode.name.toLowerCase().includes(search.toLowerCase()) && subnode.name).slice(0, 10);

    return (
        <div className="shrink-0 w-52 bg-white border-r border-slate-200 pb-4 rounded-bl-lg">
            <div className="p-2 mx-auto">
                <Input
                    value={search}
                    onInputChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                    height="h-8"
                    className="rounded-md"
                    icon={<MagnifyingGlassIcon width="14" height="14" />}
                />
            </div>
            <div className="mt-2">
                {
                    subnodesToShow.map((subnode) => (
                        <div
                            key={subnode.originalName}
                            onClick={() => setSelectedSubnode(subnode.originalName)}
                            className={`py-1 px-2 flex gap-1 items-center border-b border-slate-200 cursor-pointer text-text-primary hover:bg-slate-100 ${selectedSubnode === subnode.name ? 'bg-slate-100' : 'bg-white'}`}
                        >
                            <div><NodeIcon type={NodeType.DataModel} subnodeType={SubnodeType.Column} /></div>
                            <TextWithEllipsisAndTooltip text={subnode.name} maxChars={23} />
                        </div>
                    ))
                }
                {
                    search && subnodesToShow.length === 0 && <div className="text-tertiary text-center">No results</div>
                }
            </div>

        </div>
    );
};

const ColumnForm = ({ changeData, selectedSubnode, setChangeData, disabled }: { disabled: boolean, changeData: NewAggregateTableChangeData, selectedSubnode: string, setChangeData: (changeData: NewAggregateTableChangeData) => void }) => {
    const column = changeData.columns.find(column => column.name === selectedSubnode);
    if (!column) return null;
    const setSubnodeValues = (newValues: Partial<NewAggregateTableChangeColumn>) => {
        setChangeData({
            ...changeData,
            columns: changeData.columns.map(column => column.name === selectedSubnode ? { ...column, ...newValues } : column)
        });
    };
    return (
        <div className="flex flex-col gap-2 p-2 w-full">
            <NameForm withIcon={false} disabled={true} labelWidth="w-44" label="Column" name={column.name} setName={name => setSubnodeValues({ name })} />
            <ColumnAliasField disabled={disabled} labelWidth="w-44" alias={column.alias} setAlias={alias => setSubnodeValues({ alias })} />
            <div className="border-b -mx-2 my-1"></div>
            <DescriptionForm disabled={disabled} labelWidth="w-44" label="Column" description={column.description || ''} setDescription={description => setSubnodeValues({ description })} />
            <TagsForm disabled={disabled} labelWidth="w-44" label="Column" tags={column.tags} setTags={tags => setSubnodeValues({ tags })} />
            <MetaForm disabled={disabled} labelWidth="w-44" label="Column" meta={column.meta} setMeta={meta => setSubnodeValues({ meta })} />
            <ColumnTestsForm disabled={disabled} labelWidth="w-44" tests={column} setTests={(tests) => setSubnodeValues(tests)} />
        </div>
    );
};

const ColumnAliasField = ({ alias, setAlias, disabled, labelWidth }: { alias: string | null, setAlias: (alias: string | null) => void, disabled: boolean, labelWidth: string }) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(!alias);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAlias(e.target.value);
    };
    const toggleAliasCheckbox = (value: boolean) => {
        setIsDisabled(!value);
        if (!value) {
            setAlias(null);
        }
    };
    useEffect(() => {
        setIsDisabled(!alias);
    }, [alias]);
    return (
        <div className="flex items-center flex-1">
            <div>
                <div className={`text-secondary text-sm font-medium flex items-center gap-1 ${labelWidth}`}>
                    Use alias
                    <Checkbox disabled={disabled} value={!isDisabled} setValue={toggleAliasCheckbox} />
                </div>
            </div>
            <div className="flex-1">
                <Input
                    value={alias || ''}
                    onInputChange={onChange}
                    height="h-9"
                    disabled={disabled || isDisabled}
                />
            </div>
        </div>
    );
};