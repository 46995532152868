import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export default createSlice({
  name: 'historyStackSlice',
  initialState: <string[]> [],
  reducers: {
    setHistoryStack: (state, action: PayloadAction<string>) => {
      return [...state, action.payload];
    }
  }
});

export const getHistoryStack = (state: RootState) => state.historyStackSlice;
