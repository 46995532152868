import dateFormat from "dateformat";
export const dateFormats = {
    date: 'mmm dd, yyyy',
    monthsDaysHoursAndMinutes: 'mmm dd, HH:MM TT',
    dateHoursAndMinutes: 'mmm dd yyyy, HH:MM TT',
};

export const utcToLocal = (date: string, format: string): string => {
    try {
        const utcDate = new Date(date);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset()*60*1000);
        return dateFormat(localDate, format);
    } catch (e) {
        console.error(e);
        return date;
    }
};

export const unixToLocal = (unixTimestamp: number, format: string): string => {
    try {
        const localDate = new Date(unixTimestamp);
        return dateFormat(localDate, format);
    } catch (e) {
        console.error(e);
        return Date.toString();
    }
};