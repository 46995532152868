import { ArrowTopRightOnSquareIcon, CodeBracketIcon } from "@heroicons/react/24/solid";
import Button from "../../../../components/button/Button";
import { ButtonTypes } from "../../../../components/button/types";
import CodeModal from "./DiscoverCodeModal";
import { NodeType, SubnodeType, nodeNameMap } from "../INode";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { notify } from "../../../../components/Toaster";
import { NodeButtonProps } from "./types";

export const NodeButtons = ({ parentNode, subnode, showCodeModal, setShowCodeModal, children }: NodeButtonProps) => {
    const node = subnode || parentNode;
    const copyCode = (code: string) => {
        navigator.clipboard.writeText(code);
        notify('Code copied to clipboard', 'success');
    };
    if (!node.url && !node.withCode && !children) {
        return null;
    }
    return <div className="flex gap-1 mt-4">
        {
            node.url && (
                <Button
                    text={`Open ${nodeNameMap.get(node.type)}`}
                    type={ButtonTypes.secondary}
                    icon={<ArrowTopRightOnSquareIcon width="16" height="16" />}
                    onClick={() => window.open(node.url, '_blank')}
                    className="w-full"
                />
            )
        }
        {
            children
        }
        {
            parentNode.type === NodeType.DataModel && subnode?.type !== SubnodeType.Metric && subnode?.compiledCode && (
                <Button
                    text="Copy code"
                    type={ButtonTypes.secondary}
                    icon={<ClipboardDocumentIcon width="16" height="16" />}
                    onClick={() => copyCode(subnode.compiledCode)}
                    className="w-full"
                />
            )
        }
        {
            node.withCode && (
                <>
                    <Button
                        text="View code"
                        type={ButtonTypes.primary}
                        icon={<CodeBracketIcon width="16" height="16" />}
                        onClick={() => setShowCodeModal(true)}
                        className="w-full"
                    />
                    <CodeModal
                        isOpen={showCodeModal}
                        onClose={() => setShowCodeModal(false)}
                        parentNode={parentNode}
                        subnode={subnode}
                    />
                </>
            )
        }
    </div>;
};