import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { SelectFilter } from "src/components/Table/TableFilters/SelectFilter";
import { FilterValue } from "src/components/Table/TableFilters/types";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { CustomProperty, MultiSelectPropertyContent } from "src/services/nodes/types";
const optionIcon = <AdjustmentsHorizontalIcon width="16" height="16" />;

export const getCustomFilters = (customProperties: CustomProperty[]) => {
    const allowedCustomPropertyTypes = ['boolean', 'multi_select'];
    const customFilters: Filter[] = customProperties.filter(cp => allowedCustomPropertyTypes.includes(cp.content.type)).map(prop => {
        const options = prop.content.type === 'boolean' ? booleanOptions : (prop.content.content as MultiSelectPropertyContent[]).map(({ value }) => ({ label: value, value, icon: optionIcon }));
        return {
            name: prop.name,
            menuIcon: <AdjustmentsHorizontalIcon width="18" height="18" className="mr-1.5" />,
            value: null,
            badge: ({ value }: { value: FilterValue }) => `${prop.name}: ${value}`,
            isDisabled: false,
            getEql: (value: FilterValue) => getEql(prop.name, value, prop.content.type),
            component: ({ value, onChange, onSave, onClose }) => (
                <SelectFilter
                    label={prop.name}
                    options={options}
                    isMulti={prop.content.type === 'multi_select'}
                    value={value}
                    onSave={onSave}
                    onClose={onClose}
                    onChange={onChange}
                />
            ),
        };
    });
    return customFilters;
};

const booleanOptions = [{ label: 'Yes', value: 'Yes', icon: optionIcon }, { label: 'No', value: 'No', icon: optionIcon }];
const getEql = (propertyName: string, value: FilterValue, type: 'boolean' | 'multi_select') => {
    if (!value) return '';
    switch (type) {
        case 'boolean':
            return `(${propertyName} IS ${value === "Yes" ? 'TRUE' : 'FALSE'})`;
        case 'multi_select':
            return `(${propertyName} IN ('${value.split(',').join("','")}'))`;
        default:
            console.error(`Invalid filter type: ${type}`);
            return '';
    }
};