import Modal from '../../../components/Modal/Modal';
import { ButtonTypes } from '../../../components/button/types';
import Input from '../../../components/form/Input';
import { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useCreateProjectMutation } from '../../../services/projects/projects';
import { extractErrorMessage } from '../../../services/api';
import { notify } from '../../../components/Toaster';
import FormError from '../../../components/form/FormError';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import IRepository from '../IRepository';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { ProjectRepositoryOption, ProjectRepositoryOptionType, projectRepositoryOptions } from '../repositoryProviders/projectRepositoryOptions';
import { FormField } from '../../../components/form/FormField';
import { GitlabProjectConfiguration } from '../repositoryProviders/GitlabProjectConfiguration';
import { GithubProjectConfiguration } from '../repositoryProviders/GithubProjectConfiguration';

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProjectModal = ({ isOpen, onClose }: CreateProjectModalProps) => {
  const [projectName, setProjectName] = useState('');
  const [dbtProjectPath, setDptProjectPath] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [branch, setBranch] = useState<string>('');
  const [repository, setRepository] = useState<IRepository | null>(null);
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const accountId = useSelector(selectActiveAccountId);
  const [repositoryProvider, setRepositoryProvider] = useState<ProjectRepositoryOption>(projectRepositoryOptions[0]);

  useEffect(() => {
    setErrorMessage('');
  }, [projectName, branch]);

  useEffect(() => {
    if (isOpen) {
      setProjectName('');
      setBranch('');
      setDptProjectPath('');
      setErrorMessage('');
      setRepositoryProvider(projectRepositoryOptions[0]);
    }
  }, [isOpen]);

  const onCreateClick = useCallback(async () => {
    try {
      if (!projectName) {
        setErrorMessage('Project name is required');
        return;
      }
      if (repositoryProvider.name === ProjectRepositoryOptionType.GITLAB && !repository) {
        setErrorMessage('Invalid project ID');
        return;
      }
      if (!repository) {
        setErrorMessage('Repository is required');
        return;
      }
      if (!branch) {
        setErrorMessage('Branch is required');
        return;
      }
      const createProjectParams = {
        folder_id: accountId,
        project: {
          name: projectName,
          repository_provider: repositoryProvider.name,
          dbt_project_subdirectory: dbtProjectPath,
          repository_parameters: {
            id: repository.id,
            name: repository.name,
            owner_id: repository.ownerId,
            owner_login: repository.ownerLogin,
            clone_url: repository.cloneUrl,
            branch
          }
        }
      };
      await createProject(createProjectParams).unwrap();
      onClose();
      setProjectName('');
      setBranch('');
      setDptProjectPath('');
      notify('Project created successfully', 'success');
    } catch (e: unknown) {
      notify(`Error creating project: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  }, [repository, projectName, branch, dbtProjectPath, createProject, onClose, accountId, repositoryProvider]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a new project"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Create project', onClick: onCreateClick, isLoading, dataTestId: 'create-project' }
      ]}>
      <div className="flex flex-col gap-4 mb-4">
        <div className="p-1 bg-slate-200 rounded-md w-fit mx-auto text-secondary text-sm">
          {
            projectRepositoryOptions.map((provider) => (
              <button
                key={provider.name}
                onClick={() => setRepositoryProvider(provider)}
                className={`py-1 px-6 rounded-md cursor-pointer ${repositoryProvider === provider ? 'bg-white' : 'bg-slate-200'}`}
                title={provider.displayName}
              >
                {provider.displayName}
              </button>
            ))
          }
        </div>
        <FormField label="Project name">
          <Input
            placeholder="Enter project name"
            value={projectName}
            onInputChange={(e: ChangeEvent<HTMLInputElement>) => setProjectName(e.target.value)}
            onEnter={onCreateClick}
          />
        </FormField>
      </div>
      {
        repositoryProvider.name === ProjectRepositoryOptionType.GITLAB && (
          <GitlabProjectConfiguration setRepository={setRepository} branch={branch} setBranch={setBranch} />
        )
      }
      {
        repositoryProvider.name === ProjectRepositoryOptionType.GITHUB && (
          <GithubProjectConfiguration setRepository={setRepository} repository={repository} setBranch={setBranch} branch={branch} />
        )
      }
      <div
        className="mb-3 mt-5 flex cursor-pointer items-center gap-2 text-text-primary"
        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
        {showAdvancedOptions ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}
        Advanced
      </div>
      {showAdvancedOptions && (
        <div className="mb-3">
          <FormField label="Directory of dbt project" labelWidth="w-48">
            <Input
              placeholder="Enter folder path"
              value={dbtProjectPath}
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => setDptProjectPath(e.target.value)}
            />
          </FormField>
        </div>
      )}
      {errorMessage && <FormError errorMessage={errorMessage} onClose={() => setErrorMessage('')} />}
    </Modal>
  );
};

export default CreateProjectModal;