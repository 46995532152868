
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import GithubIntegration from './GithubIntegration';
import LookerIntegration from './LookerIntegration';
import MetabaseIntegration from './MetabaseIntegration';
import DbtCloudIntegration from './DbtCloudIntegration';
import TableauIntegration from './TableauIntegration';
import GitlabIntegration from './GitlabIntegration';
import SnowflakeIntegration from './SnowflakeIntegration';

const IntegrationsSection = () => {
  const accountId = useSelector(selectActiveAccountId);

  return (
    <div className="mb-10">
      <div className="mb-2 h-8 text-lg">
        <span className="text-text-primary">Integrations</span>
      </div>
      <div className="flex gap-3">
        <GithubIntegration accountId={accountId} />
        <LookerIntegration accountId={accountId} />
        <MetabaseIntegration accountId={accountId} />
        <DbtCloudIntegration accountId={accountId} />
        <TableauIntegration accountId={accountId} />
        <GitlabIntegration accountId={accountId} />
        <SnowflakeIntegration accountId={accountId} />
      </div>
    </div>
  );
};


export default IntegrationsSection;
