import { BriefcaseIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { FolderIcon } from "@heroicons/react/24/solid";
import { IExpandedNode } from "src/features/models/discover/INode";
import { BranchIcon, GithubIcon, ModelIcon, SourceDirectoryIcon } from "src/assets/images/icons/DelphiIcons";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";

export const NodeSidepaneFooter = ({ node }: { node: IExpandedNode | null }) => {
    if (!node) {
        return null;
    }
    const valuesToRender = [
        node.sourceDirectory && <div className="flex items-center gap-1"><SourceDirectoryIcon width="12" height="12" fill="#94a3b8"/>{node.sourceDirectory}</div>,
        node.repo && node.branch && (
            <div className="flex items-center gap-1">
                <GithubIcon width="10" height="10" fill="#94a3b8"/>
                <TextWithEllipsisAndTooltip maxChars={60} text={node.repo} />
                <BranchIcon width="10" height="10" fill="#94a3b8"/>
                {node.branch}
            </div>
        ),
        node.lastAccessedAt && `Last accessed on ${utcToLocal(`${node.lastAccessedAt}`, dateFormats.date)}`,
        node.createdBy && `Created by ${node.createdBy}`,
        node.createdAt && `Created on ${utcToLocal(`${node.createdAt}`, dateFormats.date)}`,
        node.lastUpdatedBy && `Updated by ${node.lastUpdatedBy}`,
        node.lastUpdatedAt && `Updated on ${utcToLocal(`${node.lastUpdatedAt}`, dateFormats.date)}`,
        (node.database && node.databaseSchema) && <div className="flex items-center gap-1">{node.databaseSchema} schema in <CircleStackIcon width="10" height="10"/> {node.database}</div>,
        node.identifier && `Unique id: ${node.identifier}`,
        node.package && `Package: ${node.package}`,
        node.lookerFolder && <div className="flex items-center gap-1"><FolderIcon width="10" height="10" color="#94a3b8"/>{node.lookerFolder} (Looker folder)</div>,
        node.lookerModel && <div className="flex items-center gap-1"><ModelIcon width="10" height="10" fill="#94a3b8"/>{node.lookerModel} (Looker model)</div>,
        node.lookerProject && <div className="flex items-center gap-1"><BriefcaseIcon width="11" height="11" color="#94a3b8"/>{node.lookerProject} (Looker project)</div>,
    ].filter(Boolean);
    return (
        <div className="mt-auto rounded-md border border-slate-100 bg-slate-50 p-2 mx-2 mb-2 text-xs text-tertiary flex flex-col gap-1">
            {
                valuesToRender.map((value, index) => <div key={index}>{value}</div>)
            }
        </div>
    );
};