import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";
import { useGetLatestEulaQuery } from "../../services/eula";

const EulaLink = ({ className }: {className?: string}) => {
    const accountId = useSelector(selectActiveAccountId);
    const getLatestEulaQuery = useGetLatestEulaQuery({ accountId });
    return <a className={`cursor-pointer ${className}`} target="_blank" href={getLatestEulaQuery.data?.url} rel="noreferrer">License Agreement</a>;
};

export default EulaLink;
