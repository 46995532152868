import { LookerColorlessIcon } from "../../../assets/images/icons/DelphiIcons";
import { IProjectConfiguration } from "../IProject";

export const ProjectSyncConfiguration = ({ projectConfiguration }: { projectConfiguration: IProjectConfiguration }) => {
    const dataModelSync = projectConfiguration.dataModelSync || [];
    if (dataModelSync.length === 0) return null;
    return (
        <div className="text-sm mt-4 text-tertiary flex gap-1 max-w-xs overflow-hidden whitespace-nowrap">
            Synced with
            <LookerColorlessIcon width="14" height="14" fill="#94a3b8" />
            {extractGitRepositoryFromUrl(dataModelSync[0].targetRepository)} /{dataModelSync[0].targetBranch}
        </div>
    );
};

const extractGitRepositoryFromUrl = (url: string) => {
    const urlParts = url.split('/');
    const repository = urlParts[urlParts.length - 1].replace('.git', '');
    return repository;
};