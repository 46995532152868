import { NewMeasureChangeData } from '../../IChange';
import { TagsForm } from '../../formSections/TagsForm';
import { MetaForm } from '../../formSections/MetaForm';
import { DescriptionForm } from '../../formSections/DescriptionForm';
import { NameForm } from '../../formSections/NameForm';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { ModelChangeImplications } from '../../ModelChangeImplications';

type NewMeasureFormProps = {
  sourceName: string,
  sourceUtl: string
  changeData: NewMeasureChangeData;
  setChangeData: (changeData: NewMeasureChangeData) => void;
}

const NewMeasureForm = ({ changeData, setChangeData, sourceName, sourceUtl }: NewMeasureFormProps) => {
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center gap-2 mb-2">
        <div className="text-secondary text-sm font-medium w-24">Model changes</div>
        <ModelChangeImplications
          sourceName={sourceName}
          sourceType={NodeType.LookerLook}
          sourceUtl={sourceUtl}
          sourceSubnodeName={changeData.nameInDataApplication}
          sourceSubnodeType={SubnodeType.Measure}
          targetNodeName={changeData.modelId}
          targetSubnodeName={changeData.newMeasureName}
          targetSubnodeType={SubnodeType.Measure}
        />
      </div>
      <NameForm label="Measure" name={changeData.newMeasureName} setName={newMeasureName => setChangeData({ ...changeData, newMeasureName })} />
      <DescriptionForm label="Measure" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm label="Measure" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm label="Measure" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
    </div>
  );
};

export default NewMeasureForm;
