import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../../localStorage/localStorage';
import localStorageKeys from '../../localStorage/localStorageKeys';
import { RootState } from '../store';

export default createSlice({
  name: 'activeAccount',
  initialState: <number | null> get(localStorageKeys.activeAccountId) || null,
  reducers: {
    setActiveAccount: (_state, action: PayloadAction<number | null>) => {
      return action.payload;
    }
  }
});

export const selectActiveAccountId = (state: RootState) => state.activeAccount;
