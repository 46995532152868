import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { ButtonTypes } from '../../../components/button/types';
import { extractErrorMessage } from '../../../services/api';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { useCrawlIntegrationMutation } from '../../../services/integrations/integrations';
import { GenericIntegration } from '../../../services/integrations/types';
import Select, { Option } from '../../../components/form/Select';

type CrawlIntegrationModalProps = {
    onClose: () => void;
    isOpen: boolean;
    integrations: GenericIntegration[];
}

type CrawlStatus = 'pending' | 'success' | 'failure';

export const CrawlIntegrationModal = ({ onClose, isOpen, integrations }: CrawlIntegrationModalProps) => {
    const [crawlIntegrationMutation, { isLoading }] = useCrawlIntegrationMutation();
    const accountId = useSelector(selectActiveAccountId);
    const [selectedIntegration, setSelectedIntegration] = useState<GenericIntegration | null>(null);
    const [status, setStatus] = useState<CrawlStatus>('pending');

    useEffect(() => {
        if (integrations[0]) {
            setSelectedIntegration(integrations[0]);
        }
    }, [integrations]);

    useEffect(() => {
        if (isOpen) {
            setStatus('pending');
        }
    }, [isOpen]);

    const crawlIntegration = async () => {
        try {
            if (!selectedIntegration) throw new Error('Select integration first');
            await crawlIntegrationMutation({ accountId, integrationId: selectedIntegration.id }).unwrap();
            setStatus('success');
        } catch (e) {
            notify(`Failed to initiate integration polling: ${extractErrorMessage(e).message}`, 'error');
            setStatus('failure');
        }
    };

    const buttons = status !== 'success' ? [
        {
            type: ButtonTypes.secondary,
            text: 'Cancel',
            onClick: onClose
        },
        {
            type: ButtonTypes.primary,
            text: 'Start polling',
            onClick: crawlIntegration,
            isLoading
        }
    ] : [
        {
            type: ButtonTypes.primary,
            text: 'Done',
            onClick: onClose
        }
    ];

    return (
        <Modal buttons={buttons} title="Initiate integration polling" onClose={onClose} isOpen={isOpen}>
            <div className="mt-4 flex flex-col items-center gap-4 rounded-lg border border-slate-200 bg-surface-light p-6 text-text-primary">
                <CrawlState
                    integrations={integrations}
                    selectedIntegration={selectedIntegration}
                    status={status}
                    setSelectedIntegration={setSelectedIntegration}
                />
            </div>
        </Modal>
    );
};

type SelectIntegrationProps = {
    setSelectedIntegration: (integration: GenericIntegration) => void;
    selectedIntegration: GenericIntegration | null;
    integrations: GenericIntegration[];
}

const SelectIntegration = ({ setSelectedIntegration, selectedIntegration, integrations }: SelectIntegrationProps) => {

    const onChange = (option: Option | Option[]) => {
        const integration = integrations.find(i => i.id === (option as Option).value);
        if (integration) {
            setSelectedIntegration(integration);
        }
    };

    return (
        <>
            <div className="font-medium">Select integration</div>
            <Select
                options={integrations?.map(i => ({ label: i.name, value: i.id })) || []}
                value={selectedIntegration?.id || null}
                onChange={onChange}
                className="border shadow-sm w-full"
            />
        </>
    );
};

const FailedOperationBanner = () => {
    return (
        <>
            <ExclamationCircleIcon width="32" height="32" className="ml-auto mr-auto text-secondary" />
            <div className="w-full">
                <div className="text-center font-medium">Something went wrong</div>
            </div>
        </>
    );
};

const CompletedOperationBanner = () => {
    return (
        <>
            <CheckCircleIcon width="32" height="32" className="ml-auto mr-auto text-surface-primary" />
            <div className="font-medium">Polling started successfully</div>
        </>
    );
};

type CrawlStateProps = {
    status: CrawlStatus;
    setSelectedIntegration: (integration: GenericIntegration) => void;
    selectedIntegration: GenericIntegration | null;
    integrations: GenericIntegration[];
}

const CrawlState = ({ status, setSelectedIntegration, selectedIntegration, integrations }: CrawlStateProps) => {
    switch (status) {
        case 'pending':
            return <SelectIntegration integrations={integrations} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />;
        case 'success':
            return <CompletedOperationBanner />;
        case 'failure':
            return <FailedOperationBanner />;
    }
};