export enum Environment {
    Local = 'local',
    Dev = 'dev',
    Stage = 'stage',
    Production = 'production',
    Testing = 'testing'
}

export const getCurrentEnv = (): Environment => {
    const envText = import.meta.env.VITE_ENVIRONMENT;
    switch (envText) {
        case 'local':
            return Environment.Local;
        case 'dev':
            return Environment.Dev;
        case 'stage':
            return Environment.Stage;
        case 'production':
            return Environment.Production;
        case 'testing':
            return Environment.Testing;
        default:
            throw new Error(`Unknown environment: ${envText}`);
    }
};