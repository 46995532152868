import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TopBar from "../../components/layout/TopBar";
import { notify } from "../../components/Toaster";
import { useUser } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";
import { PageTabs } from "src/components/PageTabs";
import { LiquidConsts } from "src/features/internalTools/LiquidConsts";
import { EQLExplorer } from "src/features/internalTools/EQLExplorer";
const allowedEmailSuffix = ['@euno.ai'];


enum Tab {
    LiquidConsts = 'Liquid consts',
    EQLExplorer = 'EQL Explorer',
}

export const InternalTools = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [tab, setTab] = useState<Tab>(Tab.LiquidConsts);

    useEffect(() => {
        if (user && !allowedEmailSuffix.some(suffix => user.email?.endsWith(suffix))) {
            notify('You are not authorized to access this page', 'error');
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <Layout>
            <TopBar>
                <PageTabs tabs={[Tab.LiquidConsts, Tab.EQLExplorer].map(t => ({ name: t.toString(), isActive: t === tab, onClick: () => setTab(t) }))} />
            </TopBar>
            <div className="mx-24 px-16 py-8">
                {
                    tab === Tab.LiquidConsts && <LiquidConsts />
                }
                {
                    tab === Tab.EQLExplorer && <EQLExplorer />
                }
            </div>
        </Layout>
    );
};