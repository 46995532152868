import { useSelector } from "react-redux";
import { FilterValue } from "src/components/Table/TableFilters/types";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetExpandedNodeQuery } from "src/services/nodes/nodes";

export const BadgeUtlToResourceName = ({ value, label, icon }: { value: FilterValue, label: string, icon: JSX.Element }): JSX.Element => {
  const accountId = useSelector(selectActiveAccountId);
  const getNode = useGetExpandedNodeQuery({ accountId, nodeId: value || '' }, { skip: !value });
  const resourceName = getNode.data?.name || value || '';
  const resourceType = getNode.data?.type || null;

  return (
    <>
      {resourceType && <NodeIcon type={resourceType}/>}
      {`${resourceName}: `}
      {icon}
      {label}
    </>
  );
};