import { IRevision } from "../../features/evolution/IRevision";
import { DamaRevisionResponse } from "./types";

export const transformDamaRevisionResponseToLocalRevision = (response: DamaRevisionResponse | null): IRevision | null => {
    if (!response || !response.ready) {
        return null;
    }
    return {
        createdAt: response.ready.processing_started_at,
        finishedAt: response.ready.ready_at,
        typesenseKey: response.ready.typesense_api_key,
        algoliaIndex: response.ready.algolia_index_credentials.index_name,
        algoliaAppId: response.ready.algolia_index_credentials.app_id,
        algoliaKey: response.ready.algolia_index_credentials.key,
        id: response.ready.revision_id
    };
};