import { TagIcon } from "@heroicons/react/24/outline";

type TagsSectionProps = {
    tags: string[];
}

export const TagsSection = ({ tags }: TagsSectionProps) => {
    const withTags = tags.length > 0;
    return (
        <div>
            <div className="flex items-center gap-1">
                <TagIcon width="16" height="16" />
                Tags
            </div>
            <div className="mt-2 text-sm">
                {withTags ? tags.join(', ') : 'No tags'}
            </div>
        </div>
    );
};