import stakeholdersEntityTypes from './stakeholdersEntityTypes';
import { useGetFolderStakeholdersQuery } from '../../services/folders';
import { useGetProjectStakeholdersQuery } from '../../services/projects/projects';
import { useGetBranchStakeholdersQuery } from '../../services/branches';
import IStakeholder from './IStakeholder';
import Avatar from 'react-avatar';
import StakeholdersInviteModal from './InviteStakeholdersModal';
import { useState } from 'react';
import { UserPlusIcon } from '@heroicons/react/24/solid';

const maxStakeholdersInView = 5;
const avatarBackgroundColors = ['#cbeece', '#dcd7f8', '#f9dbb0', '#f6cfd9', '#d2ecfb', '#68a5f8'];

interface StakeholdersQuickViewProps {
  entityType: stakeholdersEntityTypes;
  entityId: number;
  entityName: string;
}

const stakeholderEntityMap = {
  [stakeholdersEntityTypes.folder]: {
    query: useGetFolderStakeholdersQuery
  },
  [stakeholdersEntityTypes.project]: {
    query: useGetProjectStakeholdersQuery
  },
  [stakeholdersEntityTypes.branch]: {
    query: useGetBranchStakeholdersQuery
  }
};

const StakeholdersQuickView = ({ entityType, entityId, entityName }: StakeholdersQuickViewProps) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { data, isFetching } = stakeholderEntityMap[entityType].query({ id: entityId });
  const stakeholders = data || [];
  const preview =
    stakeholders.length > 2 ? (
      <StakeholderAvatars stakeholders={stakeholders} />
    ) : (
      <span className="flex items-center">
        <UserPlusIcon className="mr-2" width="20" height="20" /> Invite people
      </span>
    );

  return (
    <>
      <div onClick={() => setShowInviteModal(true)} className="text-primary hover:text-primary-strong cursor-pointer" data-test-id="stakeholders-quick-view">
        {(isFetching && stakeholders.length === 0) ? null : preview}
      </div>
      <StakeholdersInviteModal
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        entityType={entityType}
        entityId={entityId}
        entityName={entityName}
      />
    </>
  );
};

const StakeholderAvatars = ({ stakeholders }: { stakeholders: IStakeholder[] }) => {
  return (
    <div className="flex cursor-pointer items-center">
      {stakeholders.slice(0, maxStakeholdersInView).map((stakeholder, i) => (
        <Avatar
          color={avatarBackgroundColors[i]}
          textSizeRatio={2}
          key={i}
          value={stakeholder.user.slice(0, 2).toLocaleUpperCase()}
          size="30"
          className="-ml-2 border-2 border-white"
          round={true}
        />
      ))}
      {stakeholders.length > maxStakeholdersInView ? (
        <Avatar
          textSizeRatio={2}
          value={(stakeholders.length - maxStakeholdersInView).toString()}
          size="30"
          className="-ml-2 border-2 border-white"
          round={true}
          color={avatarBackgroundColors[avatarBackgroundColors.length - 1]}
        />
      ) : null}
    </div>
  );
};

export default StakeholdersQuickView;
