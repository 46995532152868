import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import CreateProjectModal from "./create/CreateProjectModal";
import { ProjectIcon } from "../../assets/images/icons/DelphiIcons";
import { useAppDispatch } from "../../infrastructure/state/hooks";
import activeProjectSlice, { selectActiveProject } from "../../infrastructure/state/slices/activeProjectSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAccountProjectsQuery } from "../../services/projects/projects";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";

export const SidebarProjectsList = () => {
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const getAccountProjectsQuery = useGetAccountProjectsQuery({ accountId });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeProjectId = useSelector(selectActiveProject);

    const selectProject = (id: number) => {
        dispatch(activeProjectSlice.actions.setActiveProject(id));
        navigate(`/project/${id}`);
    };

    return (
        <div className="py-4 px-2 mt-4">
            <div className="flex justify-between text-slate-400 mb-2 px-2">
                <div>
                    PROJECTS
                </div>
                <div>
                    <PlusCircleIcon className="cursor-pointer hover:text-slate-500" width="16" height="16" onClick={() => setShowCreateProjectModal(true)} />
                </div>
            </div>
            <div className="flex flex-col gap-2">
                {
                    (getAccountProjectsQuery?.data || []).map(project => (
                        <div className={`px-2 py-1 w-fit flex gap-2 items-center cursor-pointer hover:bg-slate-100 rounded ${project.id === activeProjectId ? 'bg-slate-100' : 'bg-white'}`} onClick={() => selectProject(project.id)} key={project.id}>
                            <div>
                                <ProjectIcon width="12" height="12" fill="#94A3B8" />
                            </div>
                            <div className="text-secondary">
                                {project.name}
                            </div>
                        </div>
                    ))
                }
            </div>
            <CreateProjectModal isOpen={showCreateProjectModal} onClose={() => setShowCreateProjectModal(false)} />
        </div>
    );
};