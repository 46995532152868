import TopBar from "../../components/layout/TopBar";
import stakeholdersEntityTypes from "../stakeholders/stakeholdersEntityTypes";
import { IProject } from "./IProject";
import ProjectMenu from "./ProjectMenu";
import { ProjectIcon } from '../../assets/images/icons/DelphiIcons';
import { useNavigate } from "react-router-dom";
import { PageTabs } from "../../components/PageTabs";


const ProjectHeader = ({ project, page = '', withTabs = false }: { project: IProject, page?: 'overview' | 'configuration' | '', withTabs?: boolean }) => {
  const invite = { type: stakeholdersEntityTypes.project, id: project.id, name: project.name };
  const navigate = useNavigate();
  const goToPage = (p: string) => {
    switch (p) {
      case 'overview':
        navigate(`/project/${project.id}`);
        break;
      case 'configuration':
        navigate(`/project/${project.id}/manage`);
        break;
    }
  };
  return (
    <TopBar invite={invite}>
      <ProjectIcon fill="#94A3B8" className="mr-2" width="16" height="16" />
      <div className="text-md mr-1 text-text-primary font-medium" data-test-id="project-header-name">{project.name}</div>
      <ProjectMenu project={project} />
      {
        withTabs && (
          <PageTabs
            tabs={[
              { name: 'Overview', isActive: page === 'overview', onClick: () => goToPage('overview') },
              { name: 'Configuration', isActive: page === 'configuration', onClick: () => goToPage('configuration') }
            ]}
          />
        )
      }
    </TopBar>
  );
};

export default ProjectHeader;