import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { Extension } from '@codemirror/state';
import { sql } from '@codemirror/lang-sql';
import { python } from "@codemirror/lang-python";
import { yaml } from "@codemirror/lang-yaml";
import { ChevronDownIcon, ChevronUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { notify } from "../Toaster";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { Option } from "../form/Select";
import { CodeViewerLanguages, CodeViewerProps } from "./types";


type LanguageFunction = () => Extension;
type PossibleLanguage = {
    [key: string]: LanguageFunction | null;
};

export const CodeViewer = ({ code, fileName, collapsable = false, className = '', language = CodeViewerLanguages.SQL }: CodeViewerProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [lang, setLang] = useState<string>(language);
    const [extensions, setExtensions] = useState<Extension[]>([]);
    const possibleLanguage: PossibleLanguage = useMemo(() => ({
        [CodeViewerLanguages.SQL]: sql,
        [CodeViewerLanguages.Python]: python,
        [CodeViewerLanguages.YAML]: yaml,
        [CodeViewerLanguages.PlainText]: null
    }), []);

    const selectLanguage = useCallback((language: string) => {
        const cmExtensions: Extension[] = [EditorView.editable.of(false)];
        if (language in possibleLanguage && possibleLanguage[language]) {
            cmExtensions.push(possibleLanguage[language]!());
        }
        setExtensions(cmExtensions);
        setLang(language);
    }, [possibleLanguage]);

    useEffect(() => {
        setIsCollapsed(false);
        selectLanguage(language);
    }, [code, language, selectLanguage, setIsCollapsed]);
    return (
        <div>
            {
                fileName && collapsable && (
                    <div onClick={() => setIsCollapsed(!isCollapsed)} className="items-center cursor-pointer flex gap-2 rounded-t-lg bg-slate-200 p-2 text-sm text-text-primary">
                        <div>
                            {
                                isCollapsed ? <ChevronDownIcon width="14" height="14" /> : <ChevronUpIcon width="14" height="14" />
                            }
                        </div>
                        {
                            fileName
                        }
                    </div>
                )
            }
            {
                !isCollapsed && (
                    <div className={`${collapsable ? 'rounded-b-lg' : 'rounded-lg'} flex flex-col h-auto border border-slate-200 bg-slate-50 gap-1 p-2 ${className}`}>
                        <Select
                            options={Object.keys(possibleLanguage).map(i => ({ label: i, value: i }))}
                            value={lang}
                            onChange={(lang) => selectLanguage((lang as Option).value.toString())}
                            className="shadow-sm w-32 h-3"
                        />
                        <div className="ml-auto p-1 -mr-1 -mt-1 rounded border border-slate-200 bg-white hover:bg-slate-50 cursor-pointer text-slate-400 w-fit h-fit" title="copy" onClick={() => copyCode(code)}>
                            <DocumentDuplicateIcon width="14" height="14" />
                        </div>
                        <div className="flex-1 whitespace-pre text-sm text-text-primary">
                            <CodeMirror value={code} height="30vh" extensions={extensions} />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const copyCode = (code: string) => {
    code && navigator.clipboard.writeText(code);
    notify('Copied to clipboard', 'success');
};
