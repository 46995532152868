import { TableStyles } from "react-data-table-component";

export const getTableStyles = (onRowClicked: boolean, maxBodyHeight: string | null): TableStyles => (
    {
        rows: {
            style: {
                padding: '0',
                border: 'none!important',
                backgroundColor: '#F9FAFB',
                color: '#64748B',
                cursor: onRowClicked ? 'pointer' : 'default',
                '&:hover': {
                    backgroundColor: '#f2f4f6'
                }
            },
            stripedStyle: {
                background: '#FFFFFF',
                color: '#64748B',
                '&:hover': {
                    background: '#F9FAFB'
                }
            }
        },
        headCells: {
            style: {
                paddingLeft: '24px',
                color: '#94A3B8',
                backgroundColor: '#F9FAFB'
            }
        },
        cells: {
            style: {
                paddingLeft: '24px'
            }
        },
        headRow: {
            style: {
                minHeight: '40px'
            }
        },
        tableWrapper: {
            style: {
                height: maxBodyHeight || 'auto',
            }
        }
    }
);