import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export default createSlice({
  name: 'activeProject',
  initialState: <number | null> null,
  reducers: {
    setActiveProject: (_state, action: PayloadAction<number | null>) => {
      return action.payload;
    }
  }
});

export const selectActiveProject = (state: RootState) => state.activeProject;