import { DbtCloudIcon } from '../../../assets/images/icons/DelphiIcons';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  useCreateDbtCloudIntegrationMutation,
  useGetDbtCloudIntegrationsQuery,
  useUnlinkDbtCloudIntegrationMutation
} from '../../../services/integrations/integrations';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu';
import { EllipsisVerticalIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { IDbtCloudIntegration } from './types';
import { Alert } from '../../../components/Alert';
import Input from '../../../components/form/Input';

const DbtCloudIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const { data: integrations = [], error } = useGetDbtCloudIntegrationsQuery({ accountId }, { skip: !accountId });

  useEffect(() => {
    if (error) {
      notify(extractErrorMessage(error).message, 'error');
    }
  }, [error]);

  const menuOptions = [
    {
      name: 'Edit configuration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    }
  ];

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4" dataTestId="dbt-cloud-integration">
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <DbtCloudIcon width="48" height="48" className="mb-2 text-black" fill="#FF694A" />
      <span className="mt-2 font-medium">DBT Cloud</span>
      {integrations.length === 0 ? (
        <Button
          type={ButtonTypes.secondary}
          text="Configure"
          onClick={() => setShowConfigurationModal(true)}
          className="-mb-2 mt-1 h-6"
          dataTestId='configure-dbt-cloud-integration-button'
        />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {integrations.length} active integration{integrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={integrations}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: IDbtCloudIntegration[];
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations
}: IntegrationConfigurationModalProps) => {
  const [unlinkIntegration, { originalArgs }] = useUnlinkDbtCloudIntegrationMutation();
  const [addingInstance, setAddingInstance] = useState(false);
  const [createIntegration, { isLoading }] = useCreateDbtCloudIntegrationMutation();
  const [dbtAccountId, setDbtAccountId] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState<string>('');

  const onCreateClick = async () => {
    if (!dbtAccountId) {
      setError('Account ID is required');
      return;
    }
    if (!/^\d+$/.test(dbtAccountId)) {
      setError('Account ID must be a number');
      return;
    }
    if (!token) {
      setError('Token is required');
      return;
    }
    try {
      await createIntegration({ accountId, dbtAccountId: parseInt(dbtAccountId), token }).unwrap();
      setAddingInstance(false);
      notify('DbtCloud integration created successfully', 'success');
    } catch (e) {
      setError(`Error creating integration: ${extractErrorMessage(e).message}`);
    }
  };

  useEffect(() => {
    setError('');
  }, [isOpen, token, dbtAccountId]);

  useEffect(() => {
    if (isOpen) {
      setToken('');
      setDbtAccountId('');
      if (integrations.length === 0) {
        setAddingInstance(true);
      }
    }
  }, [isOpen, integrations]);

  const onUnlinkClick = async (integration: IDbtCloudIntegration) => {
    try {
      await unlinkIntegration({ accountId, integrationId: integration.id }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      notify(`Error unlinking integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configure DbtCloud Integration"
      maxWidth="max-w-lg"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-32', dataTestId: 'dbt-cloud-integration-done-button' }]}>
      <div className="rounded border border-slate-200 bg-slate-50">
        {integrations.map((integration) => (
          <Integration
            isLoading={originalArgs?.integrationId === integration.id}
            key={integration.id}
            integration={integration}
            onUnlinkClick={onUnlinkClick}
          />
        ))}
        <div className="px-4 py-3">
          {!addingInstance && (
            <div
              className="flex items-center text-slate-600 hover:text-slate-800"
              onClick={() => setAddingInstance(!addingInstance)}>
              <PlusCircleIcon width="16" height="16" className="mr-1 cursor-pointer" />
              <span className="cursor-pointer text-sm">{integrations.length === 0 ? 'Add account' : 'Add another account'}</span>
            </div>
          )}
          <div
            className={`transition-max-height overflow-auto duration-200 ${addingInstance ? 'max-h-72' : 'max-h-0'}`}>
            <InputField label="Account ID">
              <Input
                placeholder="Enter account ID"
                value={dbtAccountId}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setDbtAccountId(e.target.value)}
              />
            </InputField>
            <InputField label="Token">
              <Input
                placeholder="Enter token"
                value={token}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setToken(e.target.value)}
              />
            </InputField>
            <div>{error && <Alert title="Error:" text={error} type="error" />}</div>
            <div className="mt-4 flex h-8 flex-row flex-row-reverse">
              <Button
                type={ButtonTypes.secondary}
                text="Save"
                onClick={onCreateClick}
                isLoading={isLoading}
                className="w-24"
                dataTestId='dbt-cloud-integration-save-button'
              />
              {integrations.length > 0 && (
                <Button
                  type={ButtonTypes.secondary}
                  text="Cancel"
                  onClick={() => setAddingInstance(false)}
                  className="mr-2 w-24"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const InputField = ({ children, label }: { children: React.ReactNode; label: string }) => {
  return (
    <div className="mb-4 flex items-center justify-between text-text-primary">
      <div className="w-20">{label}</div>
      <div className="w-80">{children}</div>
    </div>
  );
};

const Integration = ({
  integration,
  onUnlinkClick,
  isLoading,
}: {
  integration: IDbtCloudIntegration;
  onUnlinkClick: (integration: IDbtCloudIntegration) => void;
  isLoading: boolean;
}) => {
  return (
    <div key={integration.id} className="border-b border-slate-200 px-4 py-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <DbtCloudIcon width="16" height="16" className="mr-2" fill="#FF694A" />
          <span className="text-sm" data-test-id="dbt-integration-name">{integration.accountName}</span>
        </div>
        {isLoading ? (
          <span className="flex items-center text-sm text-danger hover:text-danger-strong">
            <MinusCircleIcon width="16" height="16" className="mr-1" /> Loading...
          </span>
        ) : (
          <span
            onClick={() => onUnlinkClick(integration)}
            className="flex cursor-pointer items-center text-sm text-danger hover:text-danger-strong">
            <MinusCircleIcon width="16" height="16" className="mr-1" /> Remove
          </span>
        )}
      </div>
    </div>
  );
};

export default DbtCloudIntegration;
