import { memo, useMemo, useState } from 'react';
import ReactSelect, { ControlProps, components } from 'react-select';
const MemoizedReactSelect = memo(ReactSelect);

export type Option = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

interface SelectProps {
  options: Option[];
  value: string | number | null | string[];
  onChange: (selectedOption: Option | Option[]) => void;
  isLoading?: boolean;
  className?: string;
  placeholder?: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
  height?: string;
  background?: string;
  isSearchable?: boolean;
  menuPortalDistance?: string;
  isMulti?: boolean;
  isCreatable?: boolean;
  isClearable?: boolean;
  dataTestId?: string;
  maxOptions?: number;
}

const Select = ({
  isDisabled,
  options,
  value,
  onChange,
  isLoading,
  className,
  placeholder,
  icon,
  height = '36px',
  background = 'white',
  isSearchable = true,
  menuPortalDistance = '0px',
  isMulti = false,
  isCreatable = false,
  isClearable = false,
  dataTestId,
  maxOptions = 1000
}: SelectProps) => {
  const [inputText, setInputText] = useState('');
  const ControlComponent = useMemo(() => getControlComponent(icon), [icon]);

  const optionsMirror = useMemo(() => {
    const distinctOptions = options.filter((option, index, self) => self.findIndex((o) => o.value === option.value && o.label === option.label) === index);
    const newOptions = [...distinctOptions];
    if (isCreatable && inputText && !newOptions.find((option) => option.value === value)) {
      newOptions.push({ value: inputText, label: `Create "${inputText}"` });
    }
    if (newOptions.length > maxOptions) {
      return newOptions.filter(o => o.label.toLowerCase().includes(inputText.toLocaleLowerCase())).slice(0, maxOptions);
    }
    return newOptions;
  }, [inputText, options, value, isCreatable, maxOptions]);
  
  return (
    <MemoizedReactSelect
      onInputChange={(text) => setInputText(text)}
      isMulti={isMulti}
      className={`${className || ''}`}
      options={optionsMirror}
      value={
        isMulti
          ? options.filter((o) => (value as string[]).includes((o as Option).value as string))
          : options.find((option) => option.value === value) || null
      }
      onChange={(option) => onChange(option as Option | Option[])}
      isLoading={isLoading}
      placeholder={placeholder}
      isDisabled={isLoading || isDisabled}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      isOptionDisabled={(option) => (option as Option).disabled || false}
      menuPlacement="auto"
      id={dataTestId}
      isSearchable={isSearchable}
      components={{
        IndicatorSeparator: () => null,
        Control: ControlComponent
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#f8fafc'
          },
          width: 'auto',
          textAlign: 'right',
          paddingRight: '0px',
          minHeight: height,
          height,
          background: isDisabled || isLoading ? '#F3F4F6' : background,
          cursor: isDisabled || isLoading ? 'not-allowed' : 'pointer',
          opacity: isDisabled ? 0.5 : 1
        }),
        option: (provided) => ({
          ...provided,
          cursor: 'pointer',
          fontSize: '12px'
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 100,
          minWidth: '110px',
          marginTop: menuPortalDistance
        }),
        singleValue: (base) => ({
          ...base,
          display: 'flex',
          alignItems: 'center'
        }),
        placeholder: (base) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
          color: '#94A3B8'
        }),
        valueContainer: (base) => ({
          ...base,
          height,
          padding: icon ? '0px 8px 0px 0px' : '0px 8px',
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }),
        input: (base) => ({
          ...base,
          padding: 0,
          margin: 0,
          overflow: 'hidden',
        }),
        indicatorsContainer: (base) => ({
          ...base,
          padding: 0,
          margin: 0,
          height
        })
      }}
    />
  );
};

const getControlComponent = (icon?: JSX.Element) => {
  const ControlComponent = ({ children, ...props }: ControlProps) => (
    <components.Control {...props}>
      {icon && <div className="ml-3 mr-2">{icon}</div>}
      {children}
    </components.Control>
  );
  return memo(ControlComponent);
};

export default Select;

export const MemoizedSelect = memo(Select);
