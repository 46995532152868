import { IChange } from '../../IChange';
import { MetaSection } from '../MetaSection';
import { TagsSection } from '../TagsSection';
import { NodeIcon } from '../../../models/discover/NodeIcon';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { NewAggregateTableSubnodeTab } from '../../changeForms/newAggregateTable/components/NewAggregateTableSubnodeTab';
import { NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from '../../changeForms/newAggregateTable/types';
import { ModelChangeSection } from '../ModelChangeSection';
import { ModelChangeImplications } from '../../ModelChangeImplications';


export const NewAggregateTableChangeSection = ({ change }: { change: IChange }) => {
  const newAggregateTableChange = change.changeData as NewAggregateTableChangeData;
  const sourceMetricName = newAggregateTableChange.columns.filter(c => c.type === NewAggregateTableChangeColumnType.metric)[0]?.name || '';
  return (
    <div>
      <div className="flex flex-col gap-8">
        <div>
          <div className="flex gap-2">
            <div className="bg-white p-2 rounded-lg border border-slate-200 h-fit w-fit mt-0.5">
              <NodeIcon type={NodeType.DataModel} iconSize={20} />
            </div>
            <div className="flex flex-col">
              <div className="text-lg text-text-primary">{newAggregateTableChange.newTableName}</div>
              <div className="flex items-center text-sm text-tertiary gap-1">
                model in <BriefcaseIcon width="14" height="14" />
                <span className="font-medium">{newAggregateTableChange.dbtProjectName}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 text-text-primary">{newAggregateTableChange.description}</div>
        </div>
        <div className="flex gap-4">
          <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary w-[30%]">
            <TagsSection tags={newAggregateTableChange.tags} />
            <MetaSection meta={newAggregateTableChange.meta} />
          </div>
          <ModelChangeSection change={change}>
            <ModelChangeImplications targetNodeName={newAggregateTableChange.newTableName} sourceName={change.sourceName} sourceType={NodeType.DataModel} sourceSubnodeType={SubnodeType.Metric} sourceSubnodeName={sourceMetricName} />
          </ModelChangeSection>
        </div>
      </div>
      <div className="mt-8 rounded-lg bg-slate-100 border border-slate-200 flex flex-col flex-1">
        <NewAggregateTableSubnodeTab changeData={newAggregateTableChange} setChangeData={() => { }} disabled />
      </div>
    </div>
  );
};