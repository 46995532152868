import { NewAggregateTableChangeData } from "../types";
import "react-datepicker/dist/react-datepicker.css";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import Input from "../../../../../components/form/Input";

type AggregateTableTimeframeFormProps = {
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
};

export const AggregateTableTimeframeForm = ({ changeData, setChangeData }: AggregateTableTimeframeFormProps) => {
    const [show, setShow] = useState<boolean>(!!(changeData.date.start && changeData.date.end));

    const onHide = () => {
        setChangeData({ ...changeData, date: { start: null, end: null } });
        setShow(false);
    };

    return (
        <div className="flex gap-4 w-full items-center">
            {
                !show && (
                    <div className="flex items-center mt-2 flex gap-2 cursor-pointer text-slate-400 hover:text-slate-500" onClick={() => setShow(true)}>
                        <PlusCircleIcon width={16} height={16} />
                        Add timeframe (optional)
                    </div>
                )
            }
            {
                show && (
                    <>
                        <div className="flex-1">
                            <Input
                                value={changeData.date.start || ''}
                                placeholder="Start date"
                                className="rounded border shadow"
                                onInputChange={e => setChangeData({ ...changeData, date: { ...changeData.date, start: e.target.value } })}
                            />
                        </div>
                        <div className="flex-1">
                            <Input
                                value={changeData.date.end || ''}
                                placeholder="End date"
                                className="rounded border shadow"
                                onInputChange={e => setChangeData({ ...changeData, date: { ...changeData.date, end: e.target.value } })}
                            />
                        </div>
                        <MinusCircleIcon className="text-slate-400 hover:text-slate-500 cursor-pointer" width={16} height={16} onClick={onHide} />
                    </>
                )
            }
        </div>
    );
};