import { ChangeStatus } from '../IChange';

const map = {
  [ChangeStatus.Proposal]: 'bg-muse-100 text-muse-800',
  [ChangeStatus.Open]: 'bg-muse-100 text-muse-800',
  [ChangeStatus['Pending Merge']]: 'bg-yellow-100 text-yellow-800',
  [ChangeStatus.Merged]: 'bg-green-200 text-green-900',
  [ChangeStatus.Deployed]: 'bg-green-200 text-green-900',
  [ChangeStatus.Published]: 'bg-green-200 text-green-900',
  [ChangeStatus['PR Rejected']]: 'bg-red-100 text-red-800',
  [ChangeStatus['Draft Rejected']]: 'bg-red-100 text-red-800',
};

const statusToColorScheme = (status: ChangeStatus): string => map[status];

export default statusToColorScheme;
