import { SnowflakeIcon } from '../../../assets/images/icons/DelphiIcons';
import { ChangeEvent, useEffect, useState } from 'react';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu';
import { EllipsisVerticalIcon, ExclamationCircleIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { Alert } from '../../../components/Alert';
import Input from '../../../components/form/Input';
import { useCreateGenericIntegrationMutation, useDeleteGenericIntegrationMutation, useGetGenericIntegrationsQuery } from '../../../services/integrations/integrations';
import { GenericIntegration, SnowflakeIntegrationConfiguration } from '../../../services/integrations/types';
import { CrawlIntegrationModal } from './CrawlIntegrationModal';
import Checkbox from '../../../components/form/Checkbox';
import { Tooltip } from 'react-tooltip';

const SnowflakeIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [showCrawlModal, setShowCrawlModal] = useState(false);
  const { data: integrations = [] } = useGetGenericIntegrationsQuery({ accountId }, { skip: !accountId });
  const snowflakeIntegrations = integrations.filter((i) => i.integration_type === 'snowflake').map((i) => ({ ...i, configuration: i.configuration as SnowflakeIntegrationConfiguration }));

  const menuOptions = [
    {
      name: 'Edit configuration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    },
    {
      name: 'Start polling',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowCrawlModal(true);
      }
    }
  ];

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4" dataTestId='snowflake-integration'>
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <SnowflakeIcon width="56" height="56" className="mb-2 text-black" color="black" />
      <span className="mt-2 font-medium">Snowflake</span>
      {snowflakeIntegrations.length === 0 ? (
        <Button
          type={ButtonTypes.secondary}
          text="Configure"
          onClick={() => setShowConfigurationModal(true)}
          className="-mb-2 mt-1 h-6"
          dataTestId='configure-snowflake-integration-button'
        />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {snowflakeIntegrations.length} active integration{snowflakeIntegrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={snowflakeIntegrations}
      />
      <CrawlIntegrationModal
        isOpen={showCrawlModal}
        onClose={() => setShowCrawlModal(false)}
        integrations={snowflakeIntegrations}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: (GenericIntegration & { configuration: SnowflakeIntegrationConfiguration })[];
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations
}: IntegrationConfigurationModalProps) => {
  const [unlinkIntegration, { originalArgs }] = useDeleteGenericIntegrationMutation();
  const [addingInstance, setAddingInstance] = useState(false);
  const [createIntegration, { isLoading }] = useCreateGenericIntegrationMutation();
  const [configuration, setConfiguration] = useState<SnowflakeIntegrationConfiguration>({ ...emptyConfiguration });
  const [integrationName, setIntegrationName] = useState('');
  const [error, setError] = useState<string>('');

  const onCreateClick = async () => {
    if (!configuration.host) {
      setError('Host is required');
    }
    else if (!configuration.user) {
      setError('Username is required');
    }
    else if (!configuration.password) {
      setError('Password is required');
    }
    else if (configuration.table_to_use_for_query_history && !/^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+){2}$/.test(configuration.table_to_use_for_query_history)) {
      setError('Table to use for query history must be in the format "database.schema.table"');
    }
    else {
      if (!configuration.table_to_use_for_query_history) {
        delete configuration.table_to_use_for_query_history;
      }
      try {
        await createIntegration({ accountId, integrationName, configuration, integrationType: 'snowflake' }).unwrap();
        notify('Snowflake integration created successfully', 'success');
        setAddingInstance(false);
      } catch (e) {
        setError(`Error creating integration: ${extractErrorMessage(e).message}`);
      }
    }
  };

  useEffect(() => {
    setError('');
  }, [isOpen, configuration]);

  useEffect(() => {
    if (isOpen) {
      setConfiguration({ ...emptyConfiguration });
      setIntegrationName('');
      setError('');
      if (integrations.length === 0) {
        setAddingInstance(true);
      }
    }
  }, [isOpen, integrations.length]);

  const onUnlinkClick = async (integrationId: number) => {
    try {
      await unlinkIntegration({ accountId, integrationId }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      notify(`Error unlinking integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configure Snowflake Integration"
      maxWidth="max-w-2xl"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-32' }]}
    >
      <div className="rounded border border-slate-200 bg-slate-50">
        {integrations.map((integration) => (
          <div key={integration.name} className="flex items-center justify-between border-b border-slate-200 px-4 py-3">
            <div className="flex items-center">
              <SnowflakeIcon width="20" height="20" className="mr-2" />
              <span className="text-sm" data-test-id="Snowflake-integration-name">{integration.name}</span>
            </div>
            {originalArgs?.integrationId === integration.id ? (
              <span className="flex items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Loading...
              </span>
            ) : (
              <span
                onClick={() => onUnlinkClick(integration.id)}
                className="flex cursor-pointer items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Remove
              </span>
            )}
          </div>
        ))}
        <div className="px-4 py-3">
          {!addingInstance && (
            <div
              className="flex items-center text-slate-600 hover:text-slate-800"
              onClick={() => setAddingInstance(!addingInstance)}>
              <PlusCircleIcon width="16" height="16" className="mr-1 cursor-pointer" />
              <span className="cursor-pointer text-sm">Add instance</span>
            </div>
          )}
          <div
            className={`transition-max-height overflow-auto duration-200 ${addingInstance ? 'max-h-76' : 'max-h-0'}`}>
            <InputField label="Integration name">
              <Input
                placeholder="Enter integration name"
                value={integrationName}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setIntegrationName(e.target.value)}
              />
            </InputField>
            <InputField label="Host">
              <Input
                placeholder="Enter host"
                value={configuration.host}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, host: e.target.value })}
              />
            </InputField>
            <InputField label="Username">
              <Input
                placeholder="Enter username"
                value={configuration.user}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, user: e.target.value })}
              />
            </InputField>
            <InputField label="Password">
              <Input
                placeholder="Enter password"
                value={configuration.password}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, password: e.target.value })}
                type='password'
              />
            </InputField>
            <InputField label="Role">
              <Input
                placeholder="Enter role"
                value={configuration.role}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, role: e.target.value })}
              />
            </InputField>
            <InputField label="Warehouse">
              <Input
                placeholder="Enter warehouse"
                value={configuration.warehouse}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, warehouse: e.target.value })}
              />
            </InputField>
            <InputField label="Table to use for query history" hint="Euno will use this table to query for the query history. Leave blank to use the native query history view snowflake.account_usage.query_history">
              <Input
                placeholder="snowflake.account_usage.query_history"
                value={configuration.table_to_use_for_query_history || ''}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, table_to_use_for_query_history: e.target.value })}
              />
            </InputField>
            <div className="flex gap-2">
              <Checkbox
                value={!configuration.ssl_verify}
                setValue={(value) => setConfiguration({ ...configuration, ssl_verify: !value })}
              />
              Skip SSL certificate verification
            </div>
            <div className="flex gap-2 mt-2">
              <Checkbox
                value={configuration.use_snowflake_database}
                setValue={(value) => setConfiguration({ ...configuration, use_snowflake_database: value })}
              />
              <div className="flex items-center gap-1">
                <Tooltip anchorSelect="#use-system-database-hint" className='max-w-md'>
                  Uncheck this to have Euno iterate over all available databases and query INFORMATION_SCHEMA.VIEWS. Leave this checked to have Euno issue a single query on the system view SNOWFLAKE.ACCOUNT_USAGE.VIEWS
                </Tooltip>
                <div id="use-system-database-hint">
                  <ExclamationCircleIcon width="16" height="16" className='text-slate-400' />
                </div>
                Use Snowflake system database to query for Snowflake views
              </div>
            </div>
            <div>{error && <Alert title="Error:" text={error} type="error" className="mt-2" />}</div>
            <div className="mt-4 flex h-8 flex-row-reverse">
              <Button
                type={ButtonTypes.secondary}
                text="Save"
                onClick={onCreateClick}
                isLoading={isLoading}
                className="w-24"
                dataTestId='snowflake-integration-save-button'
                enterToSubmit={true}
              />
              {integrations.length > 0 && (
                <Button
                  type={ButtonTypes.secondary}
                  text="Cancel"
                  onClick={() => setAddingInstance(false)}
                  className="w-24 mr-2"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
};

const InputField = ({ children, label, hint }: { children: React.ReactNode; label: string; hint?: string }) => {
  return (
    <div className="mb-4">
      <div className="flex items-center justify-between gap-2 text-text-primary">
        <div className="w-52 flex gap-1 items-center">
          {
            hint && (
              <>
                <Tooltip anchorSelect={`#${label.replace(/ /g, '-')}-hint`} className='max-w-md'>
                  {hint}
                </Tooltip>
                <div id={`${label.replace(/ /g, '-')}-hint`}>
                  <ExclamationCircleIcon width="16" height="16" className='text-slate-400' />
                </div>
              </>
            )
          }
          {label}
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

const emptyConfiguration: SnowflakeIntegrationConfiguration = {
  host: '',
  user: '',
  password: '',
  role: '',
  warehouse: '',
  ssl_verify: true,
  table_to_use_for_query_history: '',
  use_snowflake_database: true
};

export default SnowflakeIntegration;
