import { CodeBracketIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useCallback, useEffect } from "react";
import Input from "../../../components/form/Input";

type Meta = {
    key: string;
    value: string;
};

type MetaFormProps = {
    meta: Meta[];
    setMeta: (meta: Meta[]) => void;
    label: string;
    labelWidth?: string;
    disabled?: boolean;
}

export const MetaForm = ({ meta, setMeta, label, labelWidth = 'w-36', disabled = false }: MetaFormProps) => {
    const metaWithoutEuno = meta.filter(m => !m.key.includes('euno.ai/'));
    const addEmptyMeta = useCallback(() => setMeta([...meta, { key: '', value: '' }]), [meta, setMeta]);
    const removeMeta = useCallback((index: number) => {
        if (meta.length === 1) {
            setMeta([{ key: '', value: '' }]);
        }
        else {
            const newMeta = [...meta];
            newMeta.splice(index, 1);
            setMeta(newMeta);
        }
    }, [meta, setMeta]);

    useEffect(() => {
        const keysToRemove = ['euno.ai/saved_query'];
        for (const i in meta) {
            if (keysToRemove.includes(meta[i].key)) {
                removeMeta(Number(i));
            }
        }
    }, [meta, removeMeta]);

    useEffect(() => {
        if (metaWithoutEuno.length === 0) {
            addEmptyMeta();
        }
    }, [metaWithoutEuno, addEmptyMeta]);

    const onChange = (index: number, key: 'key' | 'value', value: string) => {
        const newMeta = [...meta].map((metaItem, i) => {
            if (i === index) {
                return { ...metaItem, [key]: value };
            }
            return metaItem;
        });
        setMeta(newMeta);
    };

    return (
        <div className="flex" data-test-id="meta-form">
            <div className={`text-secondary self-baseline mt-2 text-sm font-medium flex items-center gap-1 ${labelWidth}`}>
                <CodeBracketIcon width="12" height="12" />
                {label} Meta
            </div>
            <div className="flex gap-1 flex-col flex-1">
                {meta.map((metaItem, index) => {
                    if (metaItem.key.includes('euno.ai/')) {
                        return null;
                    }
                    return (
                        <div key={index} className="flex items-center gap-2 w-full">
                            <Input
                                value={metaItem.key}
                                onInputChange={(e: ChangeEvent<HTMLInputElement>) => onChange(index, 'key', e.target.value)}
                                placeholder="Key"
                                height="h-9"
                                disabled={disabled}
                            />
                            <Input
                                value={metaItem.value}
                                onInputChange={(e: ChangeEvent<HTMLInputElement>) => onChange(index, 'value', e.target.value)}
                                placeholder="Value"
                                height="h-9"
                                disabled={disabled}
                            />
                            {
                                !disabled && (
                                    <div className="flex w-24 gap-1">
                                        <MinusCircleIcon
                                            height="18"
                                            width="18"
                                            className="cursor-pointer text-slate-300 hover:text-slate-400"
                                            onClick={() => removeMeta(index)}
                                        />
                                        <PlusCircleIcon
                                            height="18"
                                            width="18"
                                            className="cursor-pointer text-slate-400 hover:text-slate-500"
                                            onClick={addEmptyMeta}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
};