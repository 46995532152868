import api from './api';

export interface User {
  email: string;
  user_id: number;
  github_login: string;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => 'user',
      providesTags: ['Users']
    })
  })
});

export const { useGetUserQuery } = usersApi;