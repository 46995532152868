import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useKeyPress } from "react-use";
import { isTypingInInput } from "../../../infrastructure/domUtilies";
import { isSidepaneOpen } from "../../Sidepane/isSidepaneOpen";
import { TextWithEllipsisAndTooltip } from "../../TextWithEllipsisAndTooltip";
import { useTableView } from "../useTableView";
import { FilterPanel } from "./FilterPanel";
import { FiltersMenu } from "./FiltersMenu";
import { Filter, FilterValues } from "./types";

type FiltersBarProps = {
  onFilterValuesChange: (filterValues: FilterValues) => void;
  tableName: string;
  filters: Filter[];
};

export const FiltersBar = ({ onFilterValuesChange, tableName, filters }: FiltersBarProps) => {
  const [filterToShow, setFilterToShow] = useState<Filter | null>(null);
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const fPressed = useKeyPress('f');
  const [searchParams, setSearchParams] = useSearchParams();
  const tableView = useTableView({ tableName });
  const savedTableLoaded = useRef(false);
  const searchParamsHash = useRef('');

  const filtersWithValues = useMemo(() => {
    const filtersWithValues = filters.map((f) => {
      const value = searchParams.get(f.name) || null;
      return { ...f, value };
    });
    const searchString = searchParams.toString().replace(/\+/g, ' ');
    const filtersSortedByPosition = filtersWithValues.sort((a, b) => {
      const aIndex = searchString.indexOf(a.name);
      const bIndex = searchString.indexOf(b.name);
      return aIndex - bIndex;
    });
    return filtersSortedByPosition;
  }, [filters, searchParams]);

  useEffect(() => {
    savedTableLoaded.current = false;
  }, []);

  //on "f" pressed
  useEffect(() => {
    if (fPressed[0] && !filterToShow && !isTypingInInput() && !isSidepaneOpen()) {
      setShowFiltersMenu(true);
    }
  }, [fPressed, filterToShow]);

  const removeFilter = (name: string) => {
    const newFilters = filtersWithValues.map((f) => {
      if (f.name === name) {
        return { ...f, value: null };
      }
      return f;
    });
    updateFilters(newFilters);
  };

  const updateFilters = useCallback((newFilters: Filter[], save: boolean = true) => {
    for (const f of newFilters) {
      if (f.value) {
        searchParams.set(f.name, f.value);
      }
      else {
        searchParams.delete(f.name);
      }
    }
    setSearchParams(searchParams);
    if (save) {
      tableView.saveTableView({ ...tableView.tableView, filters: newFilters.map(f => ({ key: f.name, value: f.value })) });
    }
  }, [tableView, setSearchParams, searchParams]);

  useEffect(() => {
    if (!savedTableLoaded.current && tableView.tableView && tableView.tableView.filters) {
      savedTableLoaded.current = true;
      const newFilters = tableView.tableView.filters.map(({ key, value }) => {
        const filter = filters.find(f => f.name === key);
        return filter ? { ...filter, value } : null;
      }).filter(f => f !== null) as Filter[];
      updateFilters(newFilters, false);
    }
  }, [tableView.tableView, filters, updateFilters]);

  useEffect(() => {
    const newSearchParamsHash = searchParams.toString();
    if (searchParamsHash.current !== newSearchParamsHash) {
      searchParamsHash.current = newSearchParamsHash;
      const filterValues = filters.reduce((acc, f) => {
        const value = searchParams.get(f.name);
        if (value) {
          acc[f.name] = value;
        }
        return acc;
      }, {} as FilterValues);
      onFilterValuesChange(filterValues);
    }
  }, [searchParams, onFilterValuesChange, filters]);

  return (
    <div className="flex items-center gap-4" data-test-id="table-filter-bar">
      <div className="flex items-center gap-2">
        <div className="text-text-primary">Filter by</div>
        <div className="flex gap-2 flex-wrap">
          <ActiveFilters filters={filtersWithValues} removeFilter={removeFilter} setFilterToShow={setFilterToShow} />
          <div className="relative">
            {
              showFiltersMenu ? (
                <FiltersMenu filters={filtersWithValues} setFilterToShow={setFilterToShow} onClose={() => setShowFiltersMenu(false)} />
              ) : (
                <div id="open-dropdown-menu-button" className="cursor-pointer rounded-lg bg-white p-1.5 text-text-primary hover:bg-slate-50 border-slate-200 border" onClick={() => setShowFiltersMenu(true)}>
                  <PlusIcon width="16" height="16" />
                </div>
              )
            }
            <FilterPanel
              filters={filtersWithValues}
              updateFilters={updateFilters}
              filterToShow={filterToShow}
              setFilterToShow={setFilterToShow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ActiveFiltersProps {
  filters: Filter[];
  removeFilter: (name: string) => void;
  setFilterToShow: (filter: Filter) => void;
}

const ActiveFilters = ({ filters, removeFilter, setFilterToShow }: ActiveFiltersProps) => {
  const filtersToShow = filters.filter((f) => !f.isDisabled && f.isActive(f.value));
  return (
    <>
      {filtersToShow.map((f) => (
        <div
          key={f.name}
          className="mb-auto mt-auto flex h-6 items-center justify-between gap-1 rounded-2xl bg-muse-100 px-1 text-muse-700">
          <div className="flex cursor-pointer items-center gap-1 whitespace-nowrap" onClick={() => setFilterToShow(f)}>
            {f.filterIcon}
            <TextWithEllipsisAndTooltip text={`${f.name}: ${f.value?.split(',').map(v => f.presentValue(v)).join(',')}`} maxChars={50} />
          </div>
          <XMarkIcon
            width="16"
            height="16"
            className="ml-1 cursor-pointer text-muse-400 hover:text-muse-600"
            onClick={() => removeFilter(f.name)}
            id='remove-filter-button'
          />
        </div>
      ))}
    </>
  );
};