import HealthType from "./HealthType";

export const HealthLabel = ({ health }: { health: HealthType }) => {
    const healthLabelMap = {
      healthy: {
        label: 'Healthy',
        background: 'bg-green-300',
        textColor: 'text-green-700'
      },
      pending: {
        label: 'Pending',
        background: 'bg-yellow-500',
        textColor: 'text-white'
      },
      error: {
        label: 'Error',
        background: 'bg-red-500',
        textColor: 'text-white'
      },
      problem: {
        label: 'Problem',
        background: 'bg-red-500',
        textColor: 'text-white'
      }
    };
  
    return (
      <span
        className={`mt-1 rounded-full px-2 text-sm ${healthLabelMap[health].background} ${healthLabelMap[health].textColor}`}>
        {healthLabelMap[health].label}
      </span>
    );
  };