import { useState } from "react";
import { DiscoverFilterProps, Filter } from "./Types";
import { store } from "../../../../../infrastructure/state/store";
import { searchFilterValues } from "../../../../../services/nodes/nodes";
import { accountsApi } from "../../../../../services/accounts/accounts";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useSelector } from "react-redux";
import { LanguageIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "react-use";
import { SelectFilterOption } from "../../../../../components/Table/TableFilters/types";
import { SelectFilter } from "src/components/Table/TableFilters/SelectFilter";

export const NameFilter = (props: DiscoverFilterProps) => {
    const [options, setOptions] = useState<SelectFilterOption[]>([]);
    const [query, setQuery] = useState<string>('');
    const accountId = useSelector(selectActiveAccountId);
    useDebounce(
        async () => {
            const options = await getNameOptions(query, accountId, props.filters || []);
            setOptions(options);
        },
        300,
        [query]
    );
    const onQueryChange = async (query: string) => {
        setQuery(query);
    };
    return (
        <SelectFilter
            label={'Name'}
            {...props}
            isMulti={true}
            search='custom'
            options={options}
            onQueryChange={onQueryChange}
        />
    );
};

const getNameOptions = async (query: string, accountId: number, filters: Filter[]): Promise<SelectFilterOption[]> => {
    const { data: revision, error } = await store.dispatch(accountsApi.endpoints.getDamaRevision.initiate({ accountId }));
    const typeFilter = filters.find(f => f.name === 'Type');
    const filtersSearchQuery = typeFilter?.value ? `(${typeFilter.value.split(',').map(v => `type:"${v}"`).join(' OR ')})` : '';

    if (!revision) {
        console.error(error);
        return [];
    }
    else if (!query) {
        return [];
    }
    else {
        const nameFacetValues = await searchFilterValues({
            applicationId: revision.algoliaAppId,
            apiKey: revision.algoliaKey,
            indexName: revision.algoliaIndex,
            filterName: 'name',
            filterQuery: query,
            filters: filtersSearchQuery
        });
        return nameFacetValues.map(name => ({
            label: name,
            value: name,
            icon: <LanguageIcon width="16" height="16" />
        }));
    }
};