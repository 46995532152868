interface IStakeholder {
  user: string;
  role: Roles;
  inherited_from?: {
    name: string;
    parent: number;
    id: number;
  };
}

export enum Roles {
  admin = 'admin',
  viewer = 'viewer',
  contributr = 'contributor',
  developer = 'developer',
  owner = 'owner'
}

export const roleHierarchy = [Roles.admin, Roles.owner, Roles.developer, Roles.contributr, Roles.viewer];

export default IStakeholder;
