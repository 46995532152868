import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from '@descope/react-sdk';
import App from './App.tsx';
import './assets/css/index.css';
import './assets/css/fonts.css';
import { Provider } from 'react-redux';
import { store } from './infrastructure/state/store.ts';
import config from './config.ts';
import * as sentry from './infrastructure/sentry';
import { IntercomProvider } from 'react-use-intercom';

sentry.init();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <IntercomProvider appId={import.meta.env.VITE_INTERCOM_TOKEN}>
      <Provider store={store}>
        <AuthProvider projectId={config.descopeProjectId}>
          <App />
        </AuthProvider>
      </Provider>
    </IntercomProvider>
  </React.StrictMode>
);