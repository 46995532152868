import { useEffect, useMemo, useState } from 'react';
import { DbtCloudIcon } from '../../../assets/images/icons/DelphiIcons';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Select, { Option } from '../../../components/form/Select';
import { Alert } from '../../../components/Alert';
import { useGetDbtCloudJobsQuery } from '../../../services/accounts/accounts';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { selectActiveProject } from '../../../infrastructure/state/slices/activeProjectSlice';
import { IProject } from '../IProject';
import { useUpdateProjectMutation } from '../../../services/projects/projects';

const ManageProjectDeploymentJob = ({ project }: { project: IProject }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const DeploymentJobLabel = (
    <div className="flex items-center gap-1 text-text-primary">
      Linked to
      <DbtCloudIcon width="12" height="12" fill="#FF694A" />
      <span className="font-light">{project.dbtCloudDeploymentJob?.name}</span>
    </div>
  );

  const SelectJobLabel = <div className="text-slate-400">Select a job to process builds.</div>;

  return (
    <div className="mb-8 mt-10">
      <span className="text-lg text-text-primary">Deployment job link</span>
      <div className="mt-4 flex items-center justify-between rounded-lg border border-slate-200 bg-white px-6 py-4 shadow">
        {project.dbtCloudDeploymentJob ? DeploymentJobLabel : SelectJobLabel}
        <Button
          type={ButtonTypes.secondary}
          text={project.dbtCloudDeploymentJob ? 'Change' : 'Select job'}
          onClick={() => setIsModalOpen(true)}
          dataTestId={project.dbtCloudDeploymentJob ? 'change-deployment-job' : 'select-deployment-job'}
        />
      </div>
      {isModalOpen && (
        <LinkDeploymentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} project={project} />
      )}
    </div>
  );
};

interface LinkDeploymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: IProject;
}

const LinkDeploymentModal = ({ isOpen, onClose, project }: LinkDeploymentModalProps) => {
  const [deploymentJobId, setDeploymentJobId] = useState<string | null>(project.dbtCloudDeploymentJob?.id || null);
  const accountId = useSelector(selectActiveAccountId);
  const projectId = useSelector(selectActiveProject);
  const getDbtCloudJobs = useGetDbtCloudJobsQuery({ projectId, accountId });
  const [updateProject, { isLoading: isLoadingUpdateProject }] = useUpdateProjectMutation();
  const buildJobs = useMemo(
    () => getDbtCloudJobs.data?.map((job) => ({ label: job.name, value: job.id })) || [],
    [getDbtCloudJobs.data]
  );
  useEffect(() => {
    if (getDbtCloudJobs.error) {
      notify(`Failed to fetch dbt cloud jobs: ${extractErrorMessage(getDbtCloudJobs.error).message}`, 'error');
    }
  }, [getDbtCloudJobs.error]);

  const onSelect = (option: Option | Option[]) => {
    setDeploymentJobId((option as Option).value.toString());
  };

  const onSave = async () => {
    try {
      const deploymentJob = getDbtCloudJobs.data?.find((job) => job.id === deploymentJobId);
      await updateProject({ projectId, dbtCloudDeploymentJob: deploymentJob! }).unwrap();
      notify('Deployment job saved successfully', 'success');
      onClose();
    } catch (e) {
      notify(`Failed to save deployment job: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Link to deployment job"
      onClose={onClose}
      buttons={[
        {
          type: ButtonTypes.primary,
          text: 'Save',
          onClick: onSave,
          isLoading: isLoadingUpdateProject
        }
      ]}>
      <div className="mb-4 flex items-center justify-between text-secondary">
        <div>Link to</div>
        <div className="rounded-lg border border-slate-200 py-2 pl-3 shadow">
          <Select
            options={buildJobs}
            value={deploymentJobId}
            onChange={onSelect}
            height="20px"
            className="w-60"
            isLoading={getDbtCloudJobs.isLoading}
            placeholder="Select a deployment job"
            icon={<DbtCloudIcon width="12" height="12" fill="#94A3B8" />}
            dataTestId='deployment-job-select'
          />
        </div>
      </div>
      <Alert
        text={`Ensure that the selected job is building the entire project’s data model and that the 'Generate docs on run' option has been configured.`}
        type={'info'}
      />
    </Modal>
  );
};

export default ManageProjectDeploymentJob;
