interface CheckboxProps {
  value: boolean;
  setValue: (value: boolean) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const Checkbox = ({ value, setValue, label, className, disabled = false }: CheckboxProps) => {
  const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
  return (
    <div className={`flex items-center ${className} gap-1`}>
      <label className={`${cursorClass} relative flex items-center rounded`} data-ripple-dark="true">
        <input
          type="checkbox"
          className={`${cursorClass} before:content[''] peer relative h-4 w-4 appearance-none rounded border border-gray-300 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:-translate-x-2/4 before:-translate-y-2/4 before:opacity-0 before:transition-opacity checked:bg-indigo-600  hover:before:opacity-10`}
          checked={value}
          onChange={() => setValue(!value)}
          disabled={disabled}
        />
        <div className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"></path>
          </svg>
        </div>
      </label>
      {label && <span className="text-text-primary">{label}</span>}
    </div>
  );
};

export default Checkbox;
