import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IProject } from '../../features/projects/IProject';
import { ChangeStatus, IChange } from '../../features/evolution/IChange';

const getTargetBranchFromChange = (change: IChange | undefined) =>
  change && change.status !== ChangeStatus.Proposal ? change?.targetBranch : '';

const useDefaultTargetBranch = (
  project: IProject | undefined,
  branchOptions: { label: string; value: string }[],
  change: IChange | undefined = undefined
): [string, Dispatch<SetStateAction<string>>] => {
  const [targetBranch, setTargetBranch] = useState(getTargetBranchFromChange(change));

  useEffect(() => {
    const newTargetBranch = getTargetBranchFromChange(change);
    if (newTargetBranch) {
      setTargetBranch(newTargetBranch);
    } else {
      const defaultTargetBranchIfPresent = project?.repository.defaultTargetBranch
        ? [project?.repository.defaultTargetBranch]
        : ['main', 'master'];
      for (const branch of defaultTargetBranchIfPresent) {
        if (branchOptions.find((b) => b.value === branch)) {
          setTargetBranch(branch);
          return;
        }
      }
      setTargetBranch('');
    }
  }, [project, setTargetBranch, branchOptions, change]);

  return [targetBranch, setTargetBranch];
};

export { useDefaultTargetBranch };
