import { TailSpin } from 'react-loader-spinner';
import FileDiff from '../../../../../components/FileDiff';
import Button from '../../../../../components/button/Button';
import { ButtonTypes } from '../../../../../components/button/types';
import { useCallback, useEffect, useState } from 'react';
import { ChangeDiff } from '../../../../evolution/IChange';
import { extractErrorMessage } from '../../../../../services/api';
import { notify } from '../../../../../components/Toaster';
import { useCreateProjectConfigurationDiffPreviewMutation } from '../../../../../services/projects/projects';
import { DryRunResponse } from '../../../../../services/projects/types';
import { IProjectConfiguration } from '../../../IProject';
import { useParams } from 'react-router-dom';
import { getFilenameFromPath } from '../../../../../utils/stringsUtils';

interface SyncConfigurationCodePreviewProps {
  onClose: () => void;
  apply: () => void;
  newProjectConfiguration: IProjectConfiguration | null;
}

const SyncConfigurationCodePreview = ({
  onClose,
  apply,
  newProjectConfiguration
}: SyncConfigurationCodePreviewProps) => {
  const projectId = parseInt(useParams().projectId || '');
  const [diff, setDiff] = useState<ChangeDiff[]>([]);
  const [createDiff, { isLoading }] = useCreateProjectConfigurationDiffPreviewMutation();

  const getDiff = useCallback(async () => {
    try {
      if (!newProjectConfiguration) {
        return;
      }
      const result = await createDiff({ projectId, configuration: newProjectConfiguration }).unwrap();
      setDiff(getDiffFromResult(result));
    } catch (e) {
      notify(`Failed to generate preview: ${extractErrorMessage(e).message}`, 'error');
    }
  }, [setDiff, newProjectConfiguration, projectId, createDiff]);

  useEffect(() => {
    getDiff();
  }, [getDiff, newProjectConfiguration]);

  return (
    <div className="flex flex-col gap-2 text-secondary">
      {isLoading && (
        <div className="mt-2 flex items-center">
          <TailSpin
            height="32"
            width="32"
            color="#0047FF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="mr-2"
            visible={true}
          />
          Generating preview...
        </div>
      )}
      {diff.map((file, index) => (
        <FileDiff key={index} file={file} index={index} />
      ))}
      <div className="ml-auto mt-5 flex w-fit gap-2">
        <Button type={ButtonTypes.secondary} onClick={onClose} className="w-36" text="Cancel" />
        <Button
          type={ButtonTypes.primary}
          onClick={apply}
          className="w-36"
          text="Commit Changes"
          isLoading={isLoading}
          isDisabled={diff.length === 0}
        />
      </div>
    </div>
  );
};

const getDiffFromResult = (response: DryRunResponse): ChangeDiff[] => {
  return response.parsed_diff.map((file) => ({
    fileName: getFilenameFromPath(file.new_path || ''),
    oldCode: atob(file.old_content_base64 || ''),
    newCode: atob(file.new_content_base64 || '')
  }));
};

export default SyncConfigurationCodePreview;
