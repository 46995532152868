import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { useMemo, useCallback } from "react";
import { MetricDependency } from "../../../../../models/discover/INode";
import { NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../../types";
import { generateAggregateTableColumn } from "../../utilities/generateAggregateTableColumn";
import Select, { Option } from "../../../../../../components/form/Select";

type AggregateTableGroupEntitiesProps = {
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
    metricDependencies: MetricDependency[];
    isLoading: boolean;
}

export const AggregateTableGroupEntities = ({ changeData, setChangeData, metricDependencies, isLoading }: AggregateTableGroupEntitiesProps) => {
    const columns = useMemo(() => changeData.columns, [changeData]);
    const entities = useMemo(() => columns.filter(c => c.type === NewAggregateTableChangeColumnType.entity), [columns]);
    const addEmptyEntity = useCallback(() => setChangeData({ ...changeData, columns: [...columns, generateAggregateTableColumn()] }), [columns, setChangeData, changeData]);
    const setColumns = useCallback((columns: NewAggregateTableChangeColumn[]) => setChangeData({ ...changeData, columns }), [changeData, setChangeData]);

    return (
        <div className="flex flex-col gap-2 flex-1">
            {
                entities.map(entity => (
                    <Entity
                        key={entity.name}
                        entity={entity}
                        columns={columns}
                        metricDependencies={metricDependencies}
                        setColumns={setColumns}
                        isLoading={isLoading}
                    />
                ))
            }
            <div className="flex items-center -ml-0.5 flex gap-2 cursor-pointer text-slate-400 hover:text-slate-500" onClick={addEmptyEntity}>
                <PlusCircleIcon width={16} height={16} />
                Add entity (optional)
            </div>
        </div>
    );
};

type EntityProps = {
    entity: NewAggregateTableChangeColumn;
    columns: NewAggregateTableChangeColumn[];
    metricDependencies: MetricDependency[];
    setColumns: (columns: NewAggregateTableChangeColumn[]) => void;
    isLoading: boolean;
}

const Entity = ({ entity, columns, metricDependencies, setColumns, isLoading }: EntityProps) => {
    const index = columns.findIndex(c => c.name === entity.name && c.type === NewAggregateTableChangeColumnType.entity);
    const removeEntity = () => setColumns([...columns.filter(c => c !== entity && c.parentEntity !== entity.name)]);
    const entityOptions = useMemo(() => metricDependencies.map(e => ({ label: e.entityName, value: e.entityName, disabled: columns.some(c => c.name === e.entityName && c.type === NewAggregateTableChangeColumnType.entity) })), [metricDependencies, columns]);
    const entityPaths = useMemo(() => metricDependencies.find(md => md.entityName === entity.name)?.entityPaths || [], [metricDependencies, entity]);

    const selectEntity = (name: string) => {
        const matchedEntity = metricDependencies.find(e => e.entityName === name);
        if (!matchedEntity) {
            throw new Error('Entity not found in related entities list.');
        }
        const newColumns = [...columns];
        newColumns[index] = generateAggregateTableColumn({ name, description: matchedEntity.entityDescription, entityPath: matchedEntity.entityPaths[0] || null, type: NewAggregateTableChangeColumnType.entity });
        setColumns(newColumns);
    };

    const selectPath = (path: string) => {
        const newColumns = [...columns];
        newColumns[index] = generateAggregateTableColumn({ ...entity, entityPath: path });
        setColumns(newColumns);
    };

    return (
        <div className="flex gap-2 w-full items-center">
            <div className="rounded shadow border p-2 bg-white flex-1">
                <div className="flex gap-1">
                    <Select
                        placeholder="Select entity"
                        className="text-primary flex-1 border shadow-sm rounded"
                        height="25px"
                        options={entityOptions}
                        value={entity.name}
                        onChange={option => selectEntity((option as Option).value.toString())}
                        isLoading={isLoading}
                        dataTestId="entity-select"
                    />
                        <Select
                            className="w-36 text-primary border shadow-sm rounded"
                            height="25px"
                            placeholder="N/A"
                            options={entityPaths.map(p => ({ label: p, value: p }))}
                            value={entity.entityPath || 'N/A'}
                            onChange={option => selectPath((option as Option).value.toString())}
                            isLoading={isLoading}
                            isDisabled={entityPaths.length <= 1}
                        />
                </div>
            </div>
            <MinusCircleIcon
                height="16"
                width="16"
                className="text-slate-400 cursor-pointer hover:text-slate-500"
                onClick={removeEntity}
            />
        </div>
    );
};
