import { useSelector } from "react-redux";
import Select, { Option } from "src/components/form/Select";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDamaRevisionQuery } from "src/services/accounts/accounts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getNodes } from "src/services/nodes/nodes";
import { SubnodeType } from "src/features/models/discover/INode";
import { NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType } from "src/features/evolution/changeForms/newAggregateTable/types";
import { generateAggregateTableColumn } from "src/features/evolution/changeForms/newAggregateTable/utilities/generateAggregateTableColumn";
import { IRevision } from "src/features/evolution/IRevision";
import { getMetricMeta } from "src/features/evolution/changeForms/newAggregateTable/utilities/getMetricMeta";
import { filterDuplicates } from "src/utils/arrayUtils";

type AggregateTableMetricsFormProps = {
    columns: NewAggregateTableChangeColumn[];
    setColumns: (columns: NewAggregateTableChangeColumn[]) => void;
    dbtProjectName: string;
}

type SelectableMetric = {
    metricName: string;
    modelName: string;
    modelId: string;
}

export const AggregateTableMetricsForm = ({ columns, setColumns, dbtProjectName }: AggregateTableMetricsFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getDamaRevisionQuery = useGetDamaRevisionQuery({ accountId });
    const [metrics, setMetrics] = useState<SelectableMetric[]>([]);
    const metricColumns = useMemo(() => filterDuplicates(columns.filter(c => c.type === NewAggregateTableChangeColumnType.metric), (c) => `${c.type}-${c.name}`), [columns]);

    useEffect(() => {
        getMetrics(getDamaRevisionQuery.data || null, dbtProjectName).then(setMetrics);
    }, [getDamaRevisionQuery.data, dbtProjectName, accountId]);

    const onChange = useCallback(async (options: Option | Option[]) => {
        const inferedOptions = options as Option[];
        const updatedColumns = [...columns];
        const addedMetricName = inferedOptions.map(i => i.value).filter(v => !metricColumns.find(c => c.name === v))[0];
        const removedMetricName = metricColumns.map(c => c.name).filter(n => !inferedOptions.map(i => i.value).includes(n))[0];
        if (addedMetricName) {
            const metric = metrics.find(m => m.metricName === addedMetricName);
            if (!metric) {
                throw new Error('Metric not found in selected metrics list.');
            }
            const metricMeta = await getMetricMeta(metric.modelId, metric.metricName, accountId);
            updatedColumns.push(generateAggregateTableColumn({ name: metric.metricName, type: NewAggregateTableChangeColumnType.metric, dataType: 'float', meta: metricMeta }));
        }
        if (removedMetricName) {
            updatedColumns.splice(updatedColumns.findIndex(c => c.name === removedMetricName && c.type === NewAggregateTableChangeColumnType.metric), 1);
        }
        setColumns(updatedColumns);
    }, [columns, metrics, accountId, setColumns, metricColumns]);

    return (
        <Select
            placeholder="Select metrics"
            className="w-full rounded border shadow"
            isMulti={true}
            options={metrics.map(m => ({ label: m.metricName, value: m.metricName }))}
            value={metricColumns.map(c => c.name)}
            onChange={onChange}
            height="fit-content"
        />
    );
};

const getMetrics = async (revision: IRevision | null, dbtProjectName: string): Promise<SelectableMetric[]> => {
    if (!revision) return [];
    const { items } = await getNodes({
        apiKey: revision.algoliaKey,
        applicationId: revision.algoliaAppId,
        indexName: revision.algoliaIndex,
        pageSize: 1000,
        filters: `type:dbt_model AND number_of_metrics>0 AND dbt_project:${dbtProjectName}`
    });
    return filterDuplicates(items.flatMap(n => n.subnodes.filter(sn => sn.type === SubnodeType.Metric).map(m => ({ metricName: m.name, modelName: n.name, modelId: n.id }))), (n) => n.metricName);
};
