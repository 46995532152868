import { IChange, IChangePage, Pat } from "../../features/evolution/IChange";
import { dateFilterToTimeRange } from "../../features/evolution/changesPage/filters/dateFilter";
import { Operation } from "../../features/operations/Operation";
import api from "../api";
import { statusToState, transformChangeResponse, transformChangeToRequest, transformCreateChangeToRequest, transformChangePageResponse, transformAlgoliaPatToLocalPat, transformPatResponse, mapLocalChangeTypeToBackendChangeType } from "./transformers";
import { AlgoliaPat, BackendChangeType, GetChangesPageResponse, GetChangesRequest } from "./types";
import algoliasearch from "algoliasearch";

export const changesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChanges: build.query<{ items: IChangePage[], total: number }, GetChangesRequest>({
      query: ({ projectId, sortBy = 'newest', statuses, search = '', page = 1, pageSize = 100, owner = '', createdAt = null, types }) => {
        const { start: created_after, end: created_before } = dateFilterToTimeRange(createdAt);
        return {
          url: `projects/${projectId}/changes`,
          params: {
            sort: sortBy,
            state: statuses.map((status) => statusToState[status]).join(','),
            search,
            page,
            page_size: pageSize,
            created_by_email: owner,
            created_after,
            created_before,
            types: types ? types.map(t => mapLocalChangeTypeToBackendChangeType.get(t) as BackendChangeType).join(',') : undefined
          }
        };
      },
      providesTags: ['Changes'],
      transformResponse: (response: { items: GetChangesPageResponse[], total: number }) => ({
        items: response.items.map(transformChangePageResponse),
        total: response.total
      })
    }),
    proposeChangeToDataModel: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/propose`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    getChange: build.query<IChange, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    getChangeInAccountContext: build.query<IChange, { accountId: number; changeId: number }>({
      query(data) {
        return {
          url: `accounts/${data.accountId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    updateChange: build.mutation<void, { projectId: number; changeId: number; change: IChange }>({
      query: ({ projectId, changeId, change }) => {
        return {
          url: `projects/${projectId}/changes/${changeId}`,
          method: 'PATCH',
          body: transformChangeToRequest(change)
        };
      },
      invalidatesTags: ['Changes']
    }),
    createPullRequest: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/create_pull_request`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    rejectChange: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/reject`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    generateChangeDiff: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/generate_diff`,
          method: 'POST'
        };
      }
    }),
    createChange: build.mutation<IChange, { projectId: number; change: Pat }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes`,
          method: 'POST',
          body: transformCreateChangeToRequest(data.change)
        };
      },
      invalidatesTags: ['Changes'],
      transformResponse: transformChangeResponse
    }),
    getPat: build.query<Pat, { accountId: number; uri: string }>({
      query(data) {
        const properties = ['shift_left_potential', 'shift_left_type'];
        return {
          url: `accounts/${data.accountId}/data_model/resources/${data.uri}?properties=${properties.join(',')}`
        };
      },
      transformResponse: transformPatResponse
    })
  })
});

export const {
  useGetChangesQuery,
  useProposeChangeToDataModelMutation,
  useGetChangeQuery,
  useUpdateChangeMutation,
  useCreatePullRequestMutation,
  useRejectChangeMutation,
  useGenerateChangeDiffMutation,
  useGetChangeInAccountContextQuery,
  useCreateChangeMutation,
  useLazyGetPatQuery,
  useGetPatQuery
} = changesApi;

type GetPatsProps = {
  query?: string;
  applicationId: string;
  apiKey: string;
  indexName: string;
  page: number;
  pageSize: number;
  filters?: string;
  projectId: number;
}

export const getPats = async ({ query = '', applicationId, apiKey, indexName, page, pageSize, filters = '', projectId }: GetPatsProps) => {
  const defaultFilters = `has_shift_left_potential:true AND shift_left_project:${projectId}`;
  const algoliaClient = algoliasearch(applicationId, apiKey);
  const index = algoliaClient.initIndex(indexName);
  const filtersWithDefault = filters ? `${filters} AND ${defaultFilters}` : defaultFilters;
  const results = await index.search(query, { hitsPerPage: pageSize, page, filters: filtersWithDefault, cacheable: true, restrictSearchableAttributes: ['shift_left_title'] });
  const pats = results.hits.map(hit => transformAlgoliaPatToLocalPat(hit as AlgoliaPat));
  return {
    items: pats,
    total: results.nbHits
  };
};