import dagre from 'dagre';
import { DAGNode, NodePositionsMap } from './Types';
import { distanceBetweenNodes } from '../../features/models/discover/INode';

type GetNodesPositionsProps = {
  nodes: DAGNode[];
  xOffset: number;
  yOffset: number;
  distanceBetweenForeignClusters?: number;
}

export const getNodesPositions = ({ nodes, xOffset, yOffset, distanceBetweenForeignClusters = 1 }: GetNodesPositionsProps) => {
  console.log(`Starting DAG algo for ${nodes.length} nodes`);
  const startTime = Date.now();
  const dagreGraph = new dagre.graphlib.Graph({ compound: true, directed: true });
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', ranksep: distanceBetweenNodes / 2, nodesep: distanceBetweenNodes / 2 });
  const nodeMap = new Map<string, DAGNode>();
  const clusters = [...new Set(nodes.map((node) => node.cluster))];
  clusters.forEach((cluster) => cluster && dagreGraph.setNode(cluster.toString(), {}));

  nodes.forEach((node) => {
    nodeMap.set(node.id, node);
    dagreGraph.setNode(node.id, { width: node.width, height: node.height });
    node.cluster && dagreGraph.setParent(node.id, node.cluster.toString());
  });

  const nodeIdsMap = new Map<string, boolean>(nodes.map((node) => [node.id, true]));
  nodes
    .flatMap((node) => node.parents.map((parent) => ({ source: parent, target: node.id })))
    .forEach((edge) => {
      const isParentInNodes = nodeIdsMap.get(edge.source);
      if (isParentInNodes) {
        const isDifferentCluster = nodeMap.get(edge.source)?.cluster !== nodeMap.get(edge.target)?.cluster;
        const minlen = isDifferentCluster ? distanceBetweenForeignClusters : 1;
        dagreGraph.setEdge(edge.source, edge.target, { minlen });
      }
    });

  dagre.layout(dagreGraph);
  const positionMap = new Map<string, { x: number; y: number }>();
  for (const node of nodes) {
    const { x, y } = dagreGraph.node(node.id);
    positionMap.set(node.id, { x: x + xOffset, y: y + yOffset });
  }

  reduceSpacingBetweenClusters(nodes, positionMap);
  reduceYAxisSpacing(nodes, positionMap);
  console.log('DAG Algo took', Date.now() - startTime, 'ms');
  return positionMap;
};

const reduceSpacingBetweenClusters = (nodes: DAGNode[], nodesPositions: NodePositionsMap) => {
  const orderedClusters = getOrderedClusters(nodes);
  for (let i = 1; i < orderedClusters.length; i++) {
    const nodesInCluster = nodes.filter((n) => n.cluster === orderedClusters[i]);
    const previousNodesInCluster = nodes.filter((n) => n.cluster === orderedClusters[i - 1]);
    const minXInCluster = Math.min(...nodesInCluster.map((n) => nodesPositions.get(n.id)!.x));
    const maxXInPreviousCluster = Math.max(...previousNodesInCluster.map((n) => nodesPositions.get(n.id)!.x));
    const nodeWidth = nodesInCluster[0].width || 0;
    const xToReduce = minXInCluster - maxXInPreviousCluster - distanceBetweenNodes - nodeWidth;
    nodesInCluster.forEach(node => reduceNodeSpacing(node, nodesPositions, xToReduce));
  }
};

const reduceNodeSpacing = (node: DAGNode, nodesPositions: NodePositionsMap, xToReduce: number, yToReduce?: number) => {
  nodesPositions.set(node.id, {
    x: nodesPositions.get(node.id)!.x - xToReduce,
    y: yToReduce ? nodesPositions.get(node.id)!.y - yToReduce : nodesPositions.get(node.id)!.y,
  });
};

const reduceYAxisSpacing = (nodes: DAGNode[], nodesPositions: NodePositionsMap) => {
  for (const cluster of getOrderedClusters(nodes)) {
    const nodesInCluster = nodes.filter((n) => n.cluster === cluster);
    const minYInCluster = Math.min(...nodesInCluster.map((n) => nodesPositions.get(n.id)!.y));
    if (minYInCluster > distanceBetweenNodes) {
      const yToReduce = minYInCluster - distanceBetweenNodes;
      nodesInCluster.forEach((node) => reduceNodeSpacing(node, nodesPositions, 0, yToReduce));
    }
  }
};

const getOrderedClusters = (nodes: DAGNode[]) => {
  return [...new Set(nodes.map((node) => node.cluster))].sort((a, b) => (a || 0) - (b || 0));
};