import { GithubIcon } from '../../../assets/images/icons/DelphiIcons';
import Layout from '../../../components/layout/Layout';
import { ChangeStatus, ChangeType, IChange } from '../IChange';
import ProjectHeader from '../../projects/ProjectHeader';
import { useParams } from 'react-router-dom';
import { useGetProjectQuery } from '../../../services/projects/projects';
import PageLoader from '../../../components/loaders/PageLoader';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import { CodeBracketIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/solid';
import ChangeState from './ChangeState';
import { ChangeSection as NewColumnChangeSection } from './newColumnChange/ChangeSection';
import { ChangeSection as NewMeasureChangeSection } from './newMeasureChange/ChangeSection';
import { useState } from 'react';
import ChangeImplications from './ChangeImplications';
import EditChangeSidepane from '../editChangeSidepane/EditChangeSidepane';
import ChangeCodeDiff from './ChangeCodeDiff';
import { useGetChangeQuery } from '../../../services/changes/changes';
import { NewModelChangeSection } from './newModelChange/NewModelChange';
import { NewAggregateTableChangeSection } from './newAggregateTableChange/NewAggregateTableChangeSection';
import { dateFormats, utcToLocal } from '../../../infrastructure/dateUtilities';

const Change = () => {
  const projectId = parseInt(useParams().projectId || '');
  const changeId = parseInt(useParams().changeId || '');
  const { data: change, isLoading: isLoadingChange } = useGetChangeQuery({ changeId, projectId });
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(projectId);
  const [showEditSidepane, setShowEditSidepane] = useState(false);

  if (isLoadingProject || isLoadingChange) {
    return <PageLoader label="Loading" />;
  } else if (!project) {
    return null;
  } else if (!change) {
    return <div>Change not found</div>;
  } else {
    return (
      <Layout>
        <ProjectHeader project={project} />
        <div className="px-[8vw] pb-10 pt-3">
          {change.status === ChangeStatus.Open && (
            <Button
              type={ButtonTypes.secondary}
              icon={<PencilIcon width="12" height="12" />}
              className="ml-auto"
              text="Edit"
              onClick={() => setShowEditSidepane(true)}
            />
          )}
          <div className="mt-3 rounded-lg border border-slate-200 bg-white pt-6">
            <div className="mt-2 flex justify-between gap-24 px-8">
              <ChangeTextualInformation change={change} />
              <ChangeState change={change} />
            </div>
            <div className="mx-8 mt-10 rounded-md border border-slate-200 bg-surface-light p-6">
              {change.changeType === ChangeType.NewColumn && <NewColumnChangeSection change={change} />}
              {change.changeType === ChangeType.NewMeasure && <NewMeasureChangeSection change={change} />}
              {change.changeType === ChangeType.NewModel && <NewModelChangeSection change={change} />}
              {change.changeType === ChangeType.NewAggregateTable && <NewAggregateTableChangeSection change={change} />}
            </div>
            <div className="mt-4 flex flex-col gap-10 rounded-b-lg bg-surface-light p-8">
              {![ChangeType.NewModel, ChangeType.NewAggregateTable].includes(change.changeType) && (
                <ChangeImplications change={change} />
              )}
              <ChangeCodeDiff change={change} isActive={true} />
            </div>
          </div>
        </div>
        <EditChangeSidepane
          change={change}
          isOpen={showEditSidepane}
          onClose={() => setShowEditSidepane(false)}
        />
      </Layout>
    );
  }
};

const ChangeTextualInformation = ({ change }: { change: IChange }) => {
  const redirectToPr = () => {
    window.open(change.pullRequestUrl, '_blank');
  };
  const showBuildInfo = () => {
    window.open(`/project/${change.projectId}/manage`, '_blank');
  };
  return (
    <div>
      <div className="flex gap-1 text-sm font-medium text-slate-500">
        <DocumentTextIcon width="16" height="16" />
        CHANGE #{change.id}
      </div>
      <div className="mt-2 text-lg text-text-primary">{change.title}</div>
      <div className="mt-1 text-text-primary">{change.description}</div>
      <div className="mt-2 text-sm text-tertiary">
        Created by {change.createdBy} on {utcToLocal(change.createdAt, dateFormats.dateHoursAndMinutes)}
      </div>
      <div className="mt-1 text-sm text-tertiary">
        Last edited by {change.updatedBy} on {utcToLocal(change.updatedAt, dateFormats.dateHoursAndMinutes)}
      </div>
      <div className="mt-3 flex gap-2">
        {change.pullRequestUrl && (
          <Button
            type={ButtonTypes.secondary}
            text={`Pull request #${change.pullRequestNumber}`}
            className="!h-6 !rounded-full"
            onClick={redirectToPr}
            icon={<GithubIcon fill="#94A3B8" width="14" height="14" />}
          />
        )}
        {change.buildId && (
          <Button
            type={ButtonTypes.secondary}
            text={`Build #${change.buildId}`}
            className="!h-6 !rounded-full"
            onClick={showBuildInfo}
            icon={<CodeBracketIcon width="14" height="14" />}
          />
        )}
      </div>
    </div>
  );
};

export default Change;
