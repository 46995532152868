import { CodeBracketIcon } from "@heroicons/react/24/solid";
import { NewModelChangeData } from "../../IChange";
import { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { CodeViewer } from "../../../../components/CodeViewer/CodeViewer";
import { ButtonTypes } from "../../../../components/button/types";
import Button from "../../../../components/button/Button";

export const NewModelCode = ({ changeData }: { changeData: NewModelChangeData }) => {
    const [showCodeModal, setShowCodeModal] = useState(false);
    const onClose = () => setShowCodeModal(false);
    return (
        <div className="ml-2">
            <Button type={ButtonTypes.secondary} text="Show code" onClick={() => setShowCodeModal(true)} icon={<CodeBracketIcon width="14" height="14" />} />
            <Modal
                buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
                isOpen={showCodeModal}
                onClose={onClose}
                title="Model SQL code"
            >
                <CodeViewer code={changeData.sql} />
            </Modal>
        </div>
    );
};