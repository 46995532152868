import { useSelector } from "react-redux";
import { SchemaIcon } from "../../../../../assets/images/icons/DelphiIcons";
import RadioButton from "../../../../../components/form/RadioButton";
import { NodeType } from "../../../../models/discover/INode";
import { NodeIcon } from "../../../../models/discover/NodeIcon";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useEffect, useState } from "react";
import { getFilterOptions } from "../../../../../services/nodes/nodes";
import { notify } from "../../../../../components/Toaster";
import Select, { Option } from "../../../../../components/form/Select";
import { useGetDamaRevisionQuery } from "../../../../../services/accounts/accounts";

type NewAggregateTableSchemaFormProps = {
    schema: string | null;
    setSchema: (schema: string | null) => void;
    sourceName: string;
    dbtProject: string;
}

export const NewAggregateTableSchemaForm = ({ sourceName, schema, setSchema, dbtProject }: NewAggregateTableSchemaFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getDamaRevisionQuery = useGetDamaRevisionQuery({ accountId });
    const [schemas, setSchemas] = useState<string[]>([]);

    useEffect(() => {
        if (!getDamaRevisionQuery.data) return;
        const { algoliaKey, algoliaAppId, algoliaIndex } = getDamaRevisionQuery.data;
        getFilterOptions({ apiKey: algoliaKey, applicationId: algoliaAppId, indexName: algoliaIndex })
            .then(filters => {
                setSchemas(filters.database_schema || []);
            })
            .catch(e => {
                console.error(e);
                notify('Failed to get schema options', 'error');
            });
    }, [dbtProject, getDamaRevisionQuery.data]);

    return (
        <div className="flex gap-2 items-start">
            <div className="text-secondary text-sm font-medium flex items-center gap-1 w-40">
                <SchemaIcon width="12" height="12" />
                Model schema
            </div>
            <div className="text-sm flex flex-col gap-1 -ml-1">
                <div className="flex gap-1">
                    <RadioButton value={schema === null} setValue={() => setSchema(null)} />
                    <div className="flex gap-1 items-center">
                        Use same as underlying dbt model:
                        <NodeIcon type={NodeType.DataModel} greyVersion iconSize={12} />
                        {sourceName}
                    </div>
                </div>
                <div className="flex gap-1 items-start">
                    <RadioButton value={schema !== null} setValue={() => setSchema('')} />
                    <div>
                        Use a different schema
                        {
                            schema !== null && (
                                <div className="w-60">
                                    <Select
                                        value={schema}
                                        onChange={(option: Option | Option[]) => setSchema((option as Option).value.toString())}
                                        options={schemas.map(schema => ({ value: schema, label: schema }))}
                                        className="mt-2 rounded border shadow"
                                        placeholder="Select schema"
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};