import { useSelector } from "react-redux";
import Layout from "../../../components/layout/Layout";
import TopBar from "../../../components/layout/TopBar";
import { selectActiveAccountId } from "../../../infrastructure/state/slices/activeAccountSlice";
import { useGetAccountsQuery } from "../../../services/accounts/accounts";
import { useGetAccountProjectsQuery, useGetProjectConfigurationQuery } from "../../../services/projects/projects";
import { ProjectIcon, ProjectPlusIcon } from "../../../assets/images/icons/DelphiIcons";
import { HealthLabel } from "../HealthLabel";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { ButtonTypes } from "../../../components/button/types";
import { ProjectBuildStats } from "./ProjectBuildStats";
import BuildLifecycleState from "../../builds/BuildLifecycleState";
import { ProjectSyncConfiguration } from "./ProjectSyncConfiguration";
import { ProjectProposals } from "./ProjectsProposals";
import CreateProjectModal from "../create/CreateProjectModal";
import { useState } from "react";
import StakeholderEntityTypes from "../../stakeholders/stakeholdersEntityTypes";
import { TailSpin } from "react-loader-spinner";
import { dateFormats, utcToLocal } from "../../../infrastructure/dateUtilities";

export const ProjectsPage = () => {
    const accountId = useSelector(selectActiveAccountId);
    const getAccountsQuery = useGetAccountsQuery();
    const accountName = getAccountsQuery.data?.find(account => account.id === accountId)?.name || '';
    const projects = useGetAccountProjectsQuery({ accountId });
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

    return (
        <Layout>
            <TopBar invite={{ id: accountId, type: StakeholderEntityTypes.folder, name: accountName }}>
                <div className="font-medium text-primary">{accountName}</div>
            </TopBar>
            <div className="m-4">
                <div className="flex justify-between">
                    <div className="text-lg text-primary ml-4">{projects.data?.length} projects</div>
                    <Button dataTestId="open-create-project-modal" onClick={() => setShowCreateProjectModal(true)} type={ButtonTypes.primary} text="Create project" icon={<ProjectPlusIcon fill="white" width="12" height="12" />} />
                </div>
                <div className="flex gap-4 text-sm mt-4 flex-wrap">
                    {projects.data?.map(project => <ProjectBox key={project.id} id={project.id} name={project.name} />)}
                </div>
            </div>
            <CreateProjectModal
                isOpen={showCreateProjectModal}
                onClose={() => setShowCreateProjectModal(false)}
            />
        </Layout>
    );
};

const ProjectBox = ({ id, name }: { id: number, name: string }) => {
    const navigate = useNavigate();
    const { data: projectConfiguration, isLoading } = useGetProjectConfigurationQuery({ projectId: id });
    const lastSuccessfulBuild = projectConfiguration?.lastSuccessfulBuild?.lifecycleState === BuildLifecycleState.Processed && projectConfiguration?.lastSuccessfulBuild?.buildType === 'compile' ? projectConfiguration.lastSuccessfulBuild : null;
    const buildTime = projectConfiguration?.lastSuccessfulBuild?.buildTime || '';

    const viewProject = () => {
        navigate(`/project/${id}`);
    };
    const configureProject = () => {
        navigate(`/project/${id}/manage`);
    };

    return (
        <div className="bg-white shadow-sm rounded-md w-fit border border-slate-200 flex flex-col min-w-[300px]">
            <div className="flex gap-2 p-4">
                <div className="rounded-md p-2.5 bg-slate-50 h-fit w-fit"><ProjectIcon fill="#94A3B8" width="18" height="18" /></div>
                <div>
                    <div className="flex gap-2 items-start">
                        <div className="text-lg text-primary">{name}</div>
                        {
                            projectConfiguration && <HealthLabel health={projectConfiguration.health} />
                        }
                    </div>
                    {
                        buildTime && <div className="text-slate-500 text-sm mt-0.5">Last built successfully on {utcToLocal(buildTime, dateFormats.monthsDaysHoursAndMinutes)}</div>
                    }
                </div>
            </div>
            <div className="h-[1px] w-full bg-slate-200" />
            <div className="p-4">
                {
                    isLoading ? <div className="w-fit mx-auto">
                        <TailSpin
                            height="32"
                            width="32"
                            color="#0047FF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass="ml-auto mr-auto"
                            visible={true}
                        />
                    </div>
                        : (
                            <>
                                {
                                    lastSuccessfulBuild && <ProjectBuildStats build={lastSuccessfulBuild} />
                                }
                                {
                                    projectConfiguration && <ProjectSyncConfiguration projectConfiguration={projectConfiguration} />
                                }
                                <ProjectProposals projectId={id} />
                            </>
                        )
                }
            </div>
            <div className="mt-auto">
                <div className="h-[1px] w-full bg-slate-200" />
                <div className="flex gap-2 p-4">
                    <Button type={ButtonTypes.secondary} icon={<Cog6ToothIcon width="14" height="14" />} text="Configure" className="w-1/2" onClick={configureProject} />
                    <Button type={ButtonTypes.primary} icon={<ProjectIcon width="10" height="10" fill="white" className="mr-0.5" />} text="View project" className="w-1/2" onClick={viewProject} />
                </div>
            </div>
        </div>
    );
};
