import { ChartBarIcon, EyeIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { events, trackEvent } from "src/infrastructure/analytics";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDamaRevisionQuery } from "src/services/accounts/accounts";
import { getNodes } from "src/services/nodes/nodes";
import { NodeUsageAppType } from "src/services/nodes/types";
import { IExpandedNode, ISourceNodeUsage, IUserNodeUsage, ISubnode } from "src/features/models/discover/INode";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { LookerColorlessIcon } from "src/assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";

export const NodeUsage = ({ node }: { node: IExpandedNode | ISubnode }) => {
    const hasViews = node.last7DaysViews !== null || node.last30DaysViews !== null;
    const hasUsage = node.usage !== null;
    if (!hasViews && !hasUsage) return null;
    return (
        <div className="mt-4 p-2 rounded bg-surface-light flex flex-col gap-2 text-sm border border-border">
            {
                hasViews && <Views last7DaysViews={node.last7DaysViews} last30DaysViews={node.last30DaysViews} />
            }
            {
                hasViews && hasUsage && <div className="w-full h-[1px] bg-border"></div>
            }
            {
                hasUsage && <Usage node={node} />
            }
        </div>
    );
};

const Views = ({ last7DaysViews, last30DaysViews }: { last7DaysViews: number | null, last30DaysViews: number | null }) => {
    return (
        <div className="flex gap-4">
            <div className="flex gap-1 items-center">
                <EyeIcon width="14" height="14" className="text-text-primary mr-1" />
                <div className="text-text-primary text-base">{last7DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">7D</div>
            </div>
            <div className="flex gap-1 items-center">
                <div className="text-text-primary text-base">{last30DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">30D</div>
            </div>
        </div>
    );
};

const Usage = ({ node }: { node: IExpandedNode | ISubnode }) => {
    const [showExpandedUsage14Days, setShowExpandedUsage14Days] = useState<boolean>(false);
    const uses = node.usage?.usage14Days || 0;
    const toggleExpand14Days = () => {
        if (!uses) return;
        if (!showExpandedUsage14Days) {
            trackEvent(events.nodeUsageExpanded14Days, { node_type: node.type });
        }
        setShowExpandedUsage14Days(!showExpandedUsage14Days);
    };
    if (!node.usage) return null;
    return (
        <div>
            <div>
                <div className="flex justify-between cursor-pointer" onClick={toggleExpand14Days}>
                    <div className="flex gap-1 items-center">
                        <ChartBarIcon width="14" height="14" className="text-text-primary mr-1" />
                        <div className="text-text-primary text-base">{uses} uses</div>
                        <div className="text-tertiary text-xs mt-0.5">14D</div>
                    </div>
                    {
                        uses ? (
                            <div className="w-fit text-slate-400 bg-slate-100 hover:text-slate-500 p-0.5 border rounded border-border">
                                {
                                    showExpandedUsage14Days ? <ChevronDownIcon width="14" height="14" /> : <ChevronUpIcon width="14" height="14" />
                                }
                            </div>
                        ) : null
                    }
                </div>
                <ExpandedUsage show={showExpandedUsage14Days} sources={node.usage.sources} users={node.usage.users} />
            </div>
        </div>
    );
};

const ExpandedUsage = ({ show, sources, users }: { show: boolean, sources: ISourceNodeUsage[] | undefined, users: IUserNodeUsage[] | undefined }) => {
    const accountId = useSelector(selectActiveAccountId);
    const sourcesToShow = useMemo(() => (sources || []).slice(0, 5), [sources]);
    const getRevision = useGetDamaRevisionQuery({ accountId });
    const [utlToNameMap, setUtlToNameMap] = useState<{ [key: string]: string }>({});
    const sourcesWithTitles = useMemo(() => sourcesToShow.map(s => {
        const utl = s?.utl || '';
        const title = utlToNameMap[utl] || '';
        return { ...s, title };
    }), [sourcesToShow, utlToNameMap]);

    useEffect(() => {
        const utls = sourcesToShow.map(s => s.utl).filter(Boolean);
        if (getRevision.data && utls.length > 0) {
            getNodes({
                applicationId: getRevision.data.algoliaAppId,
                apiKey: getRevision.data.algoliaKey,
                indexName: getRevision.data.algoliaIndex,
                filters: `(${utls.map(utl => `objectID:"${utl}"`).join(' OR ')})`
            }).then(nodes => {
                const utlToNameMap = nodes.items.reduce((acc, node) => ({ ...acc, [node.id]: node.name }), {} as { [key: string]: string });
                setUtlToNameMap(utlToNameMap);
            });
        }
    }, [getRevision, sourcesToShow]);

    const withUsers = (users || []).length > 0;
    const withSources = (sources || []).length > 0;
    if (!show) return null;
    return (
        <div className="flex flex-col justify-between mt-2 gap-2 text-xs">
            {
                withSources && (
                    <div className="flex-1">
                        <div className="text-tertiary text-sm">TOP 5 QUERIED SOURCES</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                sourcesWithTitles.map(source => (
                                    <Source key={`${JSON.stringify(source)}`} source={source} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                withUsers && (
                    <div className="flex-1 border-t border-border pt-2">
                        <div className="text-tertiary text-sm">TOP USERS</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                (users || []).slice(0, 5).map(user => (
                                    <User key={user.name} user={user} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const Source = ({ source }: { source: ISourceNodeUsage & { title?: string } }) => {
    const goToSource = () => {
        if (source.utl) {
            window.location.href = getNodeUrl(source.utl);
        }
    };
    let title = source.title || 'Other';
    let subtitle = sourceTypeMap[source.type] || '';

    if (source.type == NodeUsageAppType.other) {
        subtitle = '';
    } else if (source.type === NodeUsageAppType.explore) {
        title = 'Explore';
        subtitle = '';
    } else if (source.dashboardTitle) {
        subtitle = `${subtitle}: ${source.dashboardTitle}`;
    }
    return (
        <div className="flex gap-1 items-center">
            <div className="bg-surface-light border rounded border-border h-fit">
                <LookerColorlessIcon fill="#94a3b8" width="12" height="12" />
            </div>
            <div className={`text-sm ${source.utl ? 'cursor-pointer text-text-primary' : 'text-slate-400 italic'}`} onClick={goToSource}><TextWithEllipsisAndTooltip text={title || ''} maxChars={30} /></div>
            <div className="text-tertiary"><TextWithEllipsisAndTooltip text={subtitle} maxChars={25} /></div>
            <div className="text-tertiary ml-auto">{source.number}</div>
        </div>
    );
};

const sourceTypeMap = {
    [NodeUsageAppType.look]: 'Look',
    [NodeUsageAppType.dashboard_element]: 'Tile',
    [NodeUsageAppType.api_query]: 'API',
    [NodeUsageAppType.explore]: 'Explore',
    [NodeUsageAppType.other]: 'Other'
};

const User = ({ user }: { user: { name: string, number: number, email: string } }) => {
    return (
        <div className="flex gap-1 items-center">
            <div className="text-sm text-text-primary"><TextWithEllipsisAndTooltip text={user.name || ''} maxChars={35} /></div>
            <div className="text-tertiary">{user.email}</div>
            <div className="text-tertiary ml-auto">{user.number}</div>
        </div>
    );
};
