import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/layout/Layout';
import { ISuperficialNode, ISuperficialSubnode } from './INode';
import { delay } from '../../../utils/timeUtils';
import { DiscoverView } from './types';
import { DiscoverToolbar } from './toolbar/DiscoverToolbar';
import { DiscoverTableView } from './view/table/DiscoverTableView';
import { NodeSidepaneLoader } from './sidepane/NodeSidepane/NodeSidepaneLoader';
import { DiscoverDAGView } from './view/DAG/DiscoverDAGView';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { notify } from '../../../components/Toaster';
import { getNodes } from '../../../services/nodes/nodes';
import { SubNodeSidepaneLoader } from './sidepane/SubnodeSidepane/SubnodeSidepaneLoader';
import { ExpandedNode } from './view/DAG/types';
import { destructUtl } from './destructUtl';
import { mapAlgoliaSubnodeTypeToLocalSubnodeType } from '../../../services/nodes/transformers';
import { events, trackEvent } from '../../../infrastructure/analytics';
import { useGetDamaRevisionQuery } from '../../../services/accounts/accounts';

export const Discover = () => {
  const [selectedNode, setSelectedNode] = useState<ISuperficialNode | null>(null);
  const [nodeSidepane, setNodeSidepane] = useState<ISuperficialNode | null>(null);
  const [subnodeSidepane, setSubnodeSidepane] = useState<{ node: ISuperficialNode, subnode: ISuperficialSubnode } | null>(null);
  const [showNodeSidepane, setShowNodeSidepane] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const eql = filterValues.join(' AND ');
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const getDamaRevisionQuery = useGetDamaRevisionQuery({ accountId });
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNode[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const view = useMemo(() => searchParams.get('view') as DiscoverView || DiscoverView.Table, [searchParams]);
  const tableName = 'data-model-table';

  const setView = useCallback((view: DiscoverView) => {
    searchParams.set('view', view);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);
  
  useEffect(() => {
    if (!searchParams.get('view')) {
      setView(DiscoverView.Table);  
    }
  }, [searchParams, setView]);

  //On node sidepane change
  useEffect(() => {
    if (nodeSidepane) {
      delay(100).then(() => setShowNodeSidepane(true));
      setShowNodeSidepane(false);
    }
    else {
      setShowNodeSidepane(false);
    }
  }, [nodeSidepane, setShowNodeSidepane]);

  //On URL params change
  useEffect(() => {
    //Update node / subnode
    const highlightedUtl = searchParams.get('highlightedNode');
    if (highlightedUtl && getDamaRevisionQuery.data) {
      trackEvent(events.dataModelEnteredNode);
      const { algoliaKey, algoliaAppId, algoliaIndex } = getDamaRevisionQuery.data;
      const { nodeId, subnodeName, subnodeType } = destructUtl(highlightedUtl);
      getNodes({ query: '', filters: `objectID:"${nodeId}"`, apiKey: algoliaKey, applicationId: algoliaAppId, indexName: algoliaIndex, pageSize: 1 })
        .then(({ items }) => {
          const node = items[0];
          if (node) {
            searchParams.set('UTL', node.id);
            const subnode = subnodeType && node.subnodes.find(sn => sn.name === subnodeName && sn.type === mapAlgoliaSubnodeTypeToLocalSubnodeType.get(subnodeType));
            if (subnode) {
              setSubnodeSidepane({ node, subnode });
            }
            else {
              setNodeSidepane(node);
            }
          }
          else {
            notify('Resource not found', 'error');
          }
        })
        .catch(e => {
          console.error(e);
          notify('Failed to resource node', 'error');
        })
        .finally(() => {
          searchParams.delete('highlightedNode');
          setSearchParams(searchParams, { replace: true });
        });
    }
  }, [navigate, getDamaRevisionQuery, searchParams, view, setSearchParams]);

  return (
    <Layout>
      <DiscoverToolbar
        setSelectedSubNode={(parentNode, subnode) => { setSelectedNode(parentNode); setSubnodeSidepane({ node: parentNode, subnode }); }}
        setSelectedNode={node => { setSelectedNode(node); setNodeSidepane(node); }}
        view={view}
        setView={setView}
        setFilterValues={setFilterValues}
        expandedNodes={expandedNodes}
        setExpandedNodes={setExpandedNodes}
        tableName={tableName}
      />
      {
        view === DiscoverView.Graph && (
          <DiscoverDAGView
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            eql={eql}
            setExpandedNodes={setExpandedNodes}
            expandedNodes={expandedNodes}
          />
        )
      }
      {
        view === DiscoverView.Table && (
          <DiscoverTableView
            setSelectedNode={setSelectedNode}
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            eql={eql}
            tableName={tableName}
          />
        )
      }
      <NodeSidepaneLoader
        nodeId={nodeSidepane?.id || ''}
        isOpen={showNodeSidepane}
        onClose={() => setNodeSidepane(null)}
      />
      <SubNodeSidepaneLoader
        nodeId={subnodeSidepane?.node?.id || null}
        subnode={subnodeSidepane?.subnode || null}
        isOpen={subnodeSidepane !== null}
        onClose={() => setSubnodeSidepane(null)}
      />
    </Layout>
  );
};
