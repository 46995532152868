import { mapLocalSubnodeTypeToAlgoliaSubnodeType } from "../../../services/nodes/transformers";
import { SubnodeType } from "./INode";

export const getNodeUrl = (nodeId: string, subnodeType?: SubnodeType, subnodeName?: string) => {
    const parameters = [nodeId];
    if (subnodeType && subnodeName) {
        const backendSubnodeType = mapLocalSubnodeTypeToAlgoliaSubnodeType.get(subnodeType) || '';
        parameters.push(backendSubnodeType, subnodeName);
    }
    return `/data-model?view=graph&highlightedNode=${parameters.join('.')}`;
};