export enum CodeViewerLanguages {
  SQL = 'SQL',
  Python = 'Python',
  YAML = 'YAML',
  PlainText = 'Plain Text'
}

export type CodeViewerProps = {
    code: string;
    fileName?: string;
    collapsable?: boolean;
    className?: string;
    language?: CodeViewerLanguages;
}
