import { GithubIcon, GitlabIcon } from '../../../assets/images/icons/DelphiIcons';

export type ProjectRepositoryOption = {
    name: ProjectRepositoryOptionType;
    displayName: string;
    icon: JSX.Element;
}


export enum ProjectRepositoryOptionType {
    GITHUB = 'github',
    GITLAB = 'gitlab'
}

export const projectRepositoryOptions: ProjectRepositoryOption[] = [
    {
        name: ProjectRepositoryOptionType.GITHUB,
        displayName: 'GitHub',
        icon: <GithubIcon width="16" height="16" fill="#94A3B8" />,
    },
    {
        name: ProjectRepositoryOptionType.GITLAB,
        displayName: 'GitLab',
        icon: <GitlabIcon width="16" height="16" fill="#94A3B8" />,
    }
];