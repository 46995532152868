import {
    IExpandedNode,
    IMeta,
    INodeUsage,
    ISourceNodeUsage,
    ISuperficialNode,
    IUserNodeUsage,
    MetricDependency,
    NodeType,
    SubnodeType,
    Swimlane
} from '../../features/models/discover/INode';
import {
    AlgoliaNode,
    BackendSubnodeType,
    BackendExpandedNodeResponse,
    BackendSuperficialNode,
    BackendNodeType,
    ExternalNodeUsage,
    UsageAppBreakdown,
    UsageUserBreakdown,
    MetricDependenciesResponse,
    BackendExpandedSubnode
} from './types';

export const transformAlgoliaNodeToLocalNode = (algoliaNode: AlgoliaNode): ISuperficialNode | null => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(algoliaNode.type);
    if (!nodeType) {
        console.error(`Unknown Algolia node type: ${algoliaNode.type}`);
        return null;
    }
    const node: ISuperficialNode = {
        ...algoliaNode,
        id: algoliaNode.utl || algoliaNode.uri || '',
        parents: algoliaNode.parents || [],
        type: nodeType,
        generatedByDelphi: algoliaNode.generated_by_delphi === true || `${algoliaNode.generated_by_delphi}` === 'true',
        name: algoliaNode.name,
        description: algoliaNode.description,
        tags: algoliaNode.tags,
        meta: transformAlgoliaMetaToMeta(algoliaNode.meta || []),
        tableauConnection: algoliaNode.tableau_connection,
        tableauWorkbook: algoliaNode.tableau_workbook || '',
        tableauProject: algoliaNode.tableau_project || '',
        dbtMaterializationStrategy: algoliaNode.dbt_materialization_strategy,
        database: algoliaNode.database,
        databaseSchema: algoliaNode.schema || '',
        repo: algoliaNode.git_repo_url,
        branch: algoliaNode.git_repo_branch,
        package: algoliaNode.package_name,
        identifier: algoliaNode.unique_id,
        parentName: algoliaNode.parent_name,
        numberOfDimensions: algoliaNode.number_of_dimensions,
        numberOfMeasures: algoliaNode.number_of_measures,
        numberOfEntities: algoliaNode.number_of_entities,
        numberOfMetrics: algoliaNode.number_of_metrics,
        numberOfCustomFields: algoliaNode.number_of_custom_fields,
        swimlane: getSwimlaneForNodeType(nodeType),
        numberOfColumns: algoliaNode.number_of_columns,
        hasProposals: algoliaNode.has_shift_left_potential || false,
        isTrivialSql: algoliaNode.is_trivial_sql || false,
        owner: algoliaNode.owner,
        subnodes: (algoliaNode.subnodes_highlights || []).map((subnode) => ({
            name: subnode.name,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.type) || SubnodeType.Column
        })),
        firstSeen: algoliaNode.first_seen_at,
        dbtProject: algoliaNode.dbt_project || null,
        lookerFolder: algoliaNode.looker_folder || null,
        lookerModel: algoliaNode.looker_model || null,
        sourceDirectory: algoliaNode.source_directory || null,
        lookerHost: algoliaNode.looker_host || null,
        totalViews7Days: algoliaNode.last_7d_views,
        totalViews30Days: algoliaNode.last_30d_views,
        totalQueries14Days: algoliaNode.total_queries_14d,
        databaseTechnology: algoliaNode.database_technology || null,
        totalQueries30Days: algoliaNode.total_queries_30d,
        totalQueries60Days: algoliaNode.total_queries_60d,
        distinctUsers14Days: algoliaNode.distinct_users_14d,
        distinctUsers30Days: algoliaNode.distinct_users_30d,
        distinctUsers60Days: algoliaNode.distinct_users_60d,
    };
    return node;
};

export const mapBackendNodeTypeToLocalNodeType = new Map<BackendNodeType, NodeType>([
    [BackendNodeType.DBTModel, NodeType.DataModel],
    [BackendNodeType.DBTMetric, NodeType.Metric],
    [BackendNodeType.DataSource, NodeType.DataSource],
    [BackendNodeType.LookerView, NodeType.LookerView],
    [BackendNodeType.LookerDerivedView, NodeType.LookerDerivedView],
    [BackendNodeType.LookerExplore, NodeType.LookerExplore],
    [BackendNodeType.LookerLook, NodeType.LookerLook],
    [BackendNodeType.GenericDataTransformation, NodeType.GenericDataTransformation],
    [BackendNodeType.LookerDashboard, NodeType.LookerDashboard],
    [BackendNodeType.LookerTile, NodeType.LookerTile],
    [BackendNodeType.TableauWorkbook, NodeType.TableauWorkbook],
    [BackendNodeType.TableauView, NodeType.TableauView],
    [BackendNodeType.TableauCustomQuery, NodeType.TableauCustomQuery],
    [BackendNodeType.TableauPublishedDataSource, NodeType.TableauPublishedDataSource],
    [BackendNodeType.TableauEmbeddedDataSource, NodeType.TableauEmbeddedDataSource],
    [BackendNodeType.TableauDashboard, NodeType.TableauDashboard],
    [BackendNodeType.TableauStory, NodeType.TableauStory],
    [BackendNodeType.Table, NodeType.Table]
]);

export const mapBackendSubnodeTypeToLocalSubnodeType = new Map<BackendSubnodeType, SubnodeType>([
    [BackendSubnodeType.Dimension, SubnodeType.Dimension],
    [BackendSubnodeType.Measure, SubnodeType.Measure],
    [BackendSubnodeType.Entity, SubnodeType.Entity],
    [BackendSubnodeType.Metric, SubnodeType.Metric],
    [BackendSubnodeType.CustomField, SubnodeType.CustomField],
    [BackendSubnodeType.Column, SubnodeType.Column],
    [BackendSubnodeType.TableCalculation, SubnodeType.TableCalculation],
    [BackendSubnodeType.LookerConnectedView, SubnodeType.LookerConnectedView],
    [BackendSubnodeType.LookerLook, SubnodeType.LookerLook],
    [BackendSubnodeType.LookerTile, SubnodeType.LookerTile],
    [BackendSubnodeType.TableauConnectedView, SubnodeType.TableauConnectedView],
]);

export const mapLocalSubnodeTypeToBackendSubnodeType = new Map<SubnodeType, BackendSubnodeType>([
    [SubnodeType.Dimension, BackendSubnodeType.Dimension],
    [SubnodeType.Measure, BackendSubnodeType.Measure],
    [SubnodeType.Entity, BackendSubnodeType.Entity],
    [SubnodeType.Metric, BackendSubnodeType.Metric],
    [SubnodeType.CustomField, BackendSubnodeType.CustomField],
    [SubnodeType.Column, BackendSubnodeType.Column],
    [SubnodeType.TableCalculation, BackendSubnodeType.TableCalculation],
    [SubnodeType.LookerConnectedView, BackendSubnodeType.LookerConnectedView],
    [SubnodeType.LookerLook, BackendSubnodeType.LookerLook],
    [SubnodeType.LookerTile, BackendSubnodeType.LookerTile]
]);

export const mapNodeTypeToBackendNodeType = new Map<NodeType, BackendNodeType>([
    [NodeType.DataModel, BackendNodeType.DBTModel],
    [NodeType.DataSource, BackendNodeType.DataSource],
    [NodeType.LookerView, BackendNodeType.LookerView],
    [NodeType.LookerDerivedView, BackendNodeType.LookerDerivedView],
    [NodeType.LookerExplore, BackendNodeType.LookerExplore],
    [NodeType.LookerLook, BackendNodeType.LookerLook],
    [NodeType.GenericDataTransformation, BackendNodeType.GenericDataTransformation],
    [NodeType.LookerDashboard, BackendNodeType.LookerDashboard],
    [NodeType.LookerTile, BackendNodeType.LookerTile],
    [NodeType.TableauWorkbook, BackendNodeType.TableauWorkbook],
    [NodeType.TableauView, BackendNodeType.TableauView]
]);

export const mapAlgoliaSubnodeTypeToLocalSubnodeType = new Map<BackendSubnodeType, SubnodeType>([
    [BackendSubnodeType.Dimension, SubnodeType.Dimension],
    [BackendSubnodeType.Measure, SubnodeType.Measure],
    [BackendSubnodeType.Entity, SubnodeType.Entity],
    [BackendSubnodeType.Metric, SubnodeType.Metric],
    [BackendSubnodeType.CustomField, SubnodeType.CustomField],
    [BackendSubnodeType.Column, SubnodeType.Column],
    [BackendSubnodeType.TableCalculation, SubnodeType.TableCalculation],
    [BackendSubnodeType.LookerConnectedView, SubnodeType.LookerConnectedView],
    [BackendSubnodeType.LookerLook, SubnodeType.LookerLook],
    [BackendSubnodeType.LookerTile, SubnodeType.LookerTile]
]);

export const mapLocalSubnodeTypeToAlgoliaSubnodeType = new Map<SubnodeType, BackendSubnodeType>([
    [SubnodeType.Dimension, BackendSubnodeType.Dimension],
    [SubnodeType.Measure, BackendSubnodeType.Measure],
    [SubnodeType.Entity, BackendSubnodeType.Entity],
    [SubnodeType.Metric, BackendSubnodeType.Metric],
    [SubnodeType.CustomField, BackendSubnodeType.CustomField],
    [SubnodeType.Column, BackendSubnodeType.Column],
    [SubnodeType.TableCalculation, BackendSubnodeType.TableCalculation],
    [SubnodeType.LookerConnectedView, BackendSubnodeType.LookerConnectedView],
    [SubnodeType.LookerLook, BackendSubnodeType.LookerLook],
    [SubnodeType.LookerTile, BackendSubnodeType.LookerTile]
]);

const getSwimlaneForNodeType = (nodeType: NodeType): Swimlane => {
    const mapNodeTypeToSwimlane = new Map<NodeType, Swimlane>([
        [NodeType.DataModel, Swimlane.transformations_and_metrics],
        [NodeType.Metric, Swimlane.transformations_and_metrics],
        [NodeType.DataSource, Swimlane.sources],
        [NodeType.LookerView, Swimlane.appModeling],
        [NodeType.LookerDerivedView, Swimlane.appModeling],
        [NodeType.LookerExplore, Swimlane.appModeling],
        [NodeType.LookerLook, Swimlane.application],
        [NodeType.LookerTile, Swimlane.application],
        [NodeType.LookerDashboard, Swimlane.application],
        [NodeType.GenericDataTransformation, Swimlane.transformations_and_metrics],
        [NodeType.TableauWorkbook, Swimlane.application],
        [NodeType.TableauView, Swimlane.application],
        [NodeType.TableauCustomQuery, Swimlane.appModeling],
        [NodeType.TableauPublishedDataSource, Swimlane.appModeling],
        [NodeType.TableauEmbeddedDataSource, Swimlane.appModeling],
        [NodeType.TableauDashboard, Swimlane.application],
        [NodeType.TableauStory, Swimlane.application],
        [NodeType.Table, Swimlane.transformations_and_metrics]
    ]);
    const swimlane = mapNodeTypeToSwimlane.get(nodeType);
    if (!swimlane) {
        throw new Error(`Unknown node type: ${nodeType}`);
    }
    return swimlane;
};

const transformAlgoliaMetaToMeta = (algoliaMeta: string[]): IMeta => {
    if (typeof algoliaMeta === 'object' && !Array.isArray(algoliaMeta)) {
        return algoliaMeta;
    }
    const meta: IMeta = {};
    for (const metaString of algoliaMeta) {
        const [key, value] = metaString.split('=');
        meta[key] = value;
    }
    return meta;
};

export const transformBackendExpandedNodeToLocalExpandedNode = (node: BackendExpandedNodeResponse): IExpandedNode => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(node.type);
    const rawCode = node.table_properties?.view_native_code?.code || node.raw_code || '';
    if (!nodeType) {
        throw new Error(`Unknown Backend node type: ${node.type}`);
    }
    return {
        subnodes: [...buildSubondes(node), ...(node.subnodes || [])].map((subnode) => ({
            name: subnode.name,
            description: subnode.description,
            tags: subnode.tags || [],
            meta: subnode.meta || {},
            parentName: subnode.parent_name,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.subnode_type) || SubnodeType.Column,
            withCode: subnode.source_code || subnode.compiled_code ? true : false,
            subType: subnode.type,
            rawCode: subnode.source_code || '',
            compiledCode: subnode.compiled_code || '',
            semanticCode: '',
            url: null,
            id: subnode.uri || '',
            usage: subnode.looker_queries ? transformUsageFromExternalToInternal(subnode.looker_queries) : null,
            last30DaysViews: subnode.last_30d_views || null,
            last7DaysViews: subnode.last_7d_views || null,
            alias: subnode.alias || null,
            typeSpecificInfo: buildSubnodeTypeSpecificInfo(subnode),
            hasProposals: subnode.has_shift_left_potential || false,
            isTrivialSql: subnode.is_trivial_sql || false,
            aggType: subnode.agg || null,
            inputMeasures: subnode.type_params?.input_measures?.map(im => im.name) || [],
            isCalculated: subnode.is_calculated || null
        })),
        lastAccessedAt: node.last_accessed_at,
        favouriteCount: node.favourite_count,
        url: node.url,
        lastUpdatedAt: node.last_observed ? new Date(node.last_observed).toISOString() : undefined,
        lastUpdatedBy: node.native_updated_by?.email || node.updated_by || null,
        createdAt: node.created_at,
        createdBy: node.native_created_by?.email || node.created_by || null,
        id: node.utl || node.uri || '',
        name: node.name,
        type: nodeType,
        subType: nodeType === NodeType.Metric && node.metric_type ? node.metric_type : null,
        description: node.description,
        tags: node.tags || [],
        meta: node.meta || {},
        generatedByDelphi: node.generated_by_delphi === true || `${node.generated_by_delphi}` === 'true',
        materialization: node.materialized,
        tableauConnection: node.tableau_connection,
        last30DaysViews: node.last_30d_views || null,
        last7DaysViews: node.last_7d_views || null,
        package: node.package_name,
        database: node.database,
        databaseSchema: node.database_schema,
        identifier: '',
        parentName: node.parent_name,
        repo: node.git_repo_url,
        branch: node.git_repo_branch,
        rawCode,
        compiledCode: node.compiled_code,
        semanticCode: node.semantic_code,
        userAllowedToPromote: node.allow_promote,
        withCode: node.compiled_code || rawCode || node.semantic_code ? true : false,
        sourceDirectory: node.source_directory || '',
        lookerFolder: node.looker_folder || '',
        lookerModel: node.looker_model || '',
        lookerProject: node.looker_project || '',
        hasProposals: node.has_shift_left_potential || false,
        isTrivialSql: node.is_trivial_sql || false,
        usage: transformUsageFromExternalToInternal(node.looker_queries || node.tableau_queries_summary || null),
        dbtProjectName: node.dbt_project,
        eunoProjectId: node.euno_project_id || null,
        typeSpecificInfo: buildNodeTypeSpecificInfo(node),
        containedNodes:
            node.contained_resources?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || NodeType.GenericDataTransformation
            })) || [],
        chainedNodes:
            node.container_chain?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || NodeType.GenericDataTransformation
            })) || [],
        databaseTechnology: node.database_technology || null,
    };
};

const buildSubondes = (node: BackendExpandedNodeResponse): BackendExpandedSubnode[] => {
    const emptySubnode = {
        compiled_code: null,
        uri: null,
        looker_queries: null,
        last_30d_views: null,
        last_7d_views: null,
        filter: null,
        alias: null,
        has_shift_left_potential: false,
        is_trivial_sql: false,
    };
    const columns =
        node.table_schema?.columns?.map((column) => ({
            name: column.name,
            description: column.description,
            type: column.normalized_data_type,
            subnode_type: column.semantic_role,
            tags: column.native_tags,
            meta: column.native_meta,
            parent_name: node.name,
            source_code: column.native_code?.code || null,
            is_calculated: column.native_tags?.includes('CALCULATEDFIELD'),
            ...emptySubnode,
        })) || [];
    return [...columns];
};

const buildSubnodeTypeSpecificInfo = (subnode: BackendExpandedSubnode) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (subnode.alias) {
        typeSpecificInfo.alias = subnode.alias;
    }
    if (subnode.type) {
        typeSpecificInfo.type = subnode.type;
    }
    return typeSpecificInfo;
};

const buildNodeTypeSpecificInfo = (node: BackendExpandedNodeResponse) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (node.owner) {
        typeSpecificInfo['Owner'] = node.owner;
    }
    if (node.tableau_workbook && node.type !== BackendNodeType.TableauWorkbook) {
        typeSpecificInfo['Workbook'] = node.parent_name;
    }
    if (node.metric_type) {
        typeSpecificInfo['type'] = node.metric_type;
    }

    if (Array.isArray(node.container_chain) && node.container_chain.length > 0) {
        typeSpecificInfo['Browse path'] = node.container_chain.map(({ name }) => name).join('/');
    }
    if (typeof node.table_properties?.materialized === 'boolean') {
        typeSpecificInfo['Materialization type'] = node.table_properties.materialized ? 'table' : 'view';
    }
    return typeSpecificInfo;
};

export const transformUsageFromExternalToInternal = (usage: ExternalNodeUsage | null): INodeUsage | null => {
    if (!usage) {
        return null;
    }
    return {
        usage14Days: usage.total_queries_14d,
        usage30Days: usage.total_queries_30d,
        usage60Days: usage.total_queries_60d,
        sources: map_sources(usage.breakdown_by_app || []),
        users: map_users(usage.breakdown_by_user || []),
        sources30Days: map_sources(usage.breakdown_by_app_30d || []),
        users30Days: map_users(usage.breakdown_by_user_30d || []),
        sources60Days: map_sources(usage.breakdown_by_app_60d || []),
        users60Days: map_users(usage.breakdown_by_user_60d || [])
    };

    function map_sources(breakdown_by_app: UsageAppBreakdown[]): ISourceNodeUsage[] {
        return (breakdown_by_app || []).map((app) => ({
            number: app.cnt || 0,
            utl: app.app.utl,
            dashboardTitle: app.app.dashboard_element_id || '',
            type: app.app.type
        }));
    }

    function map_users(breakdown_by_user: UsageUserBreakdown[]): IUserNodeUsage[] {
        return (breakdown_by_user || []).map((user) => ({
            name: user.user.user_name,
            number: user.cnt,
            email: user.user.user_email
        }));
    }
};

export const transformBackendSuperficialNodeToLocalSuperficialNode = (
    nodes: BackendSuperficialNode[]
): ISuperficialNode[] => {
    const superficialNodes = nodes.map((node) => {
        const nodeType = mapBackendNodeTypeToLocalNodeType.get(node.type);
        if (!nodeType) {
            console.error(`Unknown Algolia node type: ${node.type}`);
            return null;
        }
        return {
            id: node.utl || node.uri || '',
            name: node.name,
            swimlane: getSwimlaneForNodeType(nodeType),
            parents: node.parents || [],
            type: nodeType,
            description: '',
            tags: [],
            meta: {},
            generatedByDelphi: node.generated_by_delphi === true || `${node.generated_by_delphi}` === 'true',
            materialization: '',
            database: '',
            databaseSchema: '',
            repo: '',
            branch: '',
            package: '',
            identifier: '',
            parentName: '',
            numberOfDimensions: 0,
            numberOfMeasures: 0,
            numberOfEntities: 0,
            numberOfMetrics: 0,
            numberOfCustomFields: 0,
            hasProposals: false,
            isTrivialSql: false,
            subnodes: [],
            owner: null,
            firstSeen: 0,
            dbtProject: null,
            lookerFolder: null,
            lookerModel: null,
            sourceDirectory: null,
            lookerHost: null,
            totalViews7Days: null,
            totalViews30Days: null,
            totalQueries14Days: null,
            databaseTechnology: node.database_technology || null,
            totalQueries30Days: null,
            totalQueries60Days: null,
            distinctUsers14Days: null,
            distinctUsers30Days: null,
            distinctUsers60Days: null,
        };
    });
    return superficialNodes.filter((n) => n !== null) as ISuperficialNode[];
};

export const transformMetricDependenciesResponseToLocal = (
    response: MetricDependenciesResponse
): MetricDependency[] => {
    const metricDependencies: MetricDependency[] = [];
    for (const entity of response) {
        const existingEntity = metricDependencies.find((e) => e.entityName === entity.entity_name);
        const dependencies = entity.dimensions.map((d) => ({ name: d.name, type: d.type, description: '' }));
        if (existingEntity) {
            if (entity.entity_path) {
                existingEntity.entityPaths.push(entity.entity_path);
            }
            existingEntity.dimensions.push(
                ...dependencies.filter((d) => !existingEntity.dimensions.find((e) => e.name === d.name))
            );
        } else {
            metricDependencies.push({
                entityName: entity.entity_name,
                entityPaths: entity.entity_path ? [entity.entity_path] : [],
                dimensions: dependencies,
                entityDescription: ''
            });
        }
    }
    return metricDependencies;
};
