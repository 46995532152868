import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { useMemo, useCallback } from "react";
import { MetricDependency } from "../../../../../models/discover/INode";
import { AggregateTableTimeGranularity, NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../../types";
import { generateAggregateTableColumn } from "../../utilities/generateAggregateTableColumn";
import Select, { Option } from "../../../../../../components/form/Select";

type AggregateTableGroupDimensionsProps = {
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
    metricDependencies: MetricDependency[];
    isLoading: boolean;
}

export const AggregateTableGroupDimensions = ({ changeData, setChangeData, metricDependencies, isLoading }: AggregateTableGroupDimensionsProps) => {
    const columns = useMemo(() => changeData.columns, [changeData]);
    const dimensions = useMemo(() => columns.filter(c => c.type === NewAggregateTableChangeColumnType.dimension), [columns]);
    const addEmptyDimension = useCallback(() => setChangeData({ ...changeData, columns: [...columns, generateAggregateTableColumn({ type: NewAggregateTableChangeColumnType.dimension })] }), [columns, setChangeData, changeData]);
    const setColumns = useCallback((columns: NewAggregateTableChangeColumn[]) => setChangeData({ ...changeData, columns }), [changeData, setChangeData]);

    return (
        <div className="flex flex-col gap-2 flex-1">
            {
                dimensions.map((dimension, index) => (
                    <Dimension
                        key={index}
                        dimension={dimension}
                        columns={columns}
                        metricDependencies={metricDependencies}
                        setColumns={setColumns}
                        isLoading={isLoading}
                    />
                ))
            }
            <div className="flex items-center -ml-0.5 flex gap-2 cursor-pointer text-slate-400 hover:text-slate-500" onClick={addEmptyDimension}>
                <PlusCircleIcon width={16} height={16} />
                Add dimension (optional)
            </div>
        </div>
    );
};

type DimensionProps = {
    dimension: NewAggregateTableChangeColumn;
    columns: NewAggregateTableChangeColumn[];
    metricDependencies: MetricDependency[];
    setColumns: (columns: NewAggregateTableChangeColumn[]) => void;
    isLoading: boolean;
}

const Dimension = ({ dimension, columns, metricDependencies, setColumns, isLoading }: DimensionProps) => {
    const removeDimension = () => setColumns([...columns.filter(c => c !== dimension)]);
    const allDimension = metricDependencies.flatMap(e => e.dimensions);
    const dimensionOptions = useMemo(() => metricDependencies.flatMap(e => e.dimensions.map(d => ({ value: d.name, label: d.name }))), [metricDependencies]);
    const entites = useMemo(() => metricDependencies.filter(e => e.dimensions.some(d => d.name === dimension.name)), [metricDependencies, dimension]);
    const entityOptions = useMemo(() => entites.map(e => ({ label: e.entityName, value: e.entityName })), [entites]);
    const entityPaths = useMemo(() => metricDependencies.find(md => md.entityName === dimension.parentEntity)?.entityPaths || [], [metricDependencies, dimension]);
    
    const selectDimension = (name: string) => {
        const matchedDimension = allDimension.find(d => d.name === name);
        if (!matchedDimension) {
            throw new Error('Dimension not found in related dimensions list.');
        }
        const matchedEntity = metricDependencies.find(e => e.dimensions.some(d => d.name === name));
        if (!matchedEntity) {
            throw new Error('Entity not found in related entities list.');
        }
        const newColumns = [...columns];
        newColumns[newColumns.indexOf(dimension)] = generateAggregateTableColumn({
            name,
            description: matchedDimension.description,
            type: NewAggregateTableChangeColumnType.dimension,
            isTimeField: matchedDimension.type === 'time',
            timeGranularity: matchedDimension.type === 'time' ? AggregateTableTimeGranularity.day : null,
            parentEntity: matchedEntity.entityName,
            entityPath: matchedEntity.entityPaths[0] || null
        });
        setColumns(newColumns);
    };

    const selectTimeGranularity = (option: Option) => {
        const newColumns = [...columns];
        const newDimension = { ...dimension, timeGranularity: option.value as AggregateTableTimeGranularity };
        newColumns[newColumns.indexOf(dimension)] = newDimension;
        setColumns(newColumns);
    };

    const selectEntity = (name: string) => {
        const matchedEntity = entites.find(e => e.entityName === name);
        if (!matchedEntity) {
            throw new Error('Entity not found in related entities list.');
        }
        const newColumns = [...columns];
        newColumns[newColumns.indexOf(dimension)] = generateAggregateTableColumn({ ...dimension, parentEntity: name, entityPath: matchedEntity.entityPaths[0] || null});
        setColumns(newColumns);
    };

    const selectPath = (path: string) => {
        const newColumns = [...columns];
        newColumns[newColumns.indexOf(dimension)] = generateAggregateTableColumn({ ...dimension, entityPath: path});
        setColumns(newColumns);
    };

    return (
        <div className="flex gap-2 w-full items-center">
            <div className="rounded shadow border p-2 bg-white flex-1">
                <div className="flex gap-1">
                    <Select
                        placeholder="Select dimension"
                        className="text-primary flex-1 border shadow-sm rounded"
                        height="25px"
                        options={dimensionOptions}
                        value={dimension.name}
                        onChange={option => selectDimension((option as Option).value.toString())}
                        isLoading={isLoading}
                        dataTestId="dimension-select"
                    />
                    <Select
                        className="w-36 border rounded shadow-sm text-primary"
                        height="25px"
                        placeholder="N/A"
                        options={Object.values(AggregateTableTimeGranularity).map(g => ({ label: g, value: g }))}
                        value={dimension.timeGranularity || 'N/A'}
                        onChange={option => selectTimeGranularity(option as Option)}
                        isDisabled={!dimension.isTimeField}
                    />
                </div>
                {
                    dimension.name && (
                        <div className="flex gap-1 mt-2">
                            <Select
                                className="text-primary flex-1 border shadow-sm rounded"
                                height="25px"
                                options={entityOptions}
                                value={dimension.parentEntity}
                                onChange={option => selectEntity((option as Option).value.toString())}
                                isLoading={isLoading}
                            />
                            {
                                entityPaths.length > 0 && (
                                    <Select
                                        className="w-36 text-primary border shadow-sm rounded"
                                        height="25px"
                                        placeholder="N/A"
                                        options={entityPaths.map(p => ({ label: p, value: p }))}
                                        value={dimension.entityPath || 'N/A'}
                                        onChange={option => selectPath((option as Option).value.toString())}
                                        isLoading={isLoading}
                                        isDisabled={entityPaths.length === 1}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </div>
            <MinusCircleIcon
                height="16"
                width="16"
                className="text-slate-400 cursor-pointer hover:text-slate-500"
                onClick={removeDimension}
            />
        </div>
    );
};
