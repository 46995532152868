type PageTabsProps = {
    tabs: {
        name: string;
        isActive: boolean;
        onClick: () => void;
    }[]
}

export const PageTabs = ({ tabs }: PageTabsProps) => {
    return (
        <div className="w-fit mx-auto flex cursor-pointer text-text-primary gap-1">
            {
                tabs.map((tab) => (
                    <div key={tab.name} data-test-id={`${tab.name}-tab`} className={`rounded-md py-1 px-2 capitalize ${tab.isActive ? 'bg-muse-100' : 'hover:bg-muse-50'}`} onClick={tab.onClick}>{tab.name}</div>
                ))
            }
        </div>
    );
};