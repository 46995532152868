import { TagsForm } from '../../../formSections/TagsForm';
import { MetaForm } from '../../../formSections/MetaForm';
import { DescriptionForm } from '../../../formSections/DescriptionForm';
import { NameForm } from '../../../formSections/NameForm';
import { useState } from 'react';
import { NewAggregateTableSubnodeTab } from './NewAggregateTableSubnodeTab';
import { NewAggregateTableMaterializationForm } from './AggregateTableMaterializationForm';
import { NewAggregateTableChangeData } from '../types';
import { NewAggregateTableSchemaForm } from './AggregateTableSchemaForm';
import { AggregateTableGroupForm } from './AggregateTableGroupForm/AggregateTableGroupForm';
import { AggregateTableMetricsForm } from './AggregateTableMetricsForm';
import Textarea from '../../../../../components/form/Textarea';
import { AggregateTableTimeframeForm } from './AggregateTableTimeframeForm';
import { AggregateTableMetricTimeForm } from './AggregateTableMetricTimeForm';

type NewAggregateTableFormProps = {
  changeData: NewAggregateTableChangeData;
  setChangeData: (changeData: NewAggregateTableChangeData) => void;
  setCompilationError: (error: string) => void;
  proposal: {
    sourceName: string;
    sourceUtl: string;
    projectId: number;
  };
}

type Tab = 'Model' | 'Columns' | 'Semantics';

export const NewAggregateTableForm = ({ changeData, setChangeData, proposal, setCompilationError }: NewAggregateTableFormProps) => {
  const [tab, setTab] = useState<Tab>('Model');
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <div className="text-secondary text-sm font-medium w-28">Metrics</div>
        <AggregateTableMetricsForm columns={changeData.columns} dbtProjectName={changeData.dbtProjectName} setColumns={columns => setChangeData({ ...changeData, columns })} />
      </div>
      <div className="flex items-center gap-2">
        <div className="text-secondary text-sm font-medium w-28">Filters</div>
        <Textarea
          value={changeData.filters}
          onChange={e => setChangeData({ ...changeData, filters: e.target.value })}
          placeholder={`Add filter (e.g. "{{ Dimension('user__ds', 'DAY') }} <= now()")`}
          className="w-full rounded border shadow"
        />
      </div>
      <div className="flex gap-2">
        <div className="text-secondary text-sm font-medium w-28 mt-2">Metric time</div>
        <AggregateTableMetricTimeForm changeData={changeData} setChangeData={changeData => setChangeData(changeData)} />
      </div>
      <div className="flex flex-col">
        <div className="text-secondary text-sm font-medium w-28 mt-2">Grouping by</div>
        <div className="ml-2">
          <AggregateTableGroupForm projectId={proposal.projectId} changeData={changeData} setChangeData={changeData => setChangeData(changeData)} setCompilationError={setCompilationError} />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="text-secondary text-sm font-medium w-28 mt-2">Timeframe</div>
        <AggregateTableTimeframeForm changeData={changeData} setChangeData={changeData => setChangeData(changeData)} />
      </div>
      <div className="mt-4 rounded bg-slate-100 border border-slate-200 flex-1 flex flex-col">
        <Tabs tab={tab} setTab={setTab} />
        {tab === 'Model' && <ModelTab proposal={proposal} changeData={changeData} setChangeData={setChangeData} />}
        {tab === 'Columns' && <NewAggregateTableSubnodeTab changeData={changeData} setChangeData={setChangeData} />}
      </div>
    </div>
  );
};

const Tabs = ({ tab, setTab }: { tab: Tab, setTab: (tab: Tab) => void }) => {
  return (
    <div className="mt-2 rounded bg-slate-200 flex mx-auto w-fit border text-secondary text-sm mb-4">
      {
        ['Model', 'Columns'].map((tabName) => (
          <div
            key={tabName}
            onClick={() => setTab(tabName as 'Model' | 'Columns')}
            className={`flex-1 py-1 px-2 text-center cursor-pointer rounded ${tab === tabName ? 'bg-white' : ''}`}
          >
            {tabName}
          </div>
        ))
      }
    </div>
  );
};

type ModelTabProps = {
  changeData: NewAggregateTableChangeData;
  setChangeData: (changeData: NewAggregateTableChangeData) => void;
  proposal: {
    sourceName: string;
    sourceUtl: string;
    projectId: number;
  };
}

const ModelTab = ({ changeData, setChangeData, proposal }: ModelTabProps) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex items-center">
        <NameForm labelWidth='w-40' label="Model" name={changeData.newTableName} setName={newTableName => setChangeData({ ...changeData, newTableName })} />
      </div>
      <DescriptionForm labelWidth='w-40' label="Model" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm labelWidth='w-40' label="Model" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm labelWidth='w-40' label="Model" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
      <NewAggregateTableMaterializationForm labelWidth='w-40' materialization={changeData.materialization} columns={changeData.columns} setMaterialization={materialization => setChangeData({ ...changeData, materialization })} />
      <NewAggregateTableSchemaForm dbtProject={changeData.dbtProjectName} sourceName={proposal.sourceName} schema={changeData.databaseSchema} setSchema={databaseSchema => setChangeData({ ...changeData, databaseSchema })} />
    </div>
  );
};