import { useState, useEffect } from 'react';
import DropdownMenu from '../../components/DropdownMenu';
import { PencilIcon, CircleStackIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { notify } from '../../components/Toaster';
import Modal from '../../components/Modal/Modal';
import { ButtonTypes } from '../../components/button/types';
import { extractErrorMessage } from '../../services/api';
import Input from '../../components/form/Input';
import { IProject } from './IProject';
import { useRenameProjectMutation } from '../../services/projects/projects';

const ProjectMenu = ({ project }: { project: IProject }) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [newName, setNewName] = useState('');
  const [renameErrorMessage, setRenameErrorMessage] = useState('');
  const [rename, { isLoading: isLoadingRename }] = useRenameProjectMutation();

  useEffect(() => {
    if (project) {
      setNewName(project.name);
    }
  }, [project]);

  const menuOptions = [
    {
      name: 'Rename project',
      className: 'hover:bg-slate-50',
      icon: <PencilIcon className="text-slate-400" width="16" height="16" />,
      onClick: () => {
        setShowRenameModal(true);
      }
    }
  ];

  const onRenameSubmit = async () => {
    if (!project) {
      notify('No project selected.', 'error');
      return;
    }
    if (!newName) {
      setRenameErrorMessage('Project name is required');
      return;
    }
    try {
      await rename({ id: project.id, name: newName }).unwrap();
      notify('Project renamed successfully', 'success');
      setShowRenameModal(false);
      setNewName('');
    } catch (e: unknown) {
      notify(`Error renaming project: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  };

  const onNewProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
    setRenameErrorMessage('');
  };

  return (
    <>
      <DropdownMenu items={menuOptions} className="bg-white">
        <EllipsisVerticalIcon className="text-slate-400 outline-0" width={20} height={20} />
      </DropdownMenu>
      <RenameModal
        isOpen={showRenameModal}
        onClose={() => setShowRenameModal(false)}
        onSaveClick={onRenameSubmit}
        isLoading={isLoadingRename}
        newProjectName={newName}
        onNewProjectNameChange={onNewProjectNameChange}
        errorMessage={renameErrorMessage}
      />
    </>
  );
};

interface RenameModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveClick: () => void;
  isLoading: boolean;
  newProjectName: string;
  onNewProjectNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
}

const RenameModal = ({
  isOpen,
  onClose,
  onSaveClick,
  isLoading,
  newProjectName,
  onNewProjectNameChange,
  errorMessage
}: RenameModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Rename Project"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Rename', onClick: onSaveClick, isLoading }
      ]}>
      <Input
        error={errorMessage}
        icon={<CircleStackIcon width="18" height="18" color="grey" />}
        placeholder="New Project name"
        value={newProjectName}
        onInputChange={onNewProjectNameChange}
        onEnter={onSaveClick}
      />
    </Modal>
  );
};

export default ProjectMenu;
