import { NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../types";

export const validateNewAggregateTableChange = (changeData: NewAggregateTableChangeData): string | null => {
    if (!changeData.newTableName) {
        return 'Model name must be set';
    }
    if (!changeData.columns.some(c => c.type === NewAggregateTableChangeColumnType.metric)) {
        return 'At least one metric needs to selected';
    }
    if (changeData.date.start && !changeData.date.end) {
        return 'If start time is set, then end time must also be set';
    }
    if (!changeData.date.start && changeData.date.end) {
        return 'If end time is set, then start time must also be set';
    }
    const modelNameRegex = /^[A-Za-z_][A-Za-z0-9_$.-]{0,254}$/;
    if (!modelNameRegex.test(changeData.newTableName)) {
        return 'Model name must start with a letter or underscore and contain only letters, numbers, underscores, hyphens, dollar signs, and periods';
    }
    return null;
};