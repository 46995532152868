export type DateFilterValue = 'last7Days' | 'last30Days' | 'last3Months' | null;

export const dateFilterToTimeRange = (dateFilter: DateFilterValue, convertToISOString = true) => {
  const now = Date.now();
  let res: { start?: string | number, end?: string | number } = {
    start: undefined,
    end: undefined
  };
  switch (dateFilter) {
    case 'last7Days':
      res = {
        start: new Date(now - 7 * 24 * 60 * 60 * 1000).getTime(),
        end: now
      };
      break;
    case 'last30Days':
      res = {
        start: new Date(now - 30 * 24 * 60 * 60 * 1000).getTime(),
        end: now
      };
      break;
    case 'last3Months':
      res = {
        start: new Date(now - 3 * 30 * 24 * 60 * 60 * 1000).getTime(),
        end: now
      };
      break;
  }
  if (convertToISOString) {
    res = {
      start: res.start ? new Date(res.start).toISOString() : undefined,
      end: res.end ? new Date(res.end).toISOString() : undefined
    };
  }
  return res;
};
