import {
  CheckIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  LinkIcon,
  NoSymbolIcon
} from '@heroicons/react/24/solid';
import { ChangeStatus, IChange } from '../IChange';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import DropdownMenu from '../../../components/DropdownMenu';
import { getEnumKeyByEnumValue } from '../../../utils/enumUtils';
import { GithubIcon } from '../../../assets/images/icons/DelphiIcons';
import { useRef, useState } from 'react';
import { useHoverDirty } from 'react-use';
import CreatePullRequestModal from './CreatePullRequestModal';
import RejectProposalModal from './RejectProposalModal';

const activeStatusLifecycle = [
  ChangeStatus.Open,
  ChangeStatus['Pending Merge'],
  ChangeStatus.Merged,
  ChangeStatus.Deployed,
  ChangeStatus.Published
];

const stateStatusHelper = new Map<Partial<ChangeStatus>, string>([
  [ChangeStatus.Open, 'Proposal created.'],
  [ChangeStatus['Pending Merge'], 'Pull request created.'],
  [ChangeStatus.Merged, 'Pull request merged.'],
  [ChangeStatus.Deployed, 'Changes deployed to dbt model.'],
  [ChangeStatus.Published, 'Data application synced with new changes.']
]);

const ChangeState = ({ change }: { change: IChange }) => {
  if (change.status === 'completed') {
    return <CompletedState change={change} />;
  } else if ([ChangeStatus['PR Rejected'], ChangeStatus['Draft Rejected']].includes(change.status)) {
    return <RejectedState change={change} />;
  } else {
    return <ActiveState change={change} />;
  }
};

const ActiveState = ({ change }: { change: IChange }) => {
  return (
    <div className="w-60">
      <div className="flex justify-between">
        <div className="flex flex-col">
          {activeStatusLifecycle.map((status, index) => {
            const isStatusActive = activeStatusLifecycle.indexOf(change.status) >= index;
            const isCurrentStatusPassed = activeStatusLifecycle.indexOf(change.status) > index;
            const textColor = isStatusActive ? 'text-text-primary' : 'text-slate-400';
            const circleColor = isStatusActive ? 'bg-surface-primary' : 'bg-slate-400';
            const lineColor = isCurrentStatusPassed ? 'bg-surface-primary' : 'bg-slate-400';
            return (
              <>
                <div className={`flex items-center gap-2 text-sm font-light ${textColor}`}>
                  <div className={`h-1.5 w-1.5 rounded-full ${circleColor}`}></div>
                  {getEnumKeyByEnumValue(ChangeStatus, status)}
                  {status === ChangeStatus.Deployed && change.buildId && (isCurrentStatusPassed || isStatusActive) && (
                    <span className="text-xs text-slate-400">build #{change.buildId}</span>
                  )}
                </div>
                {index !== activeStatusLifecycle.length - 1 && (
                  <div className={`-mb-1 -mt-1 ml-0.5 h-4 w-0.5 ${lineColor}`}></div>
                )}
              </>
            );
          })}
        </div>
        <StateHelper />
      </div>
      <StateActionBar change={change} />
    </div>
  );
};

const StateHelper = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);
  return (
    <div className="relative h-fit cursor-help text-slate-400" ref={ref}>
      <InformationCircleIcon width="16" height="16" />
      {isHovering && (
        <div className="absolute z-10 -ml-28 flex w-60 flex-col gap-2 rounded-lg bg-slate-950 p-3">
          {activeStatusLifecycle.map((status, index) => (
            <div key={index} className="">
              <div className="text-slate-200">{getEnumKeyByEnumValue(ChangeStatus, status)}</div>
              <div className="text-slate-400">{stateStatusHelper.get(status)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const StateActionBar = ({ change }: { change: IChange }) => {
  const [showCreatePRModal, setShowCreatePRModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const viewPullRequest = () => {
    window.open(change.pullRequestUrl, '_blank');
  };

  const dropdownMenuItems = [
    {
      icon: <NoSymbolIcon width="16" height="16" />,
      name: 'Reject proposal',
      onClick: () => setShowRejectModal(true)
    }
  ];
  return (
    <div className="mt-2 flex justify-between gap-2">
      {change.status === 'open' && (
        <>
          <Button
            type={ButtonTypes.primary}
            text="Create pull request"
            onClick={() => setShowCreatePRModal(true)}
            className="w-full"
          />
          <DropdownMenu items={dropdownMenuItems} className="!mt-8 -ml-24 w-40 bg-white">
            <Button
              type={ButtonTypes.secondary}
              icon={<EllipsisVerticalIcon height="16" width="16" />}
              text=""
              className="!p-1 !pr-0"
            />
          </DropdownMenu>
        </>
      )}
      {[ChangeStatus['Pending Merge'], ChangeStatus.Merged].includes(change.status) && (
        <Button
          type={ButtonTypes.secondary}
          text="View pull request"
          onClick={viewPullRequest}
          className="w-full"
          icon={<GithubIcon fill="#94A3B8" width="14" height="14"/>}
        />
      )}
      {
        change.status === ChangeStatus.Deployed && (
          <Button
            type={ButtonTypes.secondary}
            text="View build"
            onClick={() => window.open(`/project/${change.projectId}/manage`, '_blank')}
            className="w-full"
            icon={<LinkIcon width="14" height="14" />}
          />
        )
      }
      <CreatePullRequestModal change={change} isOpen={showCreatePRModal} onClose={() => setShowCreatePRModal(false)} />
      <RejectProposalModal change={change} isOpen={showRejectModal} onClose={() => setShowRejectModal(false)} />
    </div>
  );
};

const RejectedState = ({ change }: { change: IChange }) => {
  const text =
    change.status === ChangeStatus['PR Rejected']
      ? 'Pull request was rejected on Github'
      : 'This proposal was manually rejected.';
  return (
    <FinalStateContainer>
      <NoSymbolIcon width="18" height="18" className="text-tertiary" />
      <div className="mt-2 text-secondary">{text}</div>
    </FinalStateContainer>
  );
};

const CompletedState = ({ change }: { change: IChange }) => {
  return (
    <FinalStateContainer>
      <div className="w-fit rounded-full bg-green-400 p-1">
        <CheckIcon width="14" height="14" className="text-white" />
      </div>
      <div className="mt-2 text-secondary">This change has been successfully published.</div>
      <div className="mt-2 flex items-center gap-4 text-sm text-slate-400">
        {change.pullRequestUrl && (
          <div className="flex items-center gap-1">
            <GithubIcon fill="#94A3B8" width="12" height="12" />#{change.pullRequestUrl.split('/').pop()}
          </div>
        )}
        {change.buildId && (
          <div className="flex items-center gap-1">
            <LinkIcon width="12" height="12" />#{change.buildId}
          </div>
        )}
      </div>
    </FinalStateContainer>
  );
};

const FinalStateContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col items-center rounded-lg border border-slate-300 bg-surface-light p-6 text-center">
      {children}
    </div>
  );
};

export default ChangeState;
