import { IProject } from './IProject';
import {
  useGetProjectBranchesQuery,
  useGetProjectHealthQuery,
  useGetProjectQuery,
  useGetProjectBuildsQuery
} from '../../services/projects/projects';
import PageLoader from '../../components/loaders/PageLoader';
import { useParams } from 'react-router-dom';
import { notify } from '../../components/Toaster';
import activeProjectSlice from '../../infrastructure/state/slices/activeProjectSlice';
import { useAppDispatch } from '../../infrastructure/state/hooks';
import { useEffect } from 'react';
import { CircleStackIcon, CodeBracketIcon } from '@heroicons/react/24/solid';
import { HeroIcon } from '../../types';
import Container from '../../components/layout/Container';
import IBuild from '../builds/IBuild';
import { BranchIcon } from '../../assets/images/icons/DelphiIcons';
import Layout from '../../components/layout/Layout';
import ProjectHeader from './ProjectHeader';
import HealthStatusLabel from './HealthStatusLabel';
import TopBar from '../../components/layout/TopBar';
import BuildLifecycleState from '../builds/BuildLifecycleState';
import { HealthLabel } from './HealthLabel';
import { dateFormats, utcToLocal } from '../../infrastructure/dateUtilities';

const Project = () => {
  const projectId = parseInt(useParams().projectId || '');
  const dispatch = useAppDispatch();
  const { data: project, isLoading: isLoadingProject, isError } = useGetProjectQuery(projectId);

  useEffect(() => {
    if (project) {
      dispatch(activeProjectSlice.actions.setActiveProject(project.id));
    } else if (!project && !isLoadingProject) {
      dispatch(activeProjectSlice.actions.setActiveProject(null));
      notify('Project not found', 'error');
    }
  }, [project, dispatch, isLoadingProject]);

  if (isLoadingProject) {
    return <PageLoader label="Loading Project" />;
  } else if (!project || isError) {
    return (
      <Layout>
        <TopBar />
        <div className="mt-40 text-center text-lg">Failed to load project</div>
      </Layout>
    );
  } else if (project) {
    return (
      <Layout>
        <ProjectHeader project={project} page={'overview'} withTabs={true} />
        <div className="ml-14">
          <StatsBar project={project} />
          <Branches project={project} />
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

const StatsBar = ({ project }: { project: IProject }) => {
  const { data: builds = [], isLoading: isLoadingBuilds } = useGetProjectBuildsQuery({ id: project.id });
  const { data: health = 'pending', isLoading: isLoadingHealth } = useGetProjectHealthQuery({ id: project.id });
  const lastSuccessfulBuild = builds.filter((build: IBuild) => !build.errorMessage && build.lifecycleState === BuildLifecycleState.Processed && build.buildType === 'compile')[0];

  if (isLoadingBuilds || isLoadingHealth) {
    return <PageLoader />;
  }

  const HealthContainer = () => {
    return (
      !isLoadingHealth &&
      !isLoadingBuilds && (
        <Container className="flex h-28 w-80 flex-col items-start rounded-md p-3">
          <HealthLabel health={health} />
          <span className="mt-auto text-sm font-thin text-slate-500">
            {lastSuccessfulBuild && lastSuccessfulBuild.buildTime
              ? `Last built successfully on ${utcToLocal(lastSuccessfulBuild.buildTime, dateFormats.monthsDaysHoursAndMinutes)}`
              : 'No successful builds'}
          </span>
        </Container>
      )
    );
  };

  const StatContainer = ({ label, value, icon: Icon }: { label: string; value: number; icon: HeroIcon }) => {
    return (
      <Container className="flex w-44 flex-col items-start rounded-md p-3">
        <div className="mb-2 rounded-md border border-muse-600 bg-muse-100 p-1  ">
          <Icon width="16" height="16" color="#0C52FF" />
        </div>
        <span className="text-xl">{(value || 0).toLocaleString()}</span>
        <span className="text-slate-500">{label}</span>
      </Container>
    );
  };

  return (
    <div className="mt-5 flex gap-4">
      <HealthContainer />
      <StatContainer label="dbt models" value={lastSuccessfulBuild?.modelsCount} icon={CircleStackIcon} />
      <StatContainer label="Sources" value={lastSuccessfulBuild?.sourcesCount} icon={CodeBracketIcon} />
    </div>
  );
};

const Branches = ({ project }: { project: IProject }) => {
  const { data: branches = [], isLoading: isLoadingBranches } = useGetProjectBranchesQuery({ id: project.id });

  if (isLoadingBranches) {
    return <PageLoader />;
  }

  return (
    <Container className="mt-6 flex w-80 flex-col items-start rounded-md px-6 py-8">
      {!isLoadingBranches &&
        (branches.length === 0
          ? 'No branches'
          : branches.map((branch, index: number) => (
              <div key={index} className="w-full">
                {index !== 0 && <div className="mb-6 mt-6 w-full border-b border-slate-200"></div>}
                <div className="flex w-full justify-between">
                  <span className="flex items-center font-medium">
                    <BranchIcon fill="#94A3B8" width="16" height="16" className="mr-1" /> {branch.name}
                  </span>
                  <HealthStatusLabel status={branch.status} />
                </div>
              </div>
            )))}
    </Container>
  );
};

export default Project;
