import { ISuperficialNode } from './INode';

const getCriticalPath = (nodes: ISuperficialNode[], parentNodeId: string, direction: 'left' | 'right'): string[] => {
  const queue: string[] = [parentNodeId];
  const criticalPath = new Set<string>();
  while (queue.length > 0) {
    const nodeId = queue.pop();
    if (!nodeId || criticalPath.has(nodeId)) {
      continue;
    }
    if (nodeId !== parentNodeId) {
      criticalPath.add(nodeId);
    }
    if (direction === 'right') {
      queue.push(...nodes.filter((m) => m.parents.includes(nodeId)).map((m) => m.id));
    } else {
      queue.push(...(nodes.find((m) => m.id === nodeId)?.parents || []));
    }
  }
  return [...criticalPath];
};

export default getCriticalPath;
