import { Filter } from './Types';
import { DocumentDuplicateIcon, EyeIcon, LanguageIcon, ServerStackIcon, Squares2X2Icon, TableCellsIcon, TagIcon, CodeBracketIcon, BriefcaseIcon, FolderIcon, ViewfinderCircleIcon, ChartBarIcon, UserIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { mapBackendNodeTypeToLocalNodeType } from '../../../../../services/nodes/transformers';
import { NodeType, nodeNameMap } from '../../INode';
import { DbtCloudIcon, LookerInstanceIcon, ModelIcon, SourceDirectoryIcon, SparklesLeftArrowIcon, TableauIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { MetaFilter } from './MetaFilter';
import { NodeIcon } from '../../NodeIcon';
import { BackendNodeType } from '../../../../../services/nodes/types';
import { NameFilter } from './NameFilter';
import { FreeTextFilter } from './filterComponents/FreeTextFilter';
import { BadgeUtlToResourceName } from 'src/features/models/discover/toolbar/filters/BadgeUtlToResourceName';
import { SelectFilter } from 'src/components/Table/TableFilters/SelectFilter';

type FilterValue = string | null;
const dbtNodeTypes = [NodeType.DataModel, NodeType.DataSource];
const lookerNodeTypes = [NodeType.LookerLook, NodeType.LookerTile, NodeType.LookerView, NodeType.LookerDerivedView, NodeType.LookerDashboard];
const tableauNodeTypes = [NodeType.TableauEmbeddedDataSource, NodeType.TableauPublishedDataSource, NodeType.TableauCustomQuery, NodeType.TableauStory, NodeType.TableauWorkbook, NodeType.TableauView];

const FIRST_OBSERVED_METRICS_MILLISECONDS = 1726415774000; // 15-09-2024, to be removed after invalidating old metrics.

export const discoverFilterList: Filter[] = [
  {
    name: 'Type',
    menuIcon: <Squares2X2Icon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><Squares2X2Icon width="16" height="16" className="text-muse-400" /> Type: {value?.split(',').map(v => nodeNameMap.get(mapBackendNodeTypeToLocalNodeType.get(v as BackendNodeType)!)).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => {
      if (!value) {
        return '';
      }
      const values = value.split(',');
      const metricIndex = values.indexOf('dbt_metric');
      if (metricIndex !== -1) {
        values.splice(metricIndex, 1)[0];
        const metricFilter = `(type='dbt_metric' AND last_observed > ${FIRST_OBSERVED_METRICS_MILLISECONDS})`;
        if (values.length) {
            return `(${metricFilter} OR (type IN ('${values.join("','")}')))`;
        }
        return metricFilter;
      }
      return `(type IN ('${values.join("','")}'))`;
    },
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Type"
        options={
          filterOptions?.type?.map((type) => {
            const localType = mapBackendNodeTypeToLocalNodeType.get(type as BackendNodeType);
            return {
              label: nodeNameMap.get(localType!) || '',
              value: type,
              icon: <NodeIcon iconSize={16} type={localType as NodeType} />
            };
          }).filter(t => t.label) || []
        }
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />
  },
  {
    name: 'Name',
    menuIcon: <LanguageIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LanguageIcon width="16" height="16" className="text-muse-400" />{value}</>,
    isDisabled: false,
    component: NameFilter,
    getEql: (value: FilterValue) => value ? `(name IN ('${value.split(',').join("','")}'))` : '',
  },
  {
    name: 'Proposals',
    menuIcon: <SparklesLeftArrowIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><SparklesLeftArrowIcon width="16" height="16" fill="#56A5FF" />Proposals: {Object.entries(proposalsOptions).find((entry) => entry[1] === value)?.[0]}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `has_shift_left_potential ${value === 'false' ? 'IS NOT' : 'IS'} TRUE` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Proposals"
        options={Object.entries(proposalsOptions).map(([label, value]) => ({ label, value, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerTile, NodeType.LookerView, NodeType.LookerDerivedView],
  },
  {
    name: 'SQL',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-muse-400" /> SQL: {Object.entries(sqlOptions).find((entry) => entry[1] === value)?.[0]}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `is_trivial_sql ${value === 'false' ? 'IS NOT' : 'IS'} TRUE` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="SQL"
        options={Object.entries(sqlOptions).map(([label, value]) => ({ label, value, icon: <TableauIcon width="16" height="16" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.TableauCustomQuery],
  },
  {
    name: 'Total views 7D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-muse-400" /> Total views 7D: {totalViewsOptions.find(({ value: v }) => v === value)?.label}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `last_7d_views ${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 7D"
        options={totalViewsOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerDashboard],
  },
  {
    name: 'Total views 30D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-muse-400" /> Total views 30D: {totalViewsOptions.find(({ value: v }) => v === value)?.label}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `last_30d_views${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 30D"
        options={totalViewsOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerDashboard],
  },
  {
    name: 'Total usage 14D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-muse-400" /> Total usage 14D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? value.split(',').map(v => `total_queries_14d ${v}`).join(' OR ') : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total usage 14D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'Total usage 30D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-muse-400" /> Total usage 30D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    value: null,
    getEql: (value: FilterValue) => value ? value.split(',').map(v => `total_queries_30d ${v}`).join(' OR ') : '',
    isDisabled: false,
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total usage 30D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'Total usage 60D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-muse-400" /> Total usage 60D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    value: null,
    getEql: (value: FilterValue) => value ? value.split(',').map(v => `total_queries_60d ${v}`).join(' OR ') : '',
    isDisabled: false,
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total usage 60D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'First seen',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-muse-400" /> First seen: {Object.entries(firstSeenOptions).find((entry) => entry[1] === value)?.[0] || ''}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `first_observation ${mapFirstSeenValueToEql(value)}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="First seen"
        options={Object.entries(firstSeenOptions).map(([label, value]) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Materialization',
    menuIcon: <DocumentDuplicateIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><DocumentDuplicateIcon width="16" height="16" className="text-muse-400" /> Materialization: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(dbt_materialization_strategy IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Materialization"
        options={filterOptions?.dbt_materialization_strategy?.map((dbt_materialization_strategy) => ({ label: dbt_materialization_strategy, value: dbt_materialization_strategy, icon: <DocumentDuplicateIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.DataModel],
  },
  {
    name: 'Tags',
    menuIcon: <TagIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TagIcon width="16" height="16" className="text-muse-400" /> Tags: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(tags IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tags"
        options={filterOptions?.tags?.map((tag) => ({ label: tag, value: tag, icon: <TagIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [...dbtNodeTypes, NodeType.TableauEmbeddedDataSource, NodeType.TableauPublishedDataSource, NodeType.TableauCustomQuery],
  },
  {
    name: 'Meta',
    menuIcon: <CodeBracketIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TagIcon width="16" height="16" className="text-muse-400" /> Meta: {value}</>,
    isDisabled: false,
    getEql: (kv: FilterValue) => {
      if (!kv) return '';
      const keyValues = kv.split(';');
      const filterStrings = keyValues.map((kv) => {
        const [key, values] = kv.split('=');
        return `(meta.${key} IN ('${values.split(',').join("', '")}'))`;
      });
      return filterStrings.join(' AND ');
    },
    component: MetaFilter,
    nodeTypes: dbtNodeTypes,
  },
  {
    name: 'dbt project',
    menuIcon: <DbtCloudIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><DbtCloudIcon width="16" height="16" fill="#56A5FF" /> dbt project: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(dbt_project IN ('${value.split(',').join("', '")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="dbt project"
        options={filterOptions?.dbt_project?.map((dbt_project) => ({ label: dbt_project, value: dbt_project, icon: <DbtCloudIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: dbtNodeTypes,
  },
  {
    name: 'Database schema',
    menuIcon: <TableCellsIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableCellsIcon width="16" height="16" className="text-muse-400" /> Database schema: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(schema IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database schema"
        options={filterOptions?.database_schema?.map((schema) => ({ label: schema, value: schema, icon: <TableCellsIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: dbtNodeTypes,

  },
  {
    name: 'Database',
    menuIcon: <ServerStackIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ServerStackIcon width="16" height="16" className="text-muse-400" /> Database: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(database IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database"
        options={filterOptions?.database?.map((database) => ({ label: database, value: database, icon: <ServerStackIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: dbtNodeTypes,
  },
  {
    name: 'Looker project',
    menuIcon: <BriefcaseIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><BriefcaseIcon width="16" height="16" className="text-muse-400" /> Looker project: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(looker_project IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker project"
        options={filterOptions?.looker_project?.map((looker_project) => ({ label: looker_project, value: looker_project, icon: <BriefcaseIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: lookerNodeTypes,
  },
  {
    name: 'Looker folder',
    menuIcon: <FolderIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><FolderIcon width="16" height="16" className="text-muse-400" /> Looker folder: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(looker_folder IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker folder"
        options={filterOptions?.looker_folder?.map((looker_folder) => ({ label: looker_folder, value: looker_folder, icon: <FolderIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: lookerNodeTypes,
  },
  {
    name: 'Looker model',
    menuIcon: <ModelIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ModelIcon width="16" height="16" fill="#56A5FF" /> Looker model: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(looker_model IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker model"
        options={filterOptions?.looker_model?.map((looker_model) => ({ label: looker_model, value: looker_model, icon: <ModelIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: lookerNodeTypes,
  },
  {
    name: 'Looker instance',
    menuIcon: <LookerInstanceIcon width="18" height="18" className="mr-1.5" fill="#475569" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LookerInstanceIcon width="16" height="16" fill="#56A5FF" /> Looker instance: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(looker_host IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker instance"
        options={filterOptions?.looker_host?.map((looker_host) => ({ label: looker_host, value: looker_host, icon: <LookerInstanceIcon width="16" height="16" fill="#475569" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: lookerNodeTypes,
  },
  {
    name: 'Source directory',
    menuIcon: <SourceDirectoryIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><SourceDirectoryIcon width="16" height="16" fill="#56A5FF" /> Source directory: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(source_directory IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Source directory"
        options={filterOptions?.source_directory?.map((source_directory) => ({ label: source_directory, value: source_directory, icon: <SourceDirectoryIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [...dbtNodeTypes, NodeType.LookerView, NodeType.LookerExplore]
  },
  {
    name: 'UTL',
    menuIcon: <ViewfinderCircleIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ViewfinderCircleIcon width="16" height="16" className="text-muse-400" /> UTL: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `uri = '${value}'` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <FreeTextFilter
        value={value}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
        placeholder={'UTL'}
        label={'UTL'}
      />
  },
  {
    name: 'Tableau project',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-muse-400" /> Tableau project: {value}</> || '',
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(tableau_project IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau project"
        options={filterOptions?.tableau_project?.map((tableau_project) => ({ label: tableau_project, value: tableau_project, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: tableauNodeTypes,
  },
  {
    name: 'Tableau connection',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    isDisabled: false,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-muse-400" /> Tableau connection: {value}</> || '',
    getEql: (value: FilterValue) => value ? `(tableau_connection IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau connection"
        options={filterOptions?.tableau_connection?.map((tableau_connection) => ({ label: tableau_connection, value: tableau_connection, icon: <DocumentDuplicateIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: tableauNodeTypes,
  },
  {
    name: 'Tableau workbook',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-muse-400" /> Tableau workbook: {value}</> || '',
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(tableau_workbook IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau workbook"
        options={filterOptions?.tableau_workbook?.map((tableau_workbook) => ({ label: tableau_workbook, value: tableau_workbook, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: tableauNodeTypes,
  },
  {
    name: 'Owner',
    menuIcon: <UserIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <>ic<UserIcon width="16" height="16" className="text-muse-400" />n Owner: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(owner IN ('${value.split(',').join("','")}'))` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Owner"
        options={filterOptions?.owner?.map((owner) => ({ label: owner, value: owner, icon: <UserIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: tableauNodeTypes,
  },
  {
    name: 'Upstream dependencies',
    menuIcon: <ChevronDoubleLeftIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <BadgeUtlToResourceName label='Upstream dependencies' value={value} icon={<ChevronDoubleLeftIcon width="16" height="16" className="text-muse-400" />} />,
    getEql: (value: FilterValue) => value ? `(has downstream(uri='${value}'))` : '',
    showInMenu: false
  },
  {
    name: 'Downstream dependents',
    menuIcon: <ChevronDoubleRightIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <BadgeUtlToResourceName label='Downstream dependents' value={value} icon={<ChevronDoubleRightIcon width="16" height="16" className="text-muse-400" />} />,
    getEql: (value: FilterValue) => value ? `(has upstream(uri='${value}'))` : '',
    showInMenu: false
  },
];

const firstSeenOptions = {
  'Last 7 days': `last_7_days`,
  'Last 30 days': `last_30_days`,
  'Last 90 days': `last_90_days`,
};

const mapFirstSeenValueToEql = (value: string) => {
  const now = Date.now();
  const nowIso = new Date(now).toISOString();
  switch (value) {
    case 'last_7_days':
      return `BETWEEN '${new Date(now - 7 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    case 'last_30_days':
      return `BETWEEN '${new Date(now - 30 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    case 'last_90_days':
      return `BETWEEN '${new Date(now - 90 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    default:
      return '';
  }
};

const totalViewsOptions = [
  { label: '1-10', value: 'BETWEEN 1 AND 10' },
  { label: '10-100', value: 'BETWEEN 10 AND 100' },
  { label: '100-1,000', value: 'BETWEEN 100 AND 1000' },
  { label: '1,000-10,000', value: 'BETWEEN 1000 AND 10000' },
  { label: '10,000+', value: '>= 10000' },
];

const proposalsOptions = {
  'Has a proposal': 'true',
  'Doesn\'t have a proposal': 'false',
};

const sqlOptions = {
  'Trivial SQL': 'true',
  'Non-trivial SQL': 'false',
};

const usageOptions = [
  { label: '0', value: '= 0' },
  { label: '1-100', value: 'BETWEEN 1 AND 100' },
  { label: '101-500', value: 'BETWEEN 101 AND 500' },
  { label: '501-1,000', value: 'BETWEEN 501 AND 1000' },
  { label: '1,001-5,000', value: 'BETWEEN 1001 AND 5000' },
  { label: '5,001-10,000', value: 'BETWEEN 5001 AND 10000' },
  { label: '10,001+', value: ' > 10000' },
];
