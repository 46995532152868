import { Outlet, useLocation } from 'react-router-dom';
import { trackPageChange, resetIdentity, identifyUser } from '../infrastructure/analytics';
import { useSession, useUser } from '@descope/react-sdk';
import { useEffect, useState } from 'react';
import api from '../services/api';
import { reset as resetLocalStorage } from '../infrastructure/localStorage/localStorage';
import { store } from '../infrastructure/state/store';
import { useAppDispatch } from '../infrastructure/state/hooks';
import * as sentry from '../infrastructure/sentry';
import { Environment, getCurrentEnv } from '../infrastructure/env';
import historyStackSlice from 'src/infrastructure/state/slices/historyStackSlice';
const DESCOPE_TIMEOUT = 3000;

const RouterRoot = () => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [startTime] = useState(new Date().getTime());

  //On location change
  useEffect(() => {
    trackPageChange(location.pathname);

    dispatch(historyStackSlice.actions.setHistoryStack(location.pathname + location.search));
  }, [location, dispatch]);

  //On auth change
  useEffect(() => {
    if (!(isSessionLoading || isUserLoading) && getCurrentEnv() !== Environment.Testing) {
      if (isAuthenticated) {
        identifyUser({ userId: user.userId, traits: { email: user.email, name: user.name } });
        sentry.identify(user);
      } else {
        store.dispatch(api.util.resetApiState());
        resetIdentity();
        resetLocalStorage();
        console.error('Descope session expired');
      }
    } else if (new Date().getTime() - startTime > DESCOPE_TIMEOUT) {
      console.error('Descope timeout');
      window.location.reload();
    }
  }, [isAuthenticated, user, isSessionLoading, isUserLoading, startTime]);

  return <Outlet />;
};

export default RouterRoot;
