import { Tooltip } from "react-tooltip";

interface Props {
    text: string;
    maxChars: number;
}

export const TextWithEllipsisAndTooltip = ({ text = '', maxChars }: Props) => {
    const showTooltip = text.length > maxChars;
    const textWithEllipsis = text.length > maxChars ? `${text.substring(0, maxChars)}...` : text;
    const id = Math.random().toString(36).substring(7);
    return (
        <>
            <div data-tag="allowRowEvents" data-tooltip-id={id} data-tooltip-content={text}>{textWithEllipsis}</div>
            {
                showTooltip && (
                    <Tooltip id={id} style={{zIndex: 100}}/>
                )
            }
        </>
    );
};