import { TagIcon, CodeBracketIcon } from "@heroicons/react/24/solid";
import { IMeta } from "../../INode";
import { TextWithEllipsisAndTooltip } from "../../../../../components/TextWithEllipsisAndTooltip";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type NodeInfoProps = {
    info?: { [key: string]: string };
    tags: string[];
    meta: IMeta;
};

export const NodeInfo = ({ info = {}, tags, meta }: NodeInfoProps) => {
    if (Object.keys(info).length === 0 && tags.length === 0 && Object.keys(meta).length === 0) {
        return null;
    }
    return (
        <div className="mt-4 rounded-md border border-slate-100 bg-slate-50 p-2 flex flex-col gap-2">
            {
                Object.keys(info).length > 0 && (
                    <div>
                        <div className="flex gap-1 items-center text-secondary">
                            <ExclamationCircleIcon width="16" height="16" className="text-slate-400" /> Info
                        </div>
                        {
                            Object.entries(info).map(([key, value]) => (
                                <div className="flex gap-1 text-sm mt-1" key={key}>
                                    <div className="flex items-center gap-1 text-tertiary">{key}</div>
                                    <div className="font-light text-secondary"><TextWithEllipsisAndTooltip text={value} maxChars={45} /></div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                tags.length > 0 && (
                    <div>
                        <div className="flex items-center gap-1 text-secondary">
                            <TagIcon width="16" height="16" />
                            Tags
                        </div>
                        <div className="mt-1 text-sm font-light text-secondary">{tags.join(', ')}</div>
                    </div>
                )
            }
            {
                Object.keys(meta).length > 0 && (
                    <div>
                        <div className="flex items-center gap-1 text-secondary">
                            <CodeBracketIcon width="16" height="16" />
                            Meta
                        </div>
                        {Object.entries(meta).map(([key, value]) => (
                            <div className="mt-1 flex gap-1 text-sm font-light" key={key}>
                                <span className="text-tertiary"><TextWithEllipsisAndTooltip text={`${key}:`} maxChars={30} /></span>
                                <span className="text-secondary"><TextWithEllipsisAndTooltip text={value} maxChars={20} /></span>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
};