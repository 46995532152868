import { ChangeEvent, useState } from "react";
import { DiscoverFilterProps } from "../Types";
import Input from "../../../../../../components/form/Input";
import Button from "../../../../../../components/button/Button";
import { ButtonTypes } from "../../../../../../components/button/types";

type FreeTextFilterProps = DiscoverFilterProps & {
    placeholder: string;
    label: string;
}

export const FreeTextFilter = ({ value, onChange, onSave, onClose, placeholder, label }: FreeTextFilterProps) => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
    const [focus] = useState(true);
    const apply = () => {
        onSave(value);
        onClose();
    };
    const clear = () => {
        onSave(null);
        onClose();
    };
    return (
        <div className="w-60 p-2">
            <div className="mb-2 text-text-primary">{label}</div>
            <Input placeholder={placeholder} value={value || ''} onInputChange={onInputChange} focus={focus} />
            <div className="flex gap-1 mt-5">
                <Button onClick={clear} type={ButtonTypes.secondary} text='Clear' className="w-full"/>
                <Button onClick={apply} type={ButtonTypes.primary} text='Apply' className="w-full"/>
            </div>
        </div>
    );
};