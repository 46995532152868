import ReactDiffViewer from 'react-diff-viewer-continued';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState, useRef, useEffect } from 'react';
import { ChangeDiff } from '../features/evolution/IChange';

const FileDiff = ({ file, index }: { file: ChangeDiff; index: number }) => {
  const [isOpen, setIsOpen] = useState(index === 0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    applyBottomRadiusOnDiffViewer(ref);
  }, [isOpen]);

  return (
    <div className="mt-2 text-sm" ref={ref}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex cursor-pointer items-center gap-2 rounded-t-lg bg-slate-700 p-2 text-white ${
          !isOpen && 'rounded-b-lg'
        }`}>
        {isOpen ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}
        {file.fileName}
      </div>
      {isOpen && (
        <ReactDiffViewer
          oldValue={file.oldCode}
          newValue={file.newCode}
          splitView={false}
          styles={DiffStyles}
          useDarkTheme={true}
        />
      )}
    </div>
  );
};

const applyBottomRadiusOnDiffViewer = (ref: React.RefObject<HTMLDivElement>) => {
  const table = ref.current?.querySelector('table');
  const rows = table?.querySelectorAll('tr');
  const lastRowCells = rows?.[rows.length - 1].querySelectorAll('td');
  lastRowCells?.forEach((cell, index) => {
    if (index === 0) {
      cell.style.borderBottomLeftRadius = '0.5rem';
    } else if (index === lastRowCells.length - 1) {
      cell.style.borderBottomRightRadius = '0.5rem';
    }
  });
};

const DiffStyles = {
  lineNumber: {
    color: 'white'
  },
  diffContainer: {
    borderBottomRightRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem'
  }
};

export default FileDiff;
