import { AggregateTableTimeGranularity, NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../../features/evolution/changeForms/newAggregateTable/types";
import { backendMetadataToLocalMetadata, backendRelationshipToLocalRelationship, localMetadataToBackendMetadata, localRelationshipToBackendRelationship } from "./transformers";
import { BackendChangeType, Metadata } from "./types";

export type NewAggregateTableChangeDataBackend = {
    change_type: BackendChangeType.AddAggregateTable;
    project_name: string;
    model_name: string;
    metric_time: AggregateTableTimeGranularity | null;
    meta_data: {
        meta: Metadata;
        tags: string[];
        description: string;
    };
    materialization: 'view' | 'table' | 'incremental';
    database_schema: string | null;
    columns: NewAggregateTableChangeDataBackendColumn[];
    filters: string;
    start_date: string | null;
    end_date: string | null;
};

export type NewAggregateTableChangeDataBackendColumn = {
    agg_column_type: NewAggregateTableChangeColumnType;
    is_timestamp: boolean;
    time_granularity: AggregateTableTimeGranularity | null;
    column_name: string;
    parent_entity: string | null;
    entity_path: string | null;
    alias: string | null;
    column_metadata: {
        description: string;
        tags: string[];
        meta: Metadata;
        column_is_nullable: boolean;
        column_is_unique: boolean;
        column_accepted_values: string[];
        relationships: { to_model_unique_id: string; to_column_name: string } | null;
        column_type: string | null;
    }
};

export type NewAggregateTableChangeDataBackendMeasure = {
    name: string;
    label: string;
    agg: string;
    expr: string;
    measure_metadata: {
        description: string;
        tags: string[];
        meta: Metadata;
    };
}

export const transformAggregateTableChangeDataToBackend = (changeData: NewAggregateTableChangeData): NewAggregateTableChangeDataBackend => {
    return {
        ...changeData,
        change_type: BackendChangeType.AddAggregateTable,
        project_name: changeData.dbtProjectName,
        model_name: changeData.newTableName,
        metric_time: changeData.metricTime,
        meta_data: {
            meta: localMetadataToBackendMetadata(changeData.meta),
            tags: changeData.tags,
            description: changeData.description
        },
        materialization: changeData.materialization,
        database_schema: changeData.databaseSchema,
        columns: changeData.columns.map((column: NewAggregateTableChangeColumn) => ({
            agg_column_type: column.type,
            parent_entity: column.parentEntity,
            entity_path: column.entityPath,
            is_timestamp: column.type === NewAggregateTableChangeColumnType.metric_time || column.isTimeField,
            time_granularity: column.type === NewAggregateTableChangeColumnType.metric_time ? changeData.metricTime : column.timeGranularity,
            column_name: column.name,
            alias: column.alias,
            column_metadata: {
                description: column.description || '',
                tags: column.tags,
                meta: localMetadataToBackendMetadata(column.meta),
                column_type: column.dataType || null,
                column_is_nullable: column.isNullable,
                column_is_unique: column.isUnique,
                column_accepted_values: column.acceptedValues,
                relationships: localRelationshipToBackendRelationship(column.relationships)
            }
        })),
        filters: changeData.filters,
        start_date: changeData.date.start,
        end_date: changeData.date.end
    };
};

export const transformAggregateTableChangeDataToLocal = (changeData: NewAggregateTableChangeDataBackend): NewAggregateTableChangeData => {
    return {
        ...changeData,
        dbtProjectName: changeData.project_name,
        newTableName: changeData.model_name,
        metricTime: changeData.metric_time,
        meta: backendMetadataToLocalMetadata(changeData.meta_data.meta),
        tags: changeData.meta_data.tags,
        description: changeData.meta_data.description,
        materialization: changeData.materialization,
        databaseSchema: changeData.database_schema,
        columns: changeData.columns.map((column: NewAggregateTableChangeDataBackendColumn) => ({
            type: column.agg_column_type,
            parentEntity: column.parent_entity,
            entityPath: column.entity_path,
            isTimeField: column.is_timestamp,
            timeGranularity: column.time_granularity,
            name: column.column_name,
            description: column.column_metadata.description,
            tags: column.column_metadata.tags,
            meta: backendMetadataToLocalMetadata(column.column_metadata.meta),
            isUnique: column.column_metadata.column_is_unique,
            isNullable: column.column_metadata.column_is_nullable,
            acceptedValues: column.column_metadata.column_accepted_values,
            relationships: backendRelationshipToLocalRelationship(column.column_metadata.relationships),
            alias: column.alias,
            dataType: column.column_metadata.column_type || null
        })),
        filters: changeData.filters,
        date: { start: changeData.start_date, end: changeData.end_date }
    };
};