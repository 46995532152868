import api from './api';

export const eulaApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLatestEula: build.query<{ is_accepted: boolean; version: string; url: string }, { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/eula/check`,
      providesTags: ['Eula']
    }),
    acceptEula: build.mutation<void, { accountId: number; version: string }>({
      query: ({ accountId, version }) => ({
        url: `accounts/${accountId}/eula/approve`,
        method: 'POST',
        body: { version }
      }),
      invalidatesTags: ['Eula']
    })
  })
});

export const { useGetLatestEulaQuery, useAcceptEulaMutation } = eulaApi;
