import { useMemo } from 'react';
import { IContainedNode, nodeNameMap } from '../../INode';
import { NodeIcon } from '../../NodeIcon';

type ContainedNodesListProps = {
    containedNodes: IContainedNode[];
    onClick: (nodeId: string) => void;
}

export const ContainedNodesList = ({ containedNodes, onClick }: ContainedNodesListProps) => {
    const containedNodesSortedByType = useMemo(() => {
        const types = [...new Set(containedNodes.map((node) => node.type))].sort();
        return types.map((type) => {
            return containedNodes.filter((node) => node.type === type);
        }).flat();
    }, [containedNodes]);

    if (containedNodes.length === 0) {
        return null;
    }
    return (
        <div className="mt-4">
            <div className="mt-2">
                {
                    containedNodesSortedByType.map((node, index) => {
                        return (
                            <div
                                className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
                                key={index}
                                onClick={() => onClick(node.id)}>
                                <div className="flex items-center gap-1 overflow-hidden">
                                    <NodeIcon type={node.type} />
                                    <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                                        {node.name}
                                    </span>
                                </div>
                                <span className="text-xs text-tertiary">{nodeNameMap.get(node.type)}</span>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};