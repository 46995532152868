import { useConnectProjectBranchMutation } from '../../../services/projects/projects';
import { useEffect, useMemo, useState } from 'react';
import { notify } from '../../../components/Toaster';
import { IProject } from '../IProject';
import { ButtonTypes } from '../../../components/button/types';
import { BranchIcon } from '../../../assets/images/icons/DelphiIcons';
import Modal from '../../../components/Modal/Modal';
import Select, { Option } from '../../../components/form/Select';
import IBranch from './IBranch';
import { extractErrorMessage } from '../../../services/api';
import { Alert } from '../../../components/Alert';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { useGetGithubBranchesQuery, useGetGithubIntegrationsQuery } from '../../../services/integrations/integrations';

interface ConnectBranchModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: IProject;
  projectBranches: IBranch[];
}

const ConnectBranchModal = ({ isOpen, onClose, project, projectBranches }: ConnectBranchModalProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const { data, error} = useGetGithubIntegrationsQuery({ accountId });
  const githubAccountId = useMemo(() => data?.[0]?.githubAccountId || 0, [data]);

  useEffect(() => {
    if (error) {
      notify(extractErrorMessage(error).message, 'error');
    }
  }, [error]);

  const {
    data: repositoryBranches = [],
    isLoading: isLoadingRepositoryBranches,
    error: getRepositoryBranchesError
  } = useGetGithubBranchesQuery({ repo: project.repository.name, githubAccountId, accountId }, { skip: !githubAccountId });

  const [branch, setBranch] = useState('');
  const [connectBranch, { isLoading }] = useConnectProjectBranchMutation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setBranch('');
      setErrorMessage('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (branch) {
      setErrorMessage('');
    }
  }, [branch]);

  useEffect(() => {
    if (getRepositoryBranchesError) {
      setErrorMessage(extractErrorMessage(getRepositoryBranchesError).message);
    }
  }, [getRepositoryBranchesError]);

  const onCreateClick = async () => {
    if (!branch) {
      setErrorMessage('Please select a branch');
      return;
    }
    if (projectBranches.find((b) => b.name === branch)) {
      setErrorMessage('Branch already connected');
      return;
    }
    try {
      await connectBranch({ branchName: branch, projectId: project.id });
      notify('Branch connected', 'success');
      onClose();
    } catch (e) {
      notify(`Error connecting branch: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Connect a branch"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Connect branch', onClick: onCreateClick, isLoading }
      ]}>
      <div className="mb-5">
        <div className="flex items-center justify-between text-text-primary">
          <span>Branch</span>
          <Select
            options={repositoryBranches.map((b) => ({ label: b.name, value: b.name })) || []}
            value={branch}
            onChange={(option) => setBranch((option as Option).value.toString())}
            className="border-grey-300 ml-14 w-full rounded-md border shadow"
            placeholder="Select a branch"
            isDisabled={isLoadingRepositoryBranches}
            icon={<BranchIcon fill="#94A3B8" className="ml-2 mr-1 text-slate-400" width="16" height="16" />}
          />
        </div>
        {errorMessage && <Alert type="error" title="Error:" text={errorMessage} className="mt-5" />}
      </div>
    </Modal>
  );
};

export default ConnectBranchModal;
