import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import getCriticalPath from '../../getCiriticalPath';
import { ISuperficialNode, NodeType, nodeHeight, nodeWidth } from '../../INode';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../../../infrastructure/state/slices/activeAccountSlice';
import { getNodeIdForDom } from '../../getNodeIdForDom';
import PageLoader from '../../../../../components/loaders/PageLoader';
import { ExpandedNode, IDAGNode, OnCollapse, SetShowNodeSidepane } from './types';
import { DataModelTooLargeScreen } from './DataModelTooLargeScreen';
import { DiscoverDAGCanvas } from './Canvas/DiscoverDAGCanvas';
import { selectIsMenuCollpased } from '../../../../../infrastructure/state/slices/isMenuCollpasedSlice';
import { getConnectedNodes, getDAGNodes } from './getDAGNodes';
import { maxNodesForDag, maxNodesForDagWithGoodPerformance, reasonableMaxRanksPerSwimlane } from './DAGConfiguration';
import { customToast } from '../../../../../components/Toaster';
import { NodePositionsMap } from '../../../../../infrastructure/DAG/Types';
import { generateOrphanNodes } from './generateOrphanNodes';
import { useSearchParams } from 'react-router-dom';
import { events, trackEvent } from '../../../../../infrastructure/analytics';
import { useGetDamaRevisionQuery } from '../../../../../services/accounts/accounts';
import { WarningToast } from './WarningToast';
import { getHistoryStack } from 'src/infrastructure/state/slices/historyStackSlice';

const PERFORMANCE_WARNING = 'Performance may be decreased when exploring large data models.';

interface DiscoverNodesViewProps {
  setShowNodeSidepane: SetShowNodeSidepane;
  selectedNode: ISuperficialNode | null;
  setSelectedNode: (node: ISuperficialNode | null) => void;
  eql: string;
  expandedNodes: ExpandedNode[];
  setExpandedNodes: (expandedNodes: ExpandedNode[]) => void;
}

const getFullPath = (selectedNode: ISuperficialNode | null, nodes: ISuperficialNode[]) => {
  if (selectedNode) {
    return [
      ...getCriticalPath(nodes, selectedNode.id, 'right'),
      ...getCriticalPath(nodes, selectedNode.id, 'left'),
      selectedNode.id
    ];
  }
  return [];
};

export const DiscoverDAGView = ({
  setShowNodeSidepane,
  selectedNode,
  setSelectedNode,
  eql,
  expandedNodes,
  setExpandedNodes
}: DiscoverNodesViewProps) => {
  const { shutdown } = useIntercom();
  const accountId = useSelector(selectActiveAccountId);
  const getDamaRevisionQuery = useGetDamaRevisionQuery({ accountId });
  const [nodes, setNodes] = useState<IDAGNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMenuCollapsed = useSelector(selectIsMenuCollpased);
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const [dagTooLarge, setDagTooLarge] = useState<boolean>(false);
  const [nodesPositions, setNodesPositions] = useState<NodePositionsMap>(new Map<string, { x: number; y: number }>());
  const criticalPath = useMemo(() => getFullPath(selectedNode, nodes), [nodes, selectedNode]);
  const filterHashRef = useRef('');
  const expandedNodesHashRef = useRef('[]');
  const withFilters = useMemo(() => !!eql, [eql]);
  const [searchParams] = useSearchParams();
  const history = useSelector(getHistoryStack);
  const debouncedNodes = useRef(0);
  const debouncedNodePositions = useRef(0);
  const [isLoadingPositions, setIsLoadingPositions] = useState<boolean>(false);

  const updateNodesPositions = useCallback((nodes: IDAGNode[]) => {
    setIsLoadingPositions(true);
    const timestamp = Date.now();
    debouncedNodePositions.current = timestamp;
    const newDAGWorker = new Worker(new URL('../../../../../infrastructure/DAG/worker', import.meta.url), {
      type: 'module',
    });
    newDAGWorker.onmessage = (event) => {
      if (timestamp !== debouncedNodePositions.current) {
        return;
      }
      setNodesPositions(event.data);
      newDAGWorker.terminate();
      setIsLoadingPositions(false);
    };
    newDAGWorker.postMessage({
      nodes: nodes.map((n) => ({ id: n.id, parents: n.parents, width: nodeWidth, height: nodeHeight, cluster: nodeTypeToClusterOrder.get(n.type) || 0 })),
      xOffset: -120,
      yOffset: 20,
      distanceBetweenForeignClusters: reasonableMaxRanksPerSwimlane,
    });
  }, [setNodesPositions]);

  const updateFilteredNodes = useCallback(async (eql: string) => {
    const now = Date.now();
    debouncedNodes.current = now;
    setIsLoading(true);
    setExpandedNodes([]);
    setSelectedNode(null);
    const { newNodes, filteredNodes } = await getDAGNodes({ accountId, eql });
    if (now !== debouncedNodes.current) {
      return;
    }
    const filteredWorkbookNodes = filteredNodes.filter(n => n.type === NodeType.TableauWorkbook);
    if (!selectedNode && filteredWorkbookNodes.length > 0 && history.length && history[history.length - 1].includes('view=table')) {
        customToast(<WarningToast toastId={'workbooksDiscoverDisplayed'} message={`${filteredWorkbookNodes.length} Tableau Workbooks are not displayed in the DAG view`} />,  { duration: 8000, style: { maxWidth: 'fit-content' }, id: 'workbooksDiscoverDisplayed' });
    }
    if (newNodes.length > maxNodesForDag) {
      setDagTooLarge(true);
    }
    else {
      if (newNodes.length > maxNodesForDagWithGoodPerformance) {
        customToast(t => <WarningToast toastId={t.id} message={PERFORMANCE_WARNING} />, { duration: 5000, style: { maxWidth: 'fit-content' } });
      }
      const orphanNodes = generateOrphanNodes(newNodes, expandedNodes, withFilters);
      newNodes.push(...orphanNodes);
      const distinctNodes = newNodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
      updateNodesPositions(distinctNodes);
      setNodes(distinctNodes);
      setDagTooLarge(false);
    }
    setIsLoading(false);
  }, [accountId, setNodes, setIsLoading, updateNodesPositions, expandedNodes, setExpandedNodes, setSelectedNode, withFilters, history, selectedNode]);

  const updateNodeConnections = useCallback(async (expandedNodes: ExpandedNode[]) => {
    setIsLoading(true);
    const connectedNodes = expandedNodes.length === 0 ? [] : await getConnectedNodes({ accountId, expandedNodes });
    const newNodes = [...nodes.filter(n => !n.isConnectedNode), ...connectedNodes];
    const orphanNodes = generateOrphanNodes(newNodes, expandedNodes, withFilters);
    newNodes.push(...orphanNodes);
    const distinctNodes = newNodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
    if (distinctNodes.length > maxNodesForDag) {
      setDagTooLarge(true);
    }
    else {
      if (newNodes.length > maxNodesForDagWithGoodPerformance) {
        customToast(t => <WarningToast toastId={t.id} message={PERFORMANCE_WARNING} />, { duration: 5000, style: { maxWidth: 'fit-content' } });
      }
      setNodes(distinctNodes);
      updateNodesPositions(distinctNodes);
      setDagTooLarge(false);
    }
    setIsLoading(false);
  }, [nodes, accountId, setNodes, setIsLoading, updateNodesPositions, withFilters]);

  //On filters / node expansion change
  useEffect(() => {
    const filterHash = JSON.stringify({ eql });
    const expandedNodesHash = JSON.stringify(expandedNodes);
    const utlRaceCondition = (searchParams.get('highlightedNode') || searchParams.get('UTL')) && !eql;
    if (filterHash !== filterHashRef.current && getDamaRevisionQuery.data && !utlRaceCondition) {
      filterHashRef.current = filterHash;
      updateFilteredNodes(eql);
    }
    else if (expandedNodesHash !== expandedNodesHashRef.current) {
      expandedNodesHashRef.current = expandedNodesHash;
      updateNodeConnections(expandedNodes);
    }
  }, [eql, expandedNodes, getDamaRevisionQuery.data, updateFilteredNodes, updateNodeConnections, searchParams]);

  //Update canvas width when menu is collapsed
  useEffect(() => {
    const menuWidth = (document.querySelector('#menu-layout')?.getBoundingClientRect().width || 0) / window.innerWidth * 100;
    setMenuWidth(menuWidth);
  }, [isMenuCollapsed]);

  const onCollapse: OnCollapse = useCallback(({ node, direction, depth = null }) => {
    const newExpandedNodes = [...expandedNodes];
    const activeExpansion = newExpandedNodes.find((m) => m.nodeId === node.id && m.direction === direction);
    const isFullyExpanded = activeExpansion?.depth === null;
    const isDirectlyExpanded = activeExpansion && !isFullyExpanded;
    if (!activeExpansion) {
      newExpandedNodes.push({ nodeId: node.id, direction, depth });
    }
    else if (isDirectlyExpanded && depth === null) {
      activeExpansion.depth = depth;
    }
    else {
      newExpandedNodes.splice(newExpandedNodes.indexOf(activeExpansion), 1);
    }
    setExpandedNodes(newExpandedNodes);
    trackEvent(events.dagExpanded, { direction, node_type: node.type });
  }, [expandedNodes, setExpandedNodes]);

  useEffect(() => {
    if (selectedNode) {
      scrollToNode(selectedNode.id);
    }
  }, [selectedNode, nodesPositions]);

  const filtersBarHeight =
    ((document.querySelector('#discoverToolbar')?.getBoundingClientRect().height || 0) / window.innerHeight) * 100;

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (clickOutsideNode(e)) {
      setSelectedNode(null);
    }
  };

  shutdown();

  if (isLoading || isLoadingPositions) {
    return <PageLoader />;
  }

  if (dagTooLarge) {
    return <DataModelTooLargeScreen />;
  }


  return (
    <div className="absolute" style={{ height: `${100 - filtersBarHeight}vh`, width: `${100 - menuWidth}vw` }} onClick={onContainerClick}>
      <DiscoverDAGCanvas
        onCollapse={onCollapse}
        criticalPath={criticalPath}
        nodes={nodes}
        setSelectedNode={setSelectedNode}
        setShowNodeSidepane={setShowNodeSidepane}
        selectedNode={selectedNode}
        nodesPositions={nodesPositions}
        withFilters={withFilters}
        expandedNodes={expandedNodes}
      />
    </div>
  );
};

const scrollToNode = (nodeId: string) => {
  const node = document.querySelector(`#${getNodeIdForDom(nodeId)}`);
  if (node) {
    node.scrollIntoView({ block: 'center', inline: 'center' });
  }
};

const clickOutsideNode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  let target: HTMLElement | null = e.target as HTMLElement;
  while (target) {
    if (target.id && target.id.includes('swimlane')) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
};

const nodeTypeToClusterOrder = new Map<NodeType, number>([
  [NodeType.DataSource, 0],
  [NodeType.DataModel, 1],
  [NodeType.Metric, 1],
  [NodeType.Table, 1],
  [NodeType.GenericDataTransformation, 1],
  [NodeType.LookerView, 2],
  [NodeType.LookerDerivedView, 2],
  [NodeType.LookerExplore, 3],
  [NodeType.LookerLook, 4],
  [NodeType.LookerTile, 4],
  [NodeType.LookerDashboard, 5],
  [NodeType.TableauCustomQuery, 2],
  [NodeType.TableauEmbeddedDataSource, 2],
  [NodeType.TableauPublishedDataSource, 2],
  [NodeType.TableauView, 3],
  [NodeType.TableauDashboard, 4],
  [NodeType.TableauStory, 4],
]);
