import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, LinkIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "src/components/DropdownMenu";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { IExpandedNode, NodeType } from "src/features/models/discover/INode";

export const NodeSidepaneMenu = ({ node }: { node: IExpandedNode }) => {
    const navigate = useNavigate();

    const items = [
        {
            name: 'Copy link',
            icon: <LinkIcon width="16" height="16" />,
            onClick: () => navigator.clipboard.writeText(`${window.location.origin}${getNodeUrl(node.id)}`)
        },
        {
            name: 'Isolate item',
            icon: <ViewfinderCircleIcon width="16" height="16" />,
            onClick: () => node.type === NodeType.TableauWorkbook ? navigate(`/data-model?Tableau+workbook=${node.name}&view=graph`) : navigate(getNodeUrl(node.id))
        },
        {
            name: 'Show upstream dependencies',
            icon: <ChevronDoubleLeftIcon width="16" height="16" />,
            onClick: () => navigate(`/data-model?view=table&Upstream dependencies=${node.id}`)
        },
        {
            name: 'Show downstream dependents',
            icon: <ChevronDoubleRightIcon width="16" height="16" />,
            onClick: () => navigate(`/data-model?view=table&Downstream dependents=${node.id}`)
        }
    ];
    return (
        <DropdownMenu items={items} className="-ml-48 w-52 bg-white" />
    );
};
