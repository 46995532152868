import { store } from "../../../../../infrastructure/state/store";
import { nodesApi } from "../../../../../services/nodes/nodes";
import { ISuperficialNode, isNodeHiddenFromDag } from "../../INode";
import { maxNodesForDag } from "./DAGConfiguration";
import { ExpandedNode, IDAGNode } from "./types";

type GetNodesForDAGProps = {
    accountId: number;
    eql: string;
}

export const getDAGNodes = async ({ accountId, eql }: GetNodesForDAGProps): Promise<{newNodes: IDAGNode[], filteredNodes: ISuperficialNode[]}> => {
    const originalNodes = await getOriginalNodes({ accountId, eql });
    const { filteredNodes, nodes } = originalNodes.reduce((agg: { filteredNodes: ISuperficialNode[], nodes: ISuperficialNode[] }, node: ISuperficialNode) => {
              if (isNodeHiddenFromDag(node)) {
                   return { filteredNodes: [...agg.filteredNodes, node], nodes: agg.nodes };
              } else {
                   return { filteredNodes: agg.filteredNodes, nodes: [...agg.nodes, node] };
              } 
          }, { filteredNodes: [], nodes: [] });
    const distinctNodes = nodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
    return { newNodes: distinctNodes.map(n => ({ ...n, isConnectedNode: false })), filteredNodes };
};

export const getConnectedNodes = async ({ accountId, expandedNodes }: { accountId: number, expandedNodes: ExpandedNode[] }): Promise<IDAGNode[]> => {
    const nodes = await Promise.all(expandedNodes.map(async (expandedNode) => {
        const directionEql = expandedNode.direction === 'upstream' ? 'downstream' : 'upstream';
        const eql = `has ${directionEql}(uri='${expandedNode.nodeId}', ${expandedNode.depth || 100})`;
        const { data } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
          accountId,
          eql,
          page: 1,
          pageSize: 1000
        }));
        const nodes = data?.items || [];
        return nodes;
    }));
    return nodes.flat().filter(Boolean).map(n => ({ ...n, isConnectedNode: true }) as IDAGNode);
};

type GetOriginalNodesProps = {
    accountId: number;
    eql: string;
}

const getOriginalNodes =  async ({ accountId, eql }: GetOriginalNodesProps) => {
    const { data } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
        accountId,
        eql,
        page: 1,
        pageSize: maxNodesForDag + 1
    }));
    return (data?.items || []);
};
