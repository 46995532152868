import { NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType } from "../types";

export const generateAggregateTableColumn = (column: Partial<NewAggregateTableChangeColumn> = {}): NewAggregateTableChangeColumn => {
    return {
        name: column.name || '',
        alias: column.alias || null,
        description: column.description || '',
        meta: column.meta || [],
        tags: column.tags || [],
        isUnique: column.isUnique || false,
        isNullable: column.isNullable || false,
        acceptedValues: column.acceptedValues || [],
        relationships: column.relationships || null,
        isTimeField: column.isTimeField || false,
        timeGranularity: column.timeGranularity || null,
        parentEntity: column.parentEntity || null,
        type: column.type || NewAggregateTableChangeColumnType.entity,
        dataType: column.dataType || null,
        entityPath: column.entityPath || null
    };
};