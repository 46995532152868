import { XMarkIcon } from "@heroicons/react/24/solid";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";


interface ActiveFiltersProps {
  filters: Filter[];
  removeFilter: (name: string) => void;
  setFilterToShow: (filter: Filter) => void;
}

export const ActiveFilters = ({ filters, removeFilter, setFilterToShow }: ActiveFiltersProps) => {
  const filtersToShow = filters.filter((f) => !f.isDisabled && !!f.value);
  return (
    <>
      {filtersToShow.map((f) => (
        <div
          key={f.name}
          className="mb-auto mt-auto flex h-6 items-center justify-between gap-1 rounded-2xl bg-muse-100 px-1 text-muse-700">
          <div className="flex cursor-pointer items-center gap-1 whitespace-nowrap" onClick={() => setFilterToShow(f)}>
            <f.badge value={f.value} />
          </div>
          <XMarkIcon
            width="16"
            height="16"
            className="ml-1 cursor-pointer text-muse-400 hover:text-muse-600"
            onClick={() => removeFilter(f.name)}
            id='remove-filter-button'
          />
        </div>
      ))}
    </>
  );
};