export type TableColumnProps = {
    name: string;
    property?: string;
    sortBy?: string;
    width?: string;
    grow?: number;
    maxWidth?: string;
    isNotRemovable?: boolean;
    headerComponent?: JSX.Element;
    compact?: boolean;
    selector?: (
        row: unknown,
        index: number | undefined
    ) => any; /* eslint-disable-line @typescript-eslint/no-explicit-any */ /* disabled due to react-data-table-component only accepting Primitives in their types */
}

export type PaginationProps = {
    page: number;
    pageSize: number;
    total: number;
    setPage: (page: number) => void;
    setPageSize: (page: number) => void;
}

export enum SavedTableConfigKeys {
    columns = 'columns'
}
