import Markdown from 'react-markdown';
import { useState, PropsWithChildren } from "react";

interface Props {
    text: string;
    maxChars: number;
    withMarkdown?: boolean;
}

export const ExpandableText = ({ text, maxChars, withMarkdown }: Props) => {
    const [showMore, setShowMore] = useState(false);
    const exceededMaxChar = text?.length > maxChars;
    let textWithEllipsis: string | JSX.Element = exceededMaxChar && !showMore ? `${text.substring(0, maxChars)}...` : text;
    if (withMarkdown) {
        textWithEllipsis = (
            <Markdown
                components={{
                    a: (props:PropsWithChildren) => (
                        <a {...props} className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                            {props.children}
                        </a>
                    )
                 }}
            >
                {textWithEllipsis}
            </Markdown>
        );
    }
    return (
        <>
            { textWithEllipsis }
            {
                exceededMaxChar && (
                    <span className="cursor-pointer text-tertiary font-medium" onClick={() => setShowMore(!showMore)}> {showMore ? 'Show less' : 'Show more'}</span>
                )
            }
        </>
    );
};
