import { SparklesLeftArrowIcon } from "../../assets/images/icons/DelphiIcons";
import { NodeType, SubnodeType } from "../models/discover/INode";
import { ImplicationNode } from "./formSections/ImplicationNode";

type ModelChangeImplicationsProps = {
  targetSubnodeType?: SubnodeType;
  targetSubnodeName?: string;
  targetNodeName: string;
  sourceUtl?: string;
  sourceName: string;
  sourceSubnodeName?: string;
  sourceType: NodeType;
  sourceSubnodeType?: SubnodeType;
}

export const ModelChangeImplications = ({ targetSubnodeType, targetSubnodeName, targetNodeName, sourceUtl, sourceName, sourceSubnodeName, sourceType, sourceSubnodeType }: ModelChangeImplicationsProps) => {
    return (
      <div className="flex gap-4 flex-1">
        <ImplicationNode nodeName={targetNodeName} subNodeName={targetSubnodeName} nodeType={NodeType.DataModel} subNodeType={targetSubnodeType} isNew={true} />
        <div className="self-center text-tertiary">
          <SparklesLeftArrowIcon width="22" height="22" fill="#0047FF"/>
        </div>
        <ImplicationNode utl={sourceUtl} nodeName={sourceName} subNodeName={sourceSubnodeName} nodeType={sourceType} subNodeType={sourceSubnodeType} isNew={false} />
      </div>
    );
  };