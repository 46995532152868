import { useEffect, useMemo, useState } from "react";
import { IExpandedNode, ISubnode, SubnodeType, subnodeTypePlurals } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { NodeSidepane } from "src/features/models/discover/sidepane/NodeSidepane/NodeSidepane";
import { SparklesIcon } from "@heroicons/react/24/solid";

type ModelSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const ModelSidepane = ({ node, setCanCloseModal }: ModelSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        SubNodesListComponent={SubNodesList}
    />;
};


type SubNodesListProps = {
    subnodes: ISubnode[];
    onClick: (subnode: ISubnode) => void;
    parentNode: IExpandedNode;
}

enum SubnodeCategory {
    Columns = 'Columns',
    Semantics = 'Semantics'
}

const SubNodesList = ({ subnodes, onClick, parentNode }: SubNodesListProps) => {
    const [activeCategory, setActiveCategory] = useState<SubnodeCategory>(SubnodeCategory.Columns);
    const types = activeCategory === SubnodeCategory.Columns ? [SubnodeType.Column] : [SubnodeType.Dimension, SubnodeType.Measure, SubnodeType.CustomField, SubnodeType.Schema, SubnodeType.Metric, SubnodeType.Entity, SubnodeType.TableCalculation];
    const hasSemantics = useMemo(() => subnodes.some(n => n.type !== SubnodeType.Column), [subnodes]);
    const hasColumns = useMemo(() => subnodes.some(n => n.type === SubnodeType.Column), [subnodes]);
    const hasOnlyOneCategory = !hasSemantics || !hasColumns;

    //Show semantics by default if there are no columns
    useEffect(() => {
        if (activeCategory === SubnodeCategory.Columns && !hasColumns) {
            setActiveCategory(SubnodeCategory.Semantics);
        }
    }, [activeCategory, setActiveCategory, hasColumns]);

    if (subnodes.length === 0) {
        return null;
    }
    return (
        <div className="mt-4">
            {
                !hasOnlyOneCategory && (
                    <SubnodesListTabs activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
                )
            }
            <div className="mt-3">
                {
                    types.map(type => {
                        const filteredNodes = subnodes.filter(n => n.type === type);
                        if (filteredNodes.length === 0) {
                            return null;
                        }
                        return (
                            <div key={type}>
                                {
                                    (type !== SubnodeType.Column || hasOnlyOneCategory) && (
                                        <div className="flex gap-1 items-end my-2">
                                            <div className="text-tertiary">{subnodeTypePlurals[type]}</div>
                                            <div className="text-slate-400 text-sm">{filteredNodes.length}</div>
                                        </div>
                                    )
                                }
                                {subnodes.filter(n => n.type === type).map((subnode, index) => <SubnodesListRow key={index} parentNode={parentNode} subnode={subnode} onClick={onClick} />)}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

type SubnodesListTabsProps = {
    activeCategory: SubnodeCategory;
    setActiveCategory: (category: SubnodeCategory) => void;
}

const SubnodesListTabs = ({ activeCategory, setActiveCategory }: SubnodesListTabsProps) => {
    return (
        <div className="rounded p-0.5 bg-slate-200 text-secondary font-light flex text-sm gap-0.5 overflow-auto">
            {
                [SubnodeCategory.Columns, SubnodeCategory.Semantics].map((category) => {
                    const isActive = category === activeCategory;
                    const background = isActive ? 'bg-white' : 'bg-slate-200';
                    return (
                        <div
                            key={category}
                            className={`w-full text-center whitespace-nowrap cursor-pointer rounded px-1 py-1 ${background} hover:bg-white`}
                            onClick={() => setActiveCategory(category)}>
                            {category}
                        </div>
                    );
                })
            }
        </div>
    );
};

type SubnodesListRowProps = {
    parentNode: IExpandedNode;
    subnode: ISubnode;
    onClick: (subnode: ISubnode) => void;
}

const SubnodesListRow = ({ parentNode, subnode, onClick }: SubnodesListRowProps) => {
    return (
        <div
            className="items-center flex cursor-pointer justify-between p-1 font-light border-b border-slate-100 hover:bg-slate-100 gap-1"
            onClick={() => onClick(subnode)}
        >
            <div className="flex gap-1 items-center overflow-hidden">
                <NodeIcon type={parentNode.type} subnodeType={subnode.type} />
                <span className="text-sm text-secondary whitespace-nowrap overflow-hidden">
                    <TextWithEllipsisAndTooltip maxChars={35} text={subnode.name} />
                </span>
                {
                    subnode.hasProposals && (
                        <SparklesIcon width="14" height="14" className="text-surface-primary" />
                    )
                }
            </div>
            <span className="text-xs text-tertiary">{subnode.subType}</span>
        </div>
    );
};