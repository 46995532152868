import { XCircleIcon } from '@heroicons/react/24/solid';

const FormError = ({ errorMessage, onClose }: { errorMessage: string; onClose: () => void }) => {
  return (
    <div className="flex mt-6 w-full bg-danger-light p-4 text-danger">
      <div className="cursor-pointer mr-2" onClick={onClose}>
        <XCircleIcon width="20" height="20" />
      </div>
      <div>
        <span>{errorMessage}</span>
      </div>
    </div>
  );
};

export default FormError;
