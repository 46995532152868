import { useEffect, useRef } from 'react';
import { useClickAway, useKeyPress } from 'react-use';
import { events, trackEvent } from '../../../infrastructure/analytics';
import { Filter } from './types';

interface FilterPanelProps {
  filters: Filter[];
  updateFilters: (filters: Filter[]) => void;
  filterToShow: Filter | null;
  setFilterToShow: (filter: Filter | null) => void;
}

export const FilterPanel = ({ filters, updateFilters, filterToShow, setFilterToShow }: FilterPanelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escapePressed = useKeyPress('Escape');
  
  useClickAway(ref, () => {
    setFilterToShow(null);
  });

  useEffect(() => {
    if (escapePressed[0]) {
      setFilterToShow(null);
    }
  }, [escapePressed, setFilterToShow]);

  const onFilterChange = (value: string | null) => {
    if (filterToShow) {
      setFilterToShow({ ...filterToShow, value });
    }
  };

  const onSave = (value?: string | null) => {
    if (filterToShow) {
      const newValue = value === undefined ? filterToShow.value : value;
      const newFilters = filters.map((f) => (f.name === filterToShow.name ? { ...filterToShow, value: newValue } : f));
      updateFilters(newFilters);
      trackEvent(events.dataModelFilterAdded, { name: filterToShow.name, value });
    }
  };

  if (!filterToShow || !filterToShow.component) {
    return null;
  }

  return (
    <div className="absolute top-0 z-20 rounded-md border border-slate-200 bg-white shadow" ref={ref}>
      <div>
        {
          <filterToShow.component
            onSave={onSave}
            onClose={() => setFilterToShow(null)}
            value={filterToShow.value}
            onChange={onFilterChange}
          />
        }
      </div>
    </div>
  );
};