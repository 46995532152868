import { TagIcon, FolderIcon, ArrowLongRightIcon, CodeBracketIcon } from '@heroicons/react/24/solid';
import { useState, useEffect } from 'react';
import { BranchIcon, LookerIcon } from '../../../../../assets/images/icons/DelphiIcons';
import Button from '../../../../../components/button/Button';
import { ButtonTypes } from '../../../../../components/button/types';
import Select, { Option } from '../../../../../components/form/Select';
import IRepository from '../../../IRepository';
import IBranch, { IBranchSyncConfiguration } from '../../IBranch';
import Input from '../../../../../components/form/Input';
import RadioButton from '../../../../../components/form/RadioButton';
import { SQLDialect } from '../../../IProject';
import { ProjectRepositoryOption, ProjectRepositoryOptionType, projectRepositoryOptions } from '../../../repositoryProviders/projectRepositoryOptions';
import { GithubProjectConfiguration } from '../../../repositoryProviders/GithubProjectConfiguration';
import { FormField } from '../../../../../components/form/FormField';
import { GitlabProjectConfiguration } from '../../../repositoryProviders/GitlabProjectConfiguration';

interface SyncConfigurationFormProps {
  onClose: () => void;
  branch: IBranch;
  showPreview: (syncConfiguration: IBranchSyncConfiguration) => void;
}

const SyncConfigurationForm = ({ onClose, branch, showPreview }: SyncConfigurationFormProps) => {
  const [targetRepository, setTargetRepository] = useState<IRepository | null>(null);
  const [targetBranch, setTargetBranch] = useState('');
  const [directory, setDirectory] = useState('/dbt_sync');
  const [sqlDialect, setSqlDialect] = useState<SQLDialect>('snowflake');
  const [tags, setTags] = useState<string[]>([]);
  const [withPr, setWithPr] = useState(true);
  const [error, setError] = useState('');
  const [repositoryProvider, setRepositoryProvider] = useState<ProjectRepositoryOption>(projectRepositoryOptions[0]);

  const onNext = () => {
    const newSyncConfiguration = {
      targetBranch: targetBranch,
      targetRepository: targetRepository?.cloneUrl || '',
      targetDirectory: directory,
      tags,
      withPr: withPr,
      sourceBranch: branch.name,
      sqlDialect: sqlDialect
    };
    const errorMesssage = verifySyncConfiguration(newSyncConfiguration);
    if (errorMesssage) {
      setError(errorMesssage);
    } else {
      showPreview(newSyncConfiguration);
    }
  };

  useEffect(() => {
    setError('');
  }, [targetRepository, targetBranch, directory, tags, withPr, setError]);

  return (
    <div className="flex flex-col gap-4 text-secondary">
      <div className="p-1 bg-slate-200 mb-2 rounded-md w-fit text-secondary text-sm mx-auto">
        {
          projectRepositoryOptions.map((provider) => (
            <button
              key={provider.name}
              onClick={() => setRepositoryProvider(provider)}
              className={`py-1 px-6 rounded-md cursor-pointer ${repositoryProvider === provider ? 'bg-white' : 'bg-slate-200'}`}
              title={provider.displayName}
            >
              {provider.displayName}
            </button>
          ))
        }
      </div>
      <ProcessExplainer branch={branch} />
      {
        repositoryProvider.name === ProjectRepositoryOptionType.GITHUB && (
          <GithubProjectConfiguration repository={targetRepository} setRepository={setTargetRepository} branch={targetBranch} setBranch={setTargetBranch} />
        )
      }
      {
        repositoryProvider.name === ProjectRepositoryOptionType.GITLAB && (
          <GitlabProjectConfiguration setRepository={setTargetRepository} branch={targetBranch} setBranch={setTargetBranch} />
        )
      }
      <DirectorySelection directory={directory} setDirectory={setDirectory} />
      <SQLDialectSelection sqlDialect={sqlDialect} setSqlDialect={setSqlDialect} />
      <TagsSelection tags={tags} setTags={setTags} />
      <WithPrSelection withPr={withPr} setWithPr={setWithPr} />
      {error && <span className="text-sm text-danger">{error}</span>}
      <div className="ml-auto mt-5 flex w-fit gap-2">
        <Button type={ButtonTypes.secondary} onClick={onClose} className="w-36" text="Cancel" />
        <Button type={ButtonTypes.primary} onClick={onNext} className="w-36" text="Next" />
      </div>
    </div>
  );
};

const ProcessExplainer = ({ branch }: { branch: IBranch }) => {
  return (
    <div className="mb-3 rounded border border-slate-200 bg-surface-light py-4 px-2">
      <div className="justify-centertext-text-primary mx-auto flex w-fit">
        <div className="flex w-fit px-4 items-center justify-center gap-1 rounded-full border border-slate-200 bg-white py-1.5">
          <BranchIcon width="14" height="14" fill="#0A225C" />
          {branch.name} branch
        </div>
        <div className="mx-0.5 my-auto flex text-surface-primary">
          <ArrowLongRightIcon width="22" height="22" />
        </div>
        <div className="flex w-fit px-4 items-center justify-center gap-1 rounded-full border border-slate-200 bg-white py-1.5">
          <LookerIcon width="16" height="16" />
          LookML Repository
        </div>
      </div>
      <div className="mt-4 text-center text-sm text-tertiary">
        Configure LookML destination to automatically keep your Looker model consistent with a branch of your dbt
        project.
      </div>
    </div>
  );
};

const verifySyncConfiguration = (syncConfiguration: IBranchSyncConfiguration) => {
  if (!syncConfiguration.targetRepository) {
    return 'Target repository is required';
  }
  if (!syncConfiguration.targetBranch) {
    return 'Target branch is required';
  }
  if (!syncConfiguration.targetDirectory) {
    return 'Target directory is required';
  }
  return '';
};

const WithPrSelection = ({ withPr, setWithPr }: { withPr: boolean; setWithPr: (withPr: boolean) => void }) => {
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="mt-1">
          <RadioButton value={withPr} setValue={() => setWithPr(true)} />
        </div>
        <div>
          <div>Create pull request</div>
          <div className="text-sm text-tertiary">Changes will need to be merged to take effect.</div>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="mt-1">
          <RadioButton value={!withPr} setValue={() => setWithPr(false)} />
        </div>
        <div>
          <div>Commit to branch</div>
          <div className="text-sm text-tertiary">Changes will automatically be pushed without review.</div>
        </div>
      </div>
    </div>
  );
};

const TagsSelection = ({ tags, setTags }: { tags: string[]; setTags: (tags: string[]) => void }) => {
  const tagOptions = tags.map((tag) => ({ label: tag, value: tag }));
  const onChange = (options: Option | Option[]) => {
    setTags((options as Option[]).map((option) => option.value.toString()));
  };
  return (
    <FormField label="Selective sync">
      <Select
        value={tags}
        options={tagOptions}
        placeholder="Select tags"
        isCreatable
        isMulti
        onChange={onChange}
        className="border-grey-300 w-full rounded-md border shadow"
        icon={<TagIcon width="16" height="16" className="text-gray-400" />}
      />
    </FormField>
  );
};

const DirectorySelection = ({
  directory,
  setDirectory
}: {
  directory: string;
  setDirectory: (directory: string) => void;
}) => {
  return (
    <FormField label="Target directory" helper="The target directory where Delphi will synchronize LookML changes. If no target directory exists, Delphi will
        create it during the initial sync.">
      <Input
        value={directory}
        onInputChange={(e) => setDirectory(e.target.value)}
        placeholder="Directory"
        icon={<FolderIcon width="16" height="16" className="text-gray-400" />}
      />
    </FormField>
  );
};

interface SQLDialectSelectionProps {
  sqlDialect: SQLDialect;
  setSqlDialect: (dialect: SQLDialect) => void;
}

const sqlDialectOptions = [
  { label: 'spark', value: 'spark' },
  { label: 'databricks', value: 'databricks' },
  { label: 'snowflake', value: 'snowflake' },
  { label: 'presto', value: 'presto' }
];

const SQLDialectSelection = ({ sqlDialect, setSqlDialect }: SQLDialectSelectionProps) => {
  const onSqlDialectSelected = (option: Option | Option[]) => {
    const sqlDialect = (option as Option).value as SQLDialect;
    setSqlDialect(sqlDialect);
  };

  return (
    <FormField label='SQL dialect'>
      <Select
        options={sqlDialectOptions}
        value={sqlDialect}
        onChange={onSqlDialectSelected}
        placeholder="Select an SQL dialect"
        className="border-grey-300 w-full rounded-md border shadow"
        icon={<CodeBracketIcon width="16" height="16" fill="#94A3B8" />}
        dataTestId="target-sql-dialect-select"
      />
    </FormField>
  );
};

export default SyncConfigurationForm;
