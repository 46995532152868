import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { TRACE_HEADER } from '../services/consts';
import { Environment, getCurrentEnv } from './env';

export const init = () => {
  const env = getCurrentEnv();
  if ([Environment.Testing, Environment.Local].includes(env)) {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({
        networkRequestHeaders: [TRACE_HEADER],
        networkResponseHeaders: [TRACE_HEADER]
      })
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^\//],
    environment: env,
    replaysSessionSampleRate: env === 'production' ? 0.1 : 0,
    replaysOnErrorSampleRate: env === 'production' ? 1.0 : 0
  });
};

export const identify = (userParams: object) => {
  Sentry.setUser(userParams);
};