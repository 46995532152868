import { ColumnTestsForm } from '../../formSections/ColumnTestsForm';
import { ColumnTests, NewColumnChangeData } from '../../IChange';
import { TagsForm } from '../../formSections/TagsForm';
import { MetaForm } from '../../formSections/MetaForm';
import { DescriptionForm } from '../../formSections/DescriptionForm';
import { NameForm } from '../../formSections/NameForm';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { ModelChangeImplications } from '../../ModelChangeImplications';

interface NewColumnFormProps {
  changeData: NewColumnChangeData;
  setChangeData: (changeData: NewColumnChangeData) => void;
  sourceName: string;
  sourceUtl: string;
}

const NewColumnForm = ({ changeData, setChangeData, sourceName, sourceUtl }: NewColumnFormProps) => {
  const setTests = (tests: ColumnTests) => setChangeData({ ...changeData, ...tests });
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center mb-2 gap-2">
        <div className="text-secondary text-sm font-medium w-24">Model changes</div>
        <ModelChangeImplications
          targetNodeName={changeData.modelId}
          sourceName={sourceName}
          sourceType={NodeType.LookerView}
          sourceUtl={sourceUtl}
          targetSubnodeName={changeData.newColumnName}
          targetSubnodeType={SubnodeType.Column}
          sourceSubnodeName={changeData.customFieldInDataApplication}
          sourceSubnodeType={SubnodeType.Column}
        />
      </div>
      <NameForm label="Column" name={changeData.newColumnName} setName={newColumnName => setChangeData({ ...changeData, newColumnName })} />
      <DescriptionForm label="Column" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm label="Column" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm label="Column" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
      <ColumnTestsForm tests={changeData} setTests={setTests} />
    </div>
  );
};

export default NewColumnForm;
