import { NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../types";

type ChangeForEnrichment = {
  title: string;
  description: string;
}

export const onBeforeSubmitAggregateTableChange = <T>(change: ChangeForEnrichment & T, changeData: NewAggregateTableChangeData): T => {
  return {
    ...change,
    title: change.title.replace('{model_name}', changeData.newTableName),
    description: change.description.replace('{metrics}', changeData.columns.filter(c => c.type === NewAggregateTableChangeColumnType.metric).map(c => c.name).join(', ')),
    changeData: {
      ...changeData,
      columns: changeData.columns.filter(c => c.name).map(c => ({
        ...c,
        meta: c.meta.filter(m => m.key && m.value)
      }))
    }
  };
};