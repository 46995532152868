import { XCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { LogsContainer } from "../../components/LogsContainer";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/button/Button";
import { ButtonTypes } from "../../components/button/types";

export const CompilationError = ({ error }: { error: string }) => {
    const [showCompilationErrorModal, setShowCompilationErrorModal] = useState(false);
    const onClose = () => setShowCompilationErrorModal(false);
    if (!error) return null;
    return (
      <div className="flex gap-4 text-sm items-center">
        <div className="flex gap-2 text-danger"><XCircleIcon width={16} height={16} /> Something went wrong with the configuration.</div>
        <Button type={ButtonTypes.secondary} text="View details" onClick={() => setShowCompilationErrorModal(true)} />
        <Modal
          title="MetricFlow compilation error"
          isOpen={showCompilationErrorModal}
          onClose={onClose}
          buttons={[{ type: ButtonTypes.primary, text: 'Close', onClick: onClose }]}
        >
          <LogsContainer>{error}</LogsContainer>
        </Modal>
      </div>
    );
  };