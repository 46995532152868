import { SparklesIcon } from '@heroicons/react/24/solid';
import { ChangeType, SuperficialPat } from '../IChange';
import Table from '../../../components/Table/Table';
import { TextWithEllipsisAndTooltip } from '../../../components/TextWithEllipsisAndTooltip';
import { DbtCloudIcon, LookerIcon } from '../../../assets/images/icons/DelphiIcons';
import { getPats, useLazyGetPatQuery } from '../../../services/changes/changes';
import CreateChangeSidepane from '../createChangeSidepane/CreateChangeSidepane';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { useGetDamaRevisionQuery } from '../../../services/accounts/accounts';
import { dateFilterToTimeRange, DateFilterValue } from './filters/dateFilter';
import { DataModelFilters } from './filters/DataModelFilters';
import { DataModelFilter } from './Types';
import { dateFormats, unixToLocal } from '../../../infrastructure/dateUtilities';
import { mapLocalChangeTypeToBackendChangeType } from '../../../services/changes/transformers';
import { FilterValue } from '../../../components/Table/TableFilters/types';

export const PatsList = () => {
  const [getPatQuery, selectedPat] = useLazyGetPatQuery();
  const accountId = useSelector(selectActiveAccountId);
  const [showCreateChangeSidepane, setShowCreateChangeSidepane] = useState(false);
  const getDamaRevision = useGetDamaRevisionQuery({ accountId });
  const [isLoading, setIsLoading] = useState(true);
  const [pats, setPats] = useState<SuperficialPat[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>({});
  const [searchInput, setSearchInput] = useState('');
  const [projectId, setProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (getDamaRevision.data && projectId) {
      setIsLoading(true);
      const filterString = createAlgoliaFilterString(filterValues);
      getPats({
        query: searchInput,
        applicationId: getDamaRevision.data.algoliaAppId,
        apiKey: getDamaRevision.data.algoliaKey,
        indexName: getDamaRevision.data.algoliaIndex,
        page: page - 1,
        pageSize,
        filters: filterString,
        projectId
      }).then(({ items, total }) => {
        setPats(items);
        setTotal(total);
      }).finally(() => setIsLoading(false));
    }
  }, [getDamaRevision.data, filterValues, page, pageSize, projectId, searchInput]);

  const onRowClicked = (row: unknown) => {
    const pat = row as SuperficialPat;
    setShowCreateChangeSidepane(true);
    getPatQuery({ accountId, uri: pat.uri });
  };

  return (
    <div>
      <DataModelFilters
        tableName='pats-table'
        onFilterValuesChange={setFilterValues}
        showSort={false}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedProjectId={projectId}
        setSelectedProjectId={setProjectId}
      />
      <div className="mt-4 max-w-[78vw]">
        <Table isLoading={isLoading || selectedPat.isLoading} data={pats} columns={tableColumns} onRowClicked={onRowClicked} maxBodyHeight='72vh' pagination={{ page, pageSize, total, setPage, setPageSize }} />
      </div>
      {showCreateChangeSidepane && !selectedPat.isFetching && selectedPat.data && (
        <CreateChangeSidepane
          isOpen={showCreateChangeSidepane}
          onClose={() => setShowCreateChangeSidepane(false)}
          pat={selectedPat.data}
        />
      )}
    </div>
  );
};

const SourceColumn = (row: unknown) => {
  const pat = row as SuperficialPat;
  const sourceName = pat.sourceNode.split('.').pop() || '';
  let icon;
  switch (pat.type) {
    case ChangeType.NewAggregateTable:
      icon = <DbtCloudIcon width="16" height="16" fill="#FF694A" />;
      break;
    default:
      icon = <LookerIcon width="16" height="16" />;
      break;
  }
  return (
    <div className="flex gap-1 text-black" data-tag="allowRowEvents">
      <div>{icon}</div>
      <TextWithEllipsisAndTooltip text={sourceName} maxChars={20} />
    </div>
  );
};

const tableColumns = [
  {
    name: 'TYPE',
    selector: (row: unknown) => (
      <div className="flex gap-1 uppercase" data-tag="allowRowEvents">
        <div><SparklesIcon width="16" height="16" /></div><div>{(row as SuperficialPat).type}</div>
      </div>
    ),
    width: '200px'
  },
  {
    name: 'TITLE',
    selector: (row: unknown) => <span className="text-text-primary" data-tag="allowRowEvents"><TextWithEllipsisAndTooltip text={(row as SuperficialPat).title} maxChars={100} /></span>,
    width: 'auto'
  },
  {
    name: 'SOURCE',
    selector: SourceColumn,
    width: '250px'
  },
  {
    name: 'USAGE 14D',
    selector: (row: unknown) => (row as SuperficialPat).usage14 || 'N/A',
    width: '200px'
  },
  {
    name: 'USAGE 30D',
    selector: (row: unknown) => (row as SuperficialPat).usage30 || 'N/A',
    width: '200px'
  },
  {
    name: 'USAGE 60D',
    selector: (row: unknown) => (row as SuperficialPat).usage60 || 'N/A',
    width: '200px'
  },
  {
    name: 'DATE',
    selector: (row: unknown) => unixToLocal((row as SuperficialPat).date, dateFormats.dateHoursAndMinutes),
    width: '200px'
  }
];

const createAlgoliaFilterString = (filterValues: Record<string, FilterValue>) => {
  const algoliaFilters: string[] = [];
  for (const key in filterValues) {
    const value = filterValues[key];
    const { start, end } = dateFilterToTimeRange((value || null) as DateFilterValue, false);
    switch (key) {
      case DataModelFilter.usage14d:
        algoliaFilters.push(mapUsageToAlgoliaFilter14d(value));
        break;
      case DataModelFilter.usage30d:
        algoliaFilters.push(mapUsageToAlgoliaFilter30d(value));
        break;
      case DataModelFilter.usage60d:
        algoliaFilters.push(mapUsageToAlgoliaFilter60d(value));
        break;
      case DataModelFilter.owner:
        algoliaFilters.push(`shift_left_owner:${value}`);
        break;
      case DataModelFilter.createdAt:
        if (start) {
          algoliaFilters.push(`shift_left_date>${start}`);
        }
        if (end) {
          algoliaFilters.push(`shift_left_date<${end}`);
        }
        break;
      case DataModelFilter.type:
        algoliaFilters.push(`(${value?.split(',').map(v => `shift_left_type:${mapLocalChangeTypeToBackendChangeType.get(v as ChangeType)}`).join(' OR ')})`);
        break;
    }
  }
  const filterString = algoliaFilters.join(' AND ');
  return filterString;
};


const mapUsageToAlgoliaFilter14d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_14d = 0';
    case '1-100':
      return 'total_queries_14d:1 TO 100';
    case '101-500':
      return 'total_queries_14d:101 TO 500';
    case '501-1000':
      return 'total_queries_14d:501 TO 1000';
    case '1001-5000':
      return 'total_queries_14d:1001 TO 5000';
    case '5001-10000':
      return 'total_queries_14d:5001 TO 10000';
    case '10001+':
      return 'total_queries_14d > 10000';
    default:
      return '';
  }
};

const mapUsageToAlgoliaFilter30d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_30d = 0';
    case '1-100':
      return 'total_queries_30d:1 TO 100';
    case '101-500':
      return 'total_queries_30d:101 TO 500';
    case '501-1000':
      return 'total_queries_30d:501 TO 1000';
    case '1001-5000':
      return 'total_queries_30d:1001 TO 5000';
    case '5001-10000':
      return 'total_queries_30d:5001 TO 10000';
    case '10001+':
      return 'total_queries_30d > 10000';
    default:
      return '';
  }
};

const mapUsageToAlgoliaFilter60d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_60d = 0';
    case '1-100':
      return 'total_queries_60d:1 TO 100';
    case '101-500':
      return 'total_queries_60d:101 TO 500';
    case '501-1000':
      return 'total_queries_60d:501 TO 1000';
    case '1001-5000':
      return 'total_queries_60d:1001 TO 5000';
    case '5001-10000':
      return 'total_queries_60d:5001 TO 10000';
    case '10001+':
      return 'total_queries_60d > 10000';
    default:
      return '';
  }
};