import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Select from "src/components/form/Select";
import Textarea from "src/components/form/Textarea";
import { notify } from "src/components/Toaster";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetLiquidConstsQuery, useUpdateLiquidConstsMutation } from "src/services/accounts/accounts";
import { useGetGenericIntegrationsQuery } from "src/services/integrations/integrations";
import { LookerIntegrationConfiguration } from "src/services/integrations/types";

const defaultLiquidConsts = {
    "model_overrides": {},
    "project_overrides": {}
};

export const LiquidConsts = () => {
    const [liquidConsts, setLiquidConsts] = useState<string>("");
    const accountId = useSelector(selectActiveAccountId);
    const getGenericIntegrations = useGetGenericIntegrationsQuery({ accountId });
    const [updateLiquidConsts, { isLoading: isLoadingUpdateLiquidConsts }] = useUpdateLiquidConstsMutation();
    const [selectedLookerIntegrationId, setSelectedLookerIntegrationId] = useState<number>(0);
    const lookerIntegrations = useMemo(() => (getGenericIntegrations.data || []).filter(integration => integration.integration_type === "looker"), [getGenericIntegrations.data]);
    const getLiquidConsts = useGetLiquidConstsQuery({ accountId, lookerIntegrationId: lookerIntegrations[0]?.id || 0 }, { skip: !lookerIntegrations[0] });

    useEffect(() => {
        if (lookerIntegrations.length > 0 && !selectedLookerIntegrationId) {
            setSelectedLookerIntegrationId(lookerIntegrations[0].id);
        }
    }, [lookerIntegrations, selectedLookerIntegrationId]);

    useEffect(() => {
        if (getLiquidConsts.data) {
            setLiquidConsts(JSON.stringify(getLiquidConsts.data, null, 2));
        }
    }, [getLiquidConsts.data]);

    const onUpdateLiquidConstsClick = async () => {
        try {
            JSON.parse(liquidConsts);
        } catch (e) {
            notify('Invalid JSON', 'error');
        }
        try {
            await updateLiquidConsts({ accountId, lookerIntegrationId: lookerIntegrations?.[0].id || 0, liquidConsts: JSON.parse(liquidConsts) });
            notify('Liquid consts updated', 'success');
        } catch (e) {
            notify('Failed to update liquid consts', 'error');
            console.error(e);
        }
    };

    const reset = () => {
        setLiquidConsts(JSON.stringify(defaultLiquidConsts, null, 2));
    };

    return (
        <div className="flex justify-between text-text-primary">
            <div className="w-[400px] flex flex-col gap-4">
                <Select
                    options={lookerIntegrations?.map(integration => ({ value: integration.id, label: (integration.configuration as LookerIntegrationConfiguration).host })) || []}
                    value={selectedLookerIntegrationId}
                    onChange={option => setSelectedLookerIntegrationId((option as { value: number }).value)}
                    placeholder="Select Looker Integration"
                />
                <Textarea rows={4} value={liquidConsts} onChange={e => setLiquidConsts(e.target.value)} />
                <div className="flex gap-2 w-fit ml-auto">
                    <Button type={ButtonTypes.secondary} text="Reset" className="mt-auto" onClick={reset} />
                    <Button isLoading={isLoadingUpdateLiquidConsts || getGenericIntegrations.isLoading} type={ButtonTypes.primary} text="Update" className="mt-auto" onClick={onUpdateLiquidConstsClick} />
                </div>
            </div>
        </div>
    );
};