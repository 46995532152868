import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode, nodeNameMap } from "../../../INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { NodeSidepaneLoader } from "src/features/models/discover/sidepane/NodeSidepane/NodeSidepaneLoader";
import { BackendNodeType } from "src/services/nodes/types";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";

type TableauDashboardOrStorySidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauDashboardOrStorySidepane = ({ node, setCanCloseModal }: TableauDashboardOrStorySidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        SubNodesListComponent={ContainedResources}
    />;
};

export const ContainedResources = ({ parentNode }: { parentNode: IExpandedNode }) => {
    const containedResourceTypes = [BackendNodeType.TableauCustomQuery, BackendNodeType.TableauEmbeddedDataSource, BackendNodeType.TableauView, BackendNodeType.TableauPublishedDataSource];
    const eql = `type IN (${containedResourceTypes.map(t => `'${t}'`).join(',')}) AND HAS downstream(uri='${parentNode.id}')`;
    const accountId = useSelector(selectActiveAccountId);
    const getContainedResourcesQuery = useGetDataModelResourcesQuery({ eql, page: 1, pageSize: 100, accountId });
    const resources = [...(getContainedResourcesQuery.data?.items || [])].sort((a, b) => {
        if (a.type === b.type) {
            return a.name.localeCompare(b.name);
        }
        return a.type.localeCompare(b.type);
    });
    const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);

    if (getContainedResourcesQuery.isFetching) {
        return <ComponentLoader />;
    }

    return (
        <div className="mt-4">
            <div className="mt-2">
                {
                    resources.map(resource => (
                        <div
                            className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
                            onClick={() => setSelectedResourceId(resource.id)}
                            key={resource.id}>
                            <div className="flex items-center gap-1 overflow-hidden">
                                <NodeIcon type={resource.type} />
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                                    {resource.name}
                                </span>
                                {resource.hasProposals && <SparklesIcon width="14" height="14" className="text-surface-primary" />}
                            </div>
                            <span className="text-xs text-tertiary">{nodeNameMap.get(resource.type)}</span>
                        </div>
                    ))
                }
                <NodeSidepaneLoader nodeId={selectedResourceId || ''} isOpen={!!selectedResourceId} onClose={() => setSelectedResourceId(null)} />
            </div>
        </div>
    );
};
