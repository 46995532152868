import { IExpandedNode, ISuperficialNode, MetricDependency } from '../../features/models/discover/INode';
import { transformAlgoliaNodeToLocalNode, transformBackendExpandedNodeToLocalExpandedNode, transformMetricDependenciesResponseToLocal } from './transformers';
import api from '../api';
import { AlgoliaNode, BackendExpandedNodeResponse, CustomProperty } from './types';
import algoliasearch from 'algoliasearch';

export type AlgoliaProps = { applicationId: string; apiKey: string };

const DEFAULT_EQL = 'hide_from_euno_data_model IS NOT TRUE';
type GetNodesProps = AlgoliaProps & {
  indexName: string;
  pageSize?: number;
  page?: number;
  query?: string;
  filters?: string;
}

type GetNodesReponse = Promise<{ items: ISuperficialNode[], total: number }>;

const formatDate = (date: Date) => {
    const pad = (n: number) => (n < 10 ? '0' : '') + n;
    
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-indexed in JS
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;
};

const generateCSVFilename = () => {
    const now = new Date();
    const formattedDate = formatDate(now);
    return `euno-data-model-${formattedDate}.csv`;
};

export const nodesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExpandedNode: build.query<IExpandedNode | null, { accountId: number, nodeId: string }>({
      query: ({ accountId, nodeId }) => {
        const properties = ['name', 'parents', 'subnodes', 'type', 'description', 'tags', 'meta', 'generated_by_delphi', 'is_trivial_sql', 'proposals', 'dbt_materialization_strategy', 'last_30d_views', 'last_7d_views', 'last_accessed_at', 'favourite_count', 'url', 'git_repo_url', 'git_repo_branch', 'last_observed', 'updated_by', 'created_at', 'created_by', 'package_name', 'database', 'database_schema', 'utl', 'parent_name', 'allow_promote', 'raw_code', 'compiled_code', 'semantic_code', 'source_directory', 'looker_model', 'looker_folder', 'looker_project', 'looker_queries', 'dbt_project', 'owner', 'tableau_workbook', 'euno_project_id', 'native_updated_by', 'native_created_by', 'table_schema', 'contained_resources', 'container_chain', 'table_properties', 'has_shift_left_potential', 'tableau_queries_summary', 'database_technology', 'shift_left_project', 'table_dependencies', 'metric_type'];
        const url = `accounts/${accountId}/data_model/nodes/${encodeURIComponent(nodeId)}?properties=${properties.join(',')}`;
        return url;
      },
      transformResponse: (response: BackendExpandedNodeResponse) => {
        return transformBackendExpandedNodeToLocalExpandedNode(response);
      }
    }),
    getMetricsDependencies: build.query<MetricDependency[], { projectId: number, metrics: string[] }>({
      query: ({ projectId, metrics }) => `projects/${projectId}/metrics_projectables?metrics=${metrics.join(',')}`,
      transformResponse: transformMetricDependenciesResponseToLocal
    }),
    getDamaResource: build.query<IExpandedNode, { accountId: number, nodeId: string }>({
      query: ({ accountId, nodeId }) => `accounts/${accountId}/data_model/nodes/${nodeId}`,
      transformResponse: (response: BackendExpandedNodeResponse) => {
        return transformBackendExpandedNodeToLocalExpandedNode(response);
      }
    }),
    getCustomProperties: build.query<CustomProperty[], { accountId: number }>({
      query: ({ accountId }) => {
        return `accounts/${accountId}/custom_properties`;
      },
      transformResponse: (response: CustomProperty[]) => {
        return response.filter(prop => prop.indexed_since_revision_id);
      }
    }),
    getDataModelResources: build.query<{ items: ISuperficialNode[], total: number }, { accountId: number, page: number, pageSize: number, orderBy?: string, orderDirection?: 'asc' | 'desc', eql?: string, additionalProperties?: string[] }>({
      query: ({ accountId, page, pageSize, orderBy = 'observed_at', orderDirection = 'desc', eql, additionalProperties = [] }) => {
        const queryParams = {
          page: page.toString(),
          page_size: pageSize.toString(),
          properties: [superficialResourcesProperties, ...additionalProperties].join(','),
          eql: encodeURIComponent(eql ? `(${DEFAULT_EQL}) AND ${eql}` : DEFAULT_EQL),
          sorting: `${orderBy}:${orderDirection}`,
          include_count: 'true'
        };
        const queryString = new URLSearchParams(queryParams).toString();
        return `accounts/${accountId}/data_model/search?${queryString}`;
      },
      transformResponse: (response: { resources: AlgoliaNode[], count: number }) => {
        return {
          items: response.resources.map(transformAlgoliaNodeToLocalNode).filter(n => n !== null).map(n => n as ISuperficialNode),
          total: response.count
        };
      }
    }),
    downloadNodesCsv: build.query<void, { accountId: number, eql: string, properties: string, propertyHeaders: string }>({
        query: ({ accountId, eql, properties, propertyHeaders }) => {
            return {
              url: `accounts/${accountId}/data_model/download?eql=${eql || DEFAULT_EQL}&properties=${properties}&property_headers=${propertyHeaders}`,
              method: 'GET',
              responseHandler: async (response) => {
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);
              const filename = generateCSVFilename();
              const link = document.createElement('a');
              link.href = url;
              link.download = filename;

              // Step 7: Trigger the download
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              // Step 8: Revoke the URL to free up memory
              URL.revokeObjectURL(url);
              },
              cache: "no-cache",
            };
        }
    })
  })
});

export const {
  useGetExpandedNodeQuery,
  useGetMetricsDependenciesQuery,
  useGetCustomPropertiesQuery,
  useGetDataModelResourcesQuery,
  useLazyGetDataModelResourcesQuery,
  useLazyDownloadNodesCsvQuery
} = nodesApi;

const defaultFilters = 'NOT hide_from_euno_data_model:true';
const addDefaultFilters = (filters?: string) => `${filters ? `${filters} AND ` : ''}${defaultFilters}`;

export const getNodes = async ({ query = '', applicationId, apiKey, indexName, page = 0, pageSize = 50, filters }: GetNodesProps): GetNodesReponse => {
  const algoliaClient = algoliasearch(applicationId, apiKey);
  const index = algoliaClient.initIndex(indexName);
  const results = await index.search(query, { hitsPerPage: pageSize, page, filters: addDefaultFilters(filters), cacheable: false });
  const superficialNodes = results.hits.map(hit => transformAlgoliaNodeToLocalNode(hit as unknown as  AlgoliaNode)).filter(n => n !== null) as ISuperficialNode[];
  return {
    items: superficialNodes,
    total: results.nbHits
  };
};

export const getFilterOptions = async ({ applicationId, apiKey, indexName }: AlgoliaProps & { indexName: string }) => {
  const parameters = ['tags', 'dbt_materialization_strategy', 'tableau_connection', 'meta', 'database', 'database_schema', 'dbt_project', 'type', 'looker_project', 'looker_folder', 'looker_model', 'source_directory', 'looker_host', 'tableau_project', 'tableau_workbook', 'owner'];
  const algoliaClient = algoliasearch(applicationId, apiKey);
  const index = algoliaClient.initIndex(indexName);
  const { facets } = await index.search('', { facets: parameters, filters: defaultFilters, maxValuesPerFacet: 1000 });
  const sortedFacets: { [key: string]: string[] } = {};
  for (const [key, value] of Object.entries(facets as { [key: string]: { [key: string]: number } })) {
    sortedFacets[key] = Object.keys(value).sort((a, b) => value[b] - value[a]);
  }
  return sortedFacets;
};

type SearchFilterValuesProps = AlgoliaProps & {
  indexName: string;
  filterName: string;
  filterQuery: string;
  filters: string;
}

export const searchFilterValues = async ({ applicationId, apiKey, indexName, filterName, filterQuery, filters }: SearchFilterValuesProps) => {
  const algoliaClient = algoliasearch(applicationId, apiKey);
  const index = algoliaClient.initIndex(indexName);
  const { facetHits } = await index.searchForFacetValues(filterName, filterQuery, { filters: addDefaultFilters(filters) });
  return facetHits.map(hit => hit.value);
};

const superficialResourcesProperties = ['tableau_project', 'first_seen_at', 'uri', 'first_observation', 'utl', 'name', 'generated_by_delphi', 'is_trivial_sql', 'type', 'description', 'tags', 'database', 'schema', 'database_schema', 'dbt_materialization_strategy', 'tableau_connection', 'git_repo_url', 'git_repo_branch', 'package_name', 'dbt_project', 'unique_id', 'number_of_dimensions', 'number_of_columns', 'number_of_measures', 'number_of_entities', 'number_of_metrics', 'number_of_custom_fields', 'parents', 'meta', 'has_shift_left_potential', 'owner', 'tableau_workbook', 'looker_folder', 'looker_model', 'source_directory', 'looker_host', 'last_7d_views', 'last_30d_views', 'total_queries_14d', 'total_queries_30d', 'total_queries_60d', 'distinct_users_14d', 'distinct_users_30d', 'distinct_users_60d', 'parent_name', 'database_technology'];
