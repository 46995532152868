import { useEffect, useMemo, useState } from 'react';
import DropdownMenu from '../../components/DropdownMenu';
import { useAppDispatch, useAppSelector } from '../../infrastructure/state/hooks';
import activeAccountSlice, { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetAccountsQuery } from '../../services/accounts/accounts';
import { BuildingOfficeIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useGetActiveAccount } from './AccountHooks';
import { identifyUser } from '../../infrastructure/analytics';
import activeProjectSlice from '../../infrastructure/state/slices/activeProjectSlice';

const AccountSwitch = () => {
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const { data: accounts = [] } = useGetAccountsQuery();
  const [accountSwitchMenuItems, setAccountSwitchMenuItems] = useState<{ name: string; onClick: () => void }[]>([]);
  const dispatch = useAppDispatch();
  const activeAccount = useGetActiveAccount(accounts, activeAccountId);
  const activeAccountName = activeAccount?.name || '';

  useMemo(() => {
    setAccountSwitchMenuItems(
      accounts
        .filter((a) => a.id !== activeAccountId)
        .map((account) => ({
          name: account.name,
          className: 'text-secondary hover:!bg-slate-50 !bg-white',
          icon: <BuildingOfficeIcon width="15" height="15" />,
          onClick: () => {
            dispatch(activeProjectSlice.actions.setActiveProject(null));
            dispatch(activeAccountSlice.actions.setActiveAccount(account.id));
            window.location.href = '/';
          }
        }))
    );
  }, [accounts, activeAccountId, dispatch]);

  useEffect(() => {
    identifyUser({ accountId: activeAccountId });
  }, [activeAccountId]);

  const AccountName = () => (
    <div className="ml-3 text-tertiary hover:text-slate-500">{activeAccountName}</div>
  );

  const AccountWithSwitch = () => (
    <DropdownMenu items={accountSwitchMenuItems} className="ml-2">
      <div className="flex items-center">
        <AccountName />
        <ChevronUpDownIcon className="ml-1 text-tertiary hover:text-slate-500" width="18" height="18" />
      </div>
    </DropdownMenu>
  );

  if (accountSwitchMenuItems.length > 0) {
    return <AccountWithSwitch />;
  } else {
    return <AccountName />;
  }
};

export default AccountSwitch;
