import { IChange, NewColumnChangeData } from "../../IChange";
import { ImplicationNode } from "../../formSections/ImplicationNode";
import { SubnodeType } from "../../../models/discover/INode";
import { store } from "../../../../infrastructure/state/store";
import { nodesApi } from "../../../../services/nodes/nodes";
import { filterOutUpstreamNodes } from "../filterOutUpstreamNodes";

export const getImplicationNodes = async ({ change, accountId }: { change: IChange, accountId: number }) => {
  const eql = `has upstream(uri='${change.targetUtl}', 3) OR uri='${change.targetUtl}'`;
  const { data } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
    accountId,
    eql,
    page: 1,
    pageSize: 100
  }));
  const nodes = data?.items || [];
  const downStreamOnlyNodes = filterOutUpstreamNodes(nodes, change.targetUtl);
  const changeData = change.changeData as NewColumnChangeData;
  return downStreamOnlyNodes.map((node) => {
    const isTargetModel = node.id === change.targetUtl;
    return {
      id: node.id,
      parents: isTargetModel ? [] : node.parents,
      component: <ImplicationNode nodeName={node.name} subNodeName={isTargetModel ? changeData.newColumnName : ''} nodeType={node.type} subNodeType={SubnodeType.Column} isNew={isTargetModel} />,
      height: 65,
      width: 300
    };
  });
};