import { ChangeStatus, ChangeType } from '../../features/evolution/IChange';
import { SortOptions } from '../../features/evolution/changesPage/Types';
import { DateFilterValue } from '../../features/evolution/changesPage/filters/dateFilter';
import { NewAggregateTableChangeDataBackend } from './aggregateTableTransformers';

type BackendChangeState = | 'change_draft' | 'proposal' | 'pr_rejected' | 'draft_rejected' | 'pending_merge' | 'merged' | 'deployed' | 'completed';

export interface CreationContextBackend {
  source_utl: string;
  type_specific_content: {
    creation_context_type: 'looker_tile' | 'looker_view' | 'looker_look_dynamic_field' | 'dbt_project';
    expression_name?: string;
  };
  created_by_resource_name?: string | null;
  created_by_name_in_data_application?: string | null;
}

export interface GetChangesPageResponse {
  id: number;
  change_type: BackendChangeType;
  creation_context: CreationContextBackend | null;
  title: string;
  project_id: number;
  created_at: string;
  created_by_email: string;
  model_change_number: number;
  url: string;
  state: BackendChangeState;
}

export interface BackendChange {
  id: number;
  target_model_utl: string;
  title: string;
  project_id: number;
  change_origin: string;
  description: string;
  created_at: string;
  created_by_email: string;
  state: BackendChangeState;
  updated_at: string;
  updated_by: string | null;
  creation_context: CreationContextBackend | null;
  change_content: {
    type_specific_content: BackendChangeData;
  };
  associated_pull_request_url: string | null;
  associated_pull_request_number: number | null;
  applied_in_build_id: number | null;
  target_branch: string;
}

export interface UpdateChangeRequest {
  title: string;
  description: string;
  change_content: {
    type_specific_content: BackendChangeData;
  };
  target_branch: string;
}

export type NewColumnChangeDataBackend = {
  model_unique_id: string;
  column_name: string;
  change_type: BackendChangeType.AddColumnToExistingModel;
  column_metadata: {
    description: string;
    tags: string[];
    meta: Metadata;
    column_is_nullable: boolean;
    column_is_unique: boolean;
    column_accepted_values: string[];
    relationships: { to_model_unique_id: string; to_column_name: string } | null;
  };
};

export type NewModelChangeDataBackend = {
  change_type: BackendChangeType.AddModel;
  model_name: string;
  project_name: string;
  sql: string;
  meta_data: {
    meta: Metadata;
    tags: string[];
    description: string;
  };
  materialization: 'view' | 'table' | 'incremental';
  columns: NewModelChangeDataBackendColumn[];
  measures: NewModelChangeDataBackendMeasure[];
};

export type NewModelChangeDataBackendColumn = {
  column_name: string;
  column_metadata: {
    description: string;
    tags: string[];
    meta: Metadata;
    column_type: string;
    column_is_nullable: boolean;
    column_is_unique: boolean;
    column_accepted_values: string[];
    relationships: { to_model_unique_id: string; to_column_name: string } | null;
  }
};

export type NewModelChangeDataBackendMeasure = {
  name: string;
  label: string;
  agg: string;
  expr: string;
  measure_metadata: {
    description: string;
    tags: string[];
    meta: Metadata;
  };
};

export type Metadata = { [key: string]: string };

export type NewMeasureChangeDataBackend = {
  change_type: BackendChangeType.PromoteMeasure;
  model_unique_id: string;
  name: string;
  measure_metadata: {
    description: string;
    tags: string[];
    meta: { [key: string]: string };
  };
};

export enum BackendChangeType {
  AddColumnToExistingModel = 'add_column_to_existing_model',
  PromoteMeasure = 'add_measure_to_existing_model',
  AddModel = 'add_model',
  AddAggregateTable = 'add_aggregate_table',
}

export interface GetChangesRequest {
  projectId: number;
  sortBy?: SortOptions;
  statuses: ChangeStatus[];
  search?: string;
  page?: number;
  pageSize?: number;
  owner?: string | null;
  createdAt?: DateFilterValue;
  types?: ChangeType[];
}

export type BackendChangeData = NewColumnChangeDataBackend | NewMeasureChangeDataBackend | NewModelChangeDataBackend | NewAggregateTableChangeDataBackend;

export type AlgoliaPat = {
  objectID: string;
  shift_left_date: number;
  shift_left_owner: string;
  shift_left_project: string;
  shift_left_source: string;
  shift_left_title: string;
  shift_left_type: BackendChangeType;
  total_queries_14?: number;
  total_queries_30?: number;
  total_queries_60?: number;
  uri: string;
}

export type PatResponse = {
  shift_left_potential: {
    title: string,
    project: string,
    description: string,
    target_model_utl: string,
    creation_context: CreationContextBackend,
    change_content: {
      type_specific_content: BackendChangeData
    },
    target_branch: string
  },
  shift_left_type: BackendChangeType
}