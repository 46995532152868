import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../infrastructure/state/hooks';
import { useGetProjectQuery } from '../../../services/projects/projects';
import { useEffect } from 'react';
import activeProjectSlice from '../../../infrastructure/state/slices/activeProjectSlice';
import { notify } from '../../../components/Toaster';
import PageLoader from '../../../components/loaders/PageLoader';
import Layout from '../../../components/layout/Layout';
import ProjectHeader from '../ProjectHeader';
import ManageProjectBranches from './branches/ManageProjectBranches';
import ManageProjectBuilds from './ManageProjectBuilds';
import ManageProjectDeploymentJob from './ManageProjectDeploymentJob';
import TopBar from '../../../components/layout/TopBar';

const ManageProject = () => {
  const projectId = parseInt(useParams().projectId || '');
  const dispatch = useAppDispatch();
  const { data: project, isLoading: isLoadingProject, isError } = useGetProjectQuery(projectId);

  useEffect(() => {
    if (project) {
      dispatch(activeProjectSlice.actions.setActiveProject(project.id));
    } else if (!project && !isLoadingProject) {
      dispatch(activeProjectSlice.actions.setActiveProject(null));
      notify('Project not found', 'error');
    }
  }, [project, dispatch, isLoadingProject]);

  if (isLoadingProject) {
    return <PageLoader label="Loading Project" />;
  } else if (!project || isError) {
    return (
      <Layout>
        <TopBar />
        <div className="mt-40 text-center text-lg">Failed to load project</div>
      </Layout>
    );
  } else if (project) {
    return (
      <Layout>
        <ProjectHeader project={project} withTabs={true} page={'configuration'}/>
        <div className="px-14 py-6 overflow-auto pb-3">
          <ManageProjectBranches project={project} />
          <ManageProjectBuilds project={project} />
          <ManageProjectDeploymentJob project={project} />
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

export default ManageProject;
