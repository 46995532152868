import { DiscoverErrorIcon } from "../../../../../assets/images/icons/DelphiIcons";

export const DataModelTooLargeScreen = () => {
    return (
        <div className="w-fit flex flex-col items-center text-center mx-auto mt-[20vh]">
            <DiscoverErrorIcon className="ml-10"/>
            <div className="mt-5 text-secondary text-lg">Data model too large</div>
            <div className="mt-2 text-tertiary w-48">Try applying filters to reduce the amount of items to display.</div>
        </div>
    );
};