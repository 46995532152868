import { BackendSubnodeType } from "../../../services/nodes/types";

export type DestructedUtl = {
    nodeId: string;
    subnodeType?: BackendSubnodeType;
    subnodeName?: string;
}

export const destructUtl = (input: string): DestructedUtl => {
    const dotSplitUtl = input.split('.');
    const presumedSubnodeType = dotSplitUtl[dotSplitUtl.length-2] as BackendSubnodeType;
    const utlIncludesSubnode = Object.values(BackendSubnodeType).includes(presumedSubnodeType);
    if (utlIncludesSubnode) {
        return {
            nodeId: dotSplitUtl.slice(0, dotSplitUtl.length-2).join('.'),
            subnodeType: presumedSubnodeType,
            subnodeName: dotSplitUtl[dotSplitUtl.length-1]
        };
    }
    else {
        return {
            nodeId: input
        };
    }
};