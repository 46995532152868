import { useNavigate } from "react-router-dom";
import { SparklesLeftArrowIcon } from "../../../assets/images/icons/DelphiIcons";
import { useDispatch } from "react-redux";
import activeProjectSlice from "../../../infrastructure/state/slices/activeProjectSlice";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../infrastructure/state/slices/activeAccountSlice";
import { useEffect, useState } from "react";
import { getPats } from "../../../services/changes/changes";
import { useGetDamaRevisionQuery } from "../../../services/accounts/accounts";

export const ProjectProposals = ({ projectId }: { projectId: number }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountId = useSelector(selectActiveAccountId);
    const revision = useGetDamaRevisionQuery({ accountId });
    const [total, setTotal] = useState(0);
    const viewProposals = () => {
        dispatch(activeProjectSlice.actions.setActiveProject(projectId));
        navigate(`/model-changes`);
    };

    useEffect(() => {
        if (revision.data) {
            getPats({
                applicationId: revision.data.algoliaAppId,
                apiKey: revision.data.algoliaKey,
                indexName: revision.data.algoliaIndex,
                page: 0,
                pageSize: 0,
                projectId
            }).then(data => {
                setTotal(data.total || 0);
            });
        }
    }, [projectId, accountId, revision]);

    if (!total) return null;
    return (
        <div className="text-sm mt-4 flex items-center p-2 bg-muse-50 gap-1 rounded-md">
            <div><SparklesLeftArrowIcon width="14" height="14" fill="#0047FF" /></div>
            <div className="text-primary">{total} proposals</div>
            <div className="ml-auto text-surface-primary font-medium cursor-pointer" onClick={viewProposals}>View</div>
        </div>
    );
};